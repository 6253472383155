import { useEffect, useState } from 'react';

const useIntersectionObserver = ({
  root = null,
  rootMargin = '0px',
  threshold = 0.1,
  target,
  onIntersect,
  enabled = true
}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!enabled || !target) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
        if (entry.isIntersecting) {
          onIntersect(entry);
        }
      },
      {
        root,
        rootMargin,
        threshold
      }
    );

    const element = target.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [target, enabled, root, rootMargin, threshold, onIntersect]);

  return isIntersecting;
};

export default useIntersectionObserver;