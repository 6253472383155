import { toast } from 'react-toastify';

let rateLimitedCalls = new Set();

export const handleRateLimit = (key, retryAfter) => {
  if (!rateLimitedCalls.has(key)) {
    rateLimitedCalls.add(key);
    toast.error(`Too many requests for ${key}. Please try again in ${retryAfter} seconds.`);
    setTimeout(() => {
      rateLimitedCalls.delete(key);
    }, retryAfter * 1000);
  }
};