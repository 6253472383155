import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { format, isValid, parseISO } from 'date-fns';
import apiService from '../../services/api';
import Spinner from '../../components/Spinner';
import { RefreshCw, AlertTriangle } from 'lucide-react';
import { toast } from 'react-toastify';

const VendorPaymentManagement = () => {
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  const fetchPayments = useCallback(async (page = 1) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiService.getPendingVendorPayments({ page, search: searchTerm });
      console.log("API Response:", response); // For debugging
      setPayments(response.results || []);
      setTotalPages(Math.ceil((response.count || 0) / 10));
      setCurrentPage(page);
    } catch (error) {
      console.error('Failed to fetch vendor payments:', error);
      setError('Failed to fetch payments. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchPayments(currentPage);
    setIsRefreshing(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleMarkPaidToVendor = async () => {
    try {
      await apiService.markAssetEarningPaidToVendor(selectedPayment.id, transactionId);
      setIsDialogOpen(false);
      toast.success('Payment marked as paid to vendor successfully');
      
      // Update the local state to reflect the change
      setPayments(prevPayments => 
        prevPayments.map(payment => 
          payment.id === selectedPayment.id 
            ? { ...payment, is_paid_to_vendor: true, payout_transaction_id: transactionId }
            : payment
        )
      );
    } catch (error) {
      console.error('Failed to mark payment as paid to vendor:', error);
      if (error.response && error.response.status === 403) {
        toast.error('You do not have permission to mark this payment as paid.');
      } else {
        toast.error('Failed to mark payment as paid to vendor. Please try again.');
      }
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'yyyy-MM-dd HH:mm:ss') : 'Invalid Date';
  };

  const formatCurrency = (value) => {
    if (typeof value !== 'number' && typeof value !== 'string') return 'N/A';
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    return new Intl.NumberFormat('en-KE', { style: 'currency', currency: 'KES' }).format(numValue);
  };

  return (
    <Card className="mb-6 shadow-lg rounded-lg overflow-hidden">
      <CardHeader className="bg-orange-600 text-white">
        <CardTitle className="text-2xl font-bold">Vendor Payment Management</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 mt-4">
          <Input
            placeholder="Search payments..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full md:w-1/3 mb-2 md:mb-0"
          />
          <Button onClick={handleRefresh} className="bg-orange-500 text-white w-full md:w-auto" disabled={isRefreshing}>
            {isRefreshing ? 'Refreshing...' : 'Refresh'}
            <RefreshCw className="ml-2 h-4 w-4" />
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : error ? (
          <div className="text-red-500 flex items-center justify-center p-4">
            <AlertTriangle className="mr-2" />
            <p>{error}</p>
          </div>
        ) : payments.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Asset ID</TableHead>
                  <TableHead>Order Number</TableHead>
                  <TableHead>Gross Amount</TableHead>
                  <TableHead>Net Amount</TableHead>
                  <TableHead>Transaction ID</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {payments.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell>{payment.asset || 'N/A'}</TableCell>
                    <TableCell>{payment.order_number || 'N/A'}</TableCell>
                    <TableCell>{formatCurrency(payment.gross_amount)}</TableCell>
                    <TableCell>{formatCurrency(payment.net_amount)}</TableCell>
                    <TableCell>
                      {payment.is_paid_to_vendor 
                        ? payment.payout_transaction_id || 'N/A'
                        : formatDate(payment.payment_date)
                      }
                    </TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded ${payment.is_paid_to_vendor ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'}`}>
                        {payment.is_paid_to_vendor ? 'Paid' : 'Pending'}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSelectedPayment(payment);
                          setTransactionId('');
                          setIsDialogOpen(true);
                        }}
                        disabled={payment.is_paid_to_vendor}
                      >
                        Mark Paid to Vendor
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No payments to vendors available.</p>
        )}
       
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchPayments(currentPage - 1)} disabled={currentPage === 1 || isLoading}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchPayments(currentPage + 1)} disabled={currentPage === totalPages || isLoading}>Next</Button>
        </div>
      </CardContent>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Mark Payment as Paid to Vendor</DialogTitle>
          </DialogHeader>
          {selectedPayment && (
            <div>
              <p><strong>Asset ID:</strong> {selectedPayment.asset || 'N/A'}</p>
              <p><strong>Order ID:</strong> {selectedPayment.order || 'N/A'}</p>
              <p><strong>Net Amount:</strong> {formatCurrency(selectedPayment.net_amount)}</p>
              <p><strong>Payment Date:</strong> {formatDate(selectedPayment.payment_date)}</p>
              <div className="mt-4">
                <Input
                  placeholder="Transaction ID"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </div>
            </div>
          )}
          <DialogFooter>
            <Button onClick={handleMarkPaidToVendor} disabled={!transactionId}>Mark as Paid to Vendor</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default VendorPaymentManagement;