import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import { WishlistProvider } from './contexts/WishlistContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReferralRedirect from './components/ReferralRedirect';
import Layout from './components/Layout';
import ScrollToTop from './components/ScrollToTop';
import LoadingSpinner from './components/LoadingSpinner'; // You'll need to create this component

// Import all pages
import LandingPage from './pages/LandingPage';
import AssetsPage from './pages/AssetsPage';
import AssetDetailsPage from './pages/AssetDetailsPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import AccountActivationPage from './pages/AccountActivationPage';
import UserProfilePage from './pages/UserProfilePage';
import WishlistPage from './pages/WishlistPage';
import AdminDashboard from './pages/AdminDashboard';
import AnalyticsPage from './pages/AnalyticsPage';
import ReportsPage from './pages/ReportsPage';
import NotificationLogPage from './pages/NotificationLogPage';
import UserPreferencesPage from './pages/UserPreferencesPage';
import NotificationAnalyticsPage from './pages/NotificationAnalyticsPage';
import PopularAssetsPage from './pages/PopularAssetsPage';
import ExploreAssetsPage from './pages/ExploreAssetsPage';
import ListAssetPage from './pages/ListAssetPage';
import SearchResultsPage from './pages/SearchResultsPage';
import NotFoundPage from './pages/NotFoundPage';
import ContactUsPage from './pages/ContactUsPage';
import FAQPage from './pages/FAQPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import CancellationPolicyPage from './pages/CancellationPolicyPage';
import AboutUsPage from './pages/AboutUsPage';
import HowItWorksPage from './pages/HowItWorksPage';
import CategoriesPage from './pages/CategoriesPage';
import NewArrivalsPage from './pages/NewArrivalsPage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';

// Import payment pages
import PayPalPaymentPage from './pages/payment/PayPalPaymentPage';
import MpesaPaymentPage from './pages/payment/MpesaPaymentPage';
import FlutterwavePaymentPage from './pages/payment/FlutterwavePaymentPage';

function AppContent() {
  const { loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/assets" element={<AssetsPage />} />
          <Route path="/assets/:slug" element={<AssetDetailsPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/checkout" element={<ProtectedRoute><CheckoutPage /></ProtectedRoute>} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/ref/:code" element={<ReferralRedirect />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password/:uidb64/:token" element={<ResetPasswordPage />} />
          <Route path="/activate/:uidb64/:token" element={<AccountActivationPage />} />
          <Route path="/profile" element={<ProtectedRoute><UserProfilePage /></ProtectedRoute>} />
          <Route path="/wishlist" element={<ProtectedRoute><WishlistPage /></ProtectedRoute>} />
          <Route path="/preferences" element={<ProtectedRoute><UserPreferencesPage /></ProtectedRoute>} />
          <Route path="/admin/dashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
          <Route path="/admin/analytics" element={<AdminRoute><AnalyticsPage /></AdminRoute>} />
          <Route path="/admin/reports" element={<AdminRoute><ReportsPage /></AdminRoute>} />
          <Route path="/admin/notifications" element={<AdminRoute><NotificationLogPage /></AdminRoute>} />
          <Route path="/admin/notification-analytics" element={<AdminRoute><NotificationAnalyticsPage /></AdminRoute>} />
          <Route path="/popular" element={<PopularAssetsPage />} />
          <Route path="/explore" element={<ExploreAssetsPage />} />
          <Route path="/list-asset" element={<ProtectedRoute><ListAssetPage /></ProtectedRoute>} />
          <Route path="/search-results" element={<SearchResultsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="/cancellation" element={<CancellationPolicyPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/how-it-works" element={<HowItWorksPage />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/new" element={<NewArrivalsPage />} />
          <Route path="/order-confirmation/:orderId" element={<OrderConfirmationPage />} />
          <Route path="/order-confirmation" element={<OrderConfirmationPage />} />

          {/* Payment routes */}
          <Route path="/payment/paypal" element={<ProtectedRoute><PayPalPaymentPage /></ProtectedRoute>} />
          <Route path="/payment/mpesa" element={<ProtectedRoute><MpesaPaymentPage /></ProtectedRoute>} />
          <Route path="/payment/flutterwave" element={<ProtectedRoute><FlutterwavePaymentPage /></ProtectedRoute>} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Router>
  );
}

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <CartProvider>
          <WishlistProvider>
            <AppContent />
          </WishlistProvider>
        </CartProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;