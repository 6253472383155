import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { 
  ShoppingCartIcon, 
  TruckIcon, 
  CreditCardIcon, 
  ChevronRightIcon,
  ChevronLeftIcon,
  PlusIcon,
  MinusIcon
} from '@heroicons/react/24/outline';
import { useCart } from '../contexts/CartContext';
import { formatCurrency } from '../utils/formatters';
import Spinner from '../components/Spinner';
import ErrorMessage from '../components/ErrorMessage';
import StepIndicator from '../components/StepIndicator';
import OrderConfirmationModal from '../components/OrderConfirmationModal';
import apiService from '../services/api';

const paymentGateways = [
  { id: 'flutterwave', name: 'Flutterwave', icon: '💳' },
  { id: 'mpesa', name: 'M-Pesa', icon: '📱' },
  { id: 'paypal', name: 'PayPal', icon: '🅿️' },
];

const CheckoutPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [shippingInfo, setShippingInfo] = useState({
    address: '',
    city: '',
    postal_code: '',
    country: '',
  });
  const [billingInfo, setBillingInfo] = useState({
    full_name: '',
    email: '',
    phone: '',
  });
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponError, setCouponError] = useState('');
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [giftCardCode, setGiftCardCode] = useState('');
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const navigate = useNavigate();
  const { 
    cartItems, 
    totalItems, 
    totalPrice, 
    updateCartItem, 
    removeItemFromCart, 
    clearCart,
    fetchCart
  } = useCart();

  useEffect(() => {
    if (!cartItems || cartItems.length === 0) {
      toast.error('Your cart is empty. Please add items before proceeding to checkout.');
      navigate('/cart');
    }
  }, [cartItems, navigate]);

  const getItemRate = useCallback((item) => {
    if (item.hourly_rate != null) {
      return item.hourly_rate;
    } else if (item.price != null) {
      return item.price;
    } else if (item.asset && item.asset.hourly_rate != null) {
      return item.asset.hourly_rate;
    } else {
      console.warn('No rate found for item:', item);
      return 0;
    }
  }, []);

  const calculateItemTotal = useCallback((item) => {
    const rate = getItemRate(item);
    return rate * item.quantity * item.hours;
  }, [getItemRate]);

  const calculateSubtotal = useCallback(() => {
    return cartItems.reduce((total, item) => total + calculateItemTotal(item), 0);
  }, [cartItems, calculateItemTotal]);

  const calculateTotal = useCallback(() => {
    const subtotal = calculateSubtotal();
    const totalDiscount = couponDiscount + giftCardAmount;
    return Math.max(subtotal - totalDiscount, 0); // Ensure the total doesn't go below zero
  }, [calculateSubtotal, couponDiscount, giftCardAmount]);
  

  const handleInputChange = useCallback((e, setStateFunction) => {
    const { name, value } = e.target;
    setStateFunction((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleUpdateQuantity = useCallback(async (assetId, newQuantity) => {
    setLoading(true);
    try {
      if (newQuantity > 0) {
        await updateCartItem(assetId, { quantity: newQuantity });
      } else {
        await removeItemFromCart(assetId);
      }
      await fetchCart();
      toast.success('Cart updated successfully');
    } catch (error) {
      console.error('Error updating cart:', error);
      toast.error('Failed to update cart. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [updateCartItem, removeItemFromCart, fetchCart]);

  const handleUpdateHours = useCallback(async (assetId, newHours) => {
    setLoading(true);
    try {
      if (newHours > 0) {
        await updateCartItem(assetId, { hours: newHours });
        await fetchCart();
        toast.success('Rental hours updated successfully');
      }
    } catch (error) {
      console.error('Error updating rental hours:', error);
      toast.error('Failed to update rental hours. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [updateCartItem, fetchCart]);

  const validateForm = useCallback(() => {
    const errors = {};
    if (step === 1) {
      if (!shippingInfo.address.trim()) errors.address = 'Address is required';
      if (!shippingInfo.city.trim()) errors.city = 'City is required';
      if (!shippingInfo.postal_code.trim()) errors.postal_code = 'Postal code is required';
      if (!shippingInfo.country.trim()) errors.country = 'Country is required';
    } else if (step === 2) {
      if (!billingInfo.full_name.trim()) errors.full_name = 'Full name is required';
      if (!billingInfo.email.trim()) errors.email = 'Email is required';
      if (!billingInfo.phone.trim()) errors.phone = 'Phone number is required';
    }
    return errors;
  }, [step, shippingInfo, billingInfo]);

  const handleNextStep = useCallback(() => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      Object.values(formErrors).forEach(error => toast.error(error));
      return;
    }
    setStep((prevStep) => prevStep + 1);
  }, [validateForm]);

  const handlePreviousStep = useCallback(() => {
    setStep((prevStep) => prevStep - 1);
  }, []);

  const applyCoupon = useCallback(async () => {
    if (!couponCode.trim()) {
      setCouponError('Please enter a coupon code');
      return;
    }
    
    setLoading(true);
    setCouponError('');
    try {
      const validityResponse = await apiService.checkCouponValidity(couponCode);
      if (validityResponse.data.is_valid) {
        const applyResponse = await apiService.applyCoupon(couponCode);
        setCouponDiscount(applyResponse.data.discount_amount);
        toast.success('Coupon applied successfully');
      } else {
        setCouponError('Invalid or expired coupon');
      }
    } catch (error) {
      setCouponError('Error applying coupon. Please try again.');
      console.error('Coupon application error:', error);
    } finally {
      setLoading(false);
    }
  }, [couponCode]);
    


  const handleRedeemPoints = async () => {
    try {
      const response = await apiService.redeemLoyaltyPoints(loyaltyPoints);
      setCouponDiscount(prev => prev + response.data.discount_amount);
      setLoyaltyPoints(0);
      toast.success('Loyalty points redeemed successfully');
    } catch (error) {
      console.error('Error redeeming points:', error);
      toast.error('Failed to redeem points. Please try again.');
    }
  };

  const applyGiftCard = async () => {
    try {
      const response = await apiService.applyGiftCard({ code: giftCardCode });
      setGiftCardAmount(response.data.amount);
      toast.success('Gift card applied successfully');
    } catch (error) {
      console.error('Error applying gift card:', error);
      toast.error('Failed to apply gift card. Please try again.');
    }
  };

  const handleApiCall = async (apiFunction, ...args) => {
    const maxRetries = 3;
    let retries = 0;
    while (retries < maxRetries) {
      try {
        return await apiFunction(...args);
      } catch (error) {
        retries++;
        if (retries === maxRetries) {
          throw error;
        }
        await new Promise(resolve => setTimeout(resolve, 1000 * retries));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedPaymentGateway) {
      toast.error('Please select a payment method.');
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const finalTotal = calculateTotal();
      
      const orderData = {
        items: cartItems.map(item => ({
          asset_id: item.asset.id,
          quantity: item.quantity,
          unit_price: getItemRate(item),
          hours: item.hours
        })),
        shipping_info: shippingInfo,
        billing_info: billingInfo,
        payment_gateway: selectedPaymentGateway,
        total_amount: finalTotal,
        coupon_code: couponCode,
        coupon_discount: couponDiscount,
        notes: '', // Add any order notes if needed
      };
  
      console.log('Sending order data:', orderData);
  
      const orderResponse = await apiService.createOrder(orderData);
      console.log('Created order:', orderResponse);
  
      await clearCart();
      const fullOrderData = {
        ...orderResponse,
        ...orderData
      };
      sessionStorage.setItem('orderData', JSON.stringify(fullOrderData));
  
      switch (selectedPaymentGateway) {
        case 'mpesa':
          navigate('/payment/mpesa', { state: { orderData: fullOrderData } });
          break;
        case 'flutterwave':
          navigate('/payment/flutterwave', { state: { orderData: fullOrderData } });
          break;
        case 'paypal':
          navigate('/payment/paypal', { state: { orderData: fullOrderData } });
          break;
        default:
          toast.error('Invalid payment method selected.');
      }
    } catch (error) {
      console.error('Order creation error:', error);
      setError('Failed to create order. Please try again.');
      toast.error(error.response?.data?.error || 'An error occurred while creating the order.');
    } finally {
      setLoading(false);
    }
  };
  
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8"
    >
      <h1 className="text-3xl font-bold text-gray-900 mb-8 flex items-center">
        <ShoppingCartIcon className="h-8 w-8 mr-2 text-indigo-600" />
        Checkout
      </h1>
      <StepIndicator currentStep={step} totalSteps={4} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
            <TruckIcon className="h-6 w-6 mr-2 text-indigo-600" />
            Order Summary
          </h2>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <ul className="divide-y divide-gray-200">
              {cartItems.map((item) => (
                <li key={item.asset.id} className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div className="text-sm font-medium text-indigo-600 truncate">
                      {item.asset.name}
                    </div>
                    <div className="ml-2 flex-shrink-0 flex">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {item.quantity} x {formatCurrency(getItemRate(item))} x {item.hours} hour(s)
                      </span>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        Quantity:
                        <button
                          onClick={() => handleUpdateQuantity(item.asset.id, item.quantity - 1)}
                          className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200"
                        >
                          <MinusIcon className="h-4 w-4" />
                        </button>
                        <span className="mx-2">{item.quantity}</span>
                        <button
                          onClick={() => handleUpdateQuantity(item.asset.id, item.quantity + 1)}
                          className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200"
                        >
                          <PlusIcon className="h-4 w-4" />
                        </button>
                      </p>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <span className="mr-2">Hours:</span>
                        <button
                          onClick={() => handleUpdateHours(item.asset.id, item.hours - 1)}
                          className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200"
                        >
                          <MinusIcon className="h-4 w-4" />
                        </button>
                        <span className="mx-2">{item.hours}</span>
                        <button
                          onClick={() => handleUpdateHours(item.asset.id, item.hours + 1)}
                          className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200"
                        >
                          <PlusIcon className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                    <p className="mt-2 flex items-center text-sm font-medium text-gray-900 sm:mt-0">
                      Subtotal: {formatCurrency(calculateItemTotal(item))}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            <div className="px-4 py-5 bg-gray-50 sm:px-6">
              <div className="flex justify-between text-base font-medium text-gray-900">
                <p>Subtotal</p>
                <p>{formatCurrency(calculateSubtotal())}</p>
              </div>
              {couponDiscount > 0 && (
                <div className="flex justify-between text-base font-medium text-green-600 mt-2">
                  <p>Coupon Discount</p>
                  <p>-{formatCurrency(couponDiscount)}</p>
                </div>
              )}
              {giftCardAmount > 0 && (
                <div className="flex justify-between text-base font-medium text-green-600 mt-2">
                  <p>Gift Card</p>
                  <p>-{formatCurrency(giftCardAmount)}</p>
                </div>
              )}
              <div className="flex justify-between text-lg font-bold text-gray-900 mt-4">
                <p>Total</p>
                <p>{formatCurrency(calculateTotal())}</p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="coupon" className="block text-sm font-medium text-gray-700">
              Coupon Code
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                name="coupon"
                id="coupon"
                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={applyCoupon}
              >
                Apply
              </button>
            </div>
            {couponError && (
              <p className="mt-2 text-sm text-red-600">{couponError}</p>
            )}
          </div>
          <div className="mt-4">
            <p className="text-sm font-medium text-gray-700">Loyalty Points: {loyaltyPoints}</p>
            <button
              type="button"
              className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={handleRedeemPoints}
            >
              Redeem Points
            </button>
          </div>
          <div className="mt-4">
            <label htmlFor="giftcard" className="block text-sm font-medium text-gray-700">
              Gift Card
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                name="giftcard"
                id="giftcard"
                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                placeholder="Enter gift card code"
                value={giftCardCode}
                onChange={(e) => setGiftCardCode(e.target.value)}
              />
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={applyGiftCard}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
            <CreditCardIcon className="h-6 w-6 mr-2 text-indigo-600" />
            {step === 1 ? 'Shipping Information' : 
             step === 2 ? 'Billing Information' : 
              step === 3 ? 'Payment Method' :
              'Review & Place Order'}
           </h2>
           <form onSubmit={handleSubmit} className="space-y-4">
             {step === 1 && (
               <>
                 <div>
                   <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                     Address
                   </label>
                   <input
                     type="text"
                     id="address"
                     name="address"
                     value={shippingInfo.address}
                     onChange={(e) => handleInputChange(e, setShippingInfo)}
                     required
                     className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   />
                 </div>
                 <div>
                   <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                     City
                   </label>
                   <input
                     type="text"
                     id="city"
                     name="city"
                     value={shippingInfo.city}
                     onChange={(e) => handleInputChange(e, setShippingInfo)}
                     required
                     className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   />
                 </div>
                 <div>
                   <label htmlFor="postal_code" className="block text-sm font-medium text-gray-700">
                     Postal Code
                   </label>
                   <input
                     type="text"
                     id="postal_code"
                     name="postal_code"
                     value={shippingInfo.postal_code}
                     onChange={(e) => handleInputChange(e, setShippingInfo)}
                     required
                     className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   />
                 </div>
                 <div>
                   <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                     Country
                   </label>
                   <input
                     type="text"
                     id="country"
                     name="country"
                     value={shippingInfo.country}
                     onChange={(e) => handleInputChange(e, setShippingInfo)}
                     required
                     className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   />
                 </div>
               </>
             )}
             {step === 2 && (
               <>
                 <div>
                   <label htmlFor="full_name" className="block text-sm font-medium text-gray-700">
                     Full Name
                   </label>
                   <input
                     type="text"
                     id="full_name"
                     name="full_name"
                     value={billingInfo.full_name}
                     onChange={(e) => handleInputChange(e, setBillingInfo)}
                     required
                     className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   />
                 </div>
                 <div>
                   <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                     Email
                   </label>
                   <input
                     type="email"
                     id="email"
                     name="email"
                     value={billingInfo.email}
                     onChange={(e) => handleInputChange(e, setBillingInfo)}
                     required
                     className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   />
                 </div>
                 <div>
                   <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                     Phone Number
                   </label>
                   <input
                     type="tel"
                     id="phone"
                     name="phone"
                     value={billingInfo.phone}
                     onChange={(e) => handleInputChange(e, setBillingInfo)}
                     required
                     className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   />
                 </div>
               </>
             )}
             {step === 3 && (
               <div className="space-y-4">
                 <p className="text-sm text-gray-600 mb-2">Select a payment method:</p>
                 {paymentGateways.map((gateway) => (
                   <label key={gateway.id} className="flex items-center space-x-3 cursor-pointer">
                     <input
                       type="radio"
                       name="paymentGateway"
                       value={gateway.id}
                       checked={selectedPaymentGateway === gateway.id}
                       onChange={() => setSelectedPaymentGateway(gateway.id)}
                       className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                     />
                     <span className="text-sm font-medium text-gray-700">
                       {gateway.icon} {gateway.name}
                     </span>
                   </label>
                 ))}
               </div>
             )}
             {step === 4 && (
               <div className="space-y-4">
                 <h3 className="text-lg font-medium text-gray-900">Order Review</h3>
                 <div>
                   <h4 className="text-sm font-medium text-gray-700">Shipping Information</h4>
                   <p className="mt-1 text-sm text-gray-500">
                     {shippingInfo.address}, {shippingInfo.city}, {shippingInfo.postal_code}, {shippingInfo.country}
                   </p>
                 </div>
                 <div>
                   <h4 className="text-sm font-medium text-gray-700">Billing Information</h4>
                   <p className="mt-1 text-sm text-gray-500">
                     {billingInfo.full_name}<br />
                     {billingInfo.email}<br />
                     {billingInfo.phone}
                   </p>
                 </div>
                 <div>
                   <h4 className="text-sm font-medium text-gray-700">Payment Method</h4>
                   <p className="mt-1 text-sm text-gray-500">
                     {paymentGateways.find(gateway => gateway.id === selectedPaymentGateway)?.name}
                   </p>
                 </div>
                 <div>
                   <h4 className="text-sm font-medium text-gray-700">Order Total</h4>
                   <p className="mt-1 text-sm font-medium text-gray-900">
                     {formatCurrency(calculateTotal())}
                   </p>
                 </div>
                 {couponDiscount > 0 && (
                   <div>
                     <h4 className="text-sm font-medium text-gray-700">Applied Coupon</h4>
                     <p className="mt-1 text-sm text-green-600">
                       {couponCode} (Discount: {formatCurrency(couponDiscount)})
                     </p>
                   </div>
                 )}
                 {giftCardAmount > 0 && (
                   <div>
                     <h4 className="text-sm font-medium text-gray-700">Applied Gift Card</h4>
                     <p className="mt-1 text-sm text-green-600">
                       {giftCardCode} (Amount: {formatCurrency(giftCardAmount)})
                     </p>
                   </div>
                 )}
               </div>
             )}
             <div className="flex justify-between mt-6">
               {step > 1 && (
                 <button
                   type="button"
                   onClick={handlePreviousStep}
                   className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out flex items-center"
                 >
                   <ChevronLeftIcon className="h-5 w-5 mr-1" />
                   Back
                 </button>
               )}
               {step < 4 ? (
                 <button
                   type="button"
                   onClick={handleNextStep}
                   className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-150 ease-in-out flex items-center ml-auto"
                 >
                   Next
                   <ChevronRightIcon className="h-5 w-5 ml-1" />
                 </button>
               ) : (
                 <button
                   type="submit"
                   disabled={loading || cartItems.length === 0 || !selectedPaymentGateway}
                   className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed flex items-center ml-auto"
                 >
                   {loading ? 'Processing...' : 'Place Order'}
                   <ChevronRightIcon className="h-5 w-5 ml-1" />
                 </button>
               )}
             </div>
           </form>
         </div>
       </div>
       <div className="mt-8 bg-blue-50 border-l-4 border-blue-400 p-4">
         <div className="flex">
           <div className="flex-shrink-0">
             <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
               <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
             </svg>
           </div>
           <div className="ml-3">
             <p className="text-sm text-blue-700">
               Your payment and personal information are always safe. Our Secure Sockets Layer (SSL) software is the industry standard and among the best software available today for secure commerce transactions.
             </p>
           </div>
         </div>
       </div>
       {showConfirmation && (
         <OrderConfirmationModal
           orderNumber={orderNumber}
           onClose={() => {
             setShowConfirmation(false);
             navigate('/');
           }}
         />
       )}
     </motion.div>
   );
 };
 
 export default CheckoutPage;