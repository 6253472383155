import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  MicrophoneIcon, BuildingOffice2Icon, ComputerDesktopIcon, ShoppingBagIcon,
  HomeIcon, BeakerIcon, BriefcaseIcon, CameraIcon, MusicalNoteIcon, TruckIcon,
  CubeIcon, ExclamationCircleIcon
} from '@heroicons/react/24/solid';
import { getCategories, getAssets } from '../services/api';
import { toast } from 'react-toastify';
import AssetCard from '../components/AssetCard';

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState({
    categories: false,
    assets: false,
  });
  const [error, setError] = useState({
    categories: null,
    assets: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();

  const categoryIcons = {
    'audio-visual': MicrophoneIcon,
    'construction': BuildingOffice2Icon,
    'electronics': ComputerDesktopIcon,
    'fashion': ShoppingBagIcon,
    'home-garden': HomeIcon,
    'industrial': BeakerIcon,
    'medical-equipment': BriefcaseIcon,
    'office-equipment': BriefcaseIcon,
    'party-equipment': MusicalNoteIcon,
    'photography': CameraIcon,
    'sports-leisure': MusicalNoteIcon,
    'vehicles': TruckIcon,
  };

  const fetchCategories = useCallback(async () => {
    setLoading(prev => ({ ...prev, categories: true }));
    setError(prev => ({ ...prev, categories: null }));
    try {
      const response = await getCategories();
      if (response.data && Array.isArray(response.data.results)) {
        setCategories(response.data.results);
      } else {
        throw new Error('Unexpected categories data structure');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError(prev => ({ ...prev, categories: 'Failed to load categories. Please try again later.' }));
      toast.error('Failed to load categories. Please try again later.');
    } finally {
      setLoading(prev => ({ ...prev, categories: false }));
    }
  }, []);

  const fetchAssets = useCallback(async (categorySlug, page = 1) => {
    setLoading(prev => ({ ...prev, assets: true }));
    setError(prev => ({ ...prev, assets: null }));
    try {
      const response = await getAssets({
        category: categorySlug,
        page,
        limit: 8,
        featured: true,
      });
      setAssets(response.data.results || []);
      setTotalPages(Math.ceil(response.data.count / 8));
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching assets:', error);
      setError(prev => ({ ...prev, assets: 'Failed to fetch assets. Please try again later.' }));
      toast.error('Failed to fetch assets. Please try again later.');
    } finally {
      setLoading(prev => ({ ...prev, assets: false }));
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleCategoryClick = useCallback((category) => {
    setSelectedCategory(category);
    fetchAssets(category.slug, 1);
  }, [fetchAssets]);

  const handlePageChange = useCallback((newPage) => {
    if (newPage >= 1 && newPage <= totalPages && selectedCategory) {
      fetchAssets(selectedCategory.slug, newPage);
    }
  }, [fetchAssets, selectedCategory, totalPages]);

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Explore Items by Category</h1>

        {loading.categories ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
          </div>
        ) : error.categories ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-8"
            role="alert"
          >
            <div className="flex items-center">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
              <strong className="font-bold">Error!</strong>
            </div>
            <span className="block sm:inline mt-1">{error.categories}</span>
          </motion.div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
            {categories.map((category) => {
              const IconComponent = categoryIcons[category.slug] || CubeIcon;
              return (
                <button
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                  className={`p-4 rounded-lg shadow-md transition duration-300 ease-in-out ${
                    selectedCategory?.id === category.id
                      ? 'bg-primary text-white'
                      : 'bg-white hover:bg-gray-50'
                  } flex flex-col sm:flex-row items-center sm:items-start justify-center sm:justify-start h-auto sm:h-24 w-full`}
                >
                  <IconComponent className="h-8 w-8 mb-2 sm:mb-0 sm:mr-4" />
                  <span className="font-medium text-xs sm:text-sm text-center sm:text-left">{category.name}</span>
                </button>
              );
            })}
          </div>
        )}

        {selectedCategory && (
          <>
            <h2 className="text-2xl font-bold text-gray-900 mb-4">{selectedCategory.name} Assets</h2>
            {loading.assets ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
              </div>
            ) : error.assets ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-8"
                role="alert"
              >
                <div className="flex items-center">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
                  <strong className="font-bold">Error!</strong>
                </div>
                <span className="block sm:inline mt-1">{error.assets}</span>
              </motion.div>
            ) : (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-8">
                  {assets.map((asset) => (
                    <AssetCard key={asset.id} asset={asset} />
                  ))}
                </div>
                {totalPages > 1 && (
                  <div className="flex justify-center items-center mt-8">
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="mr-2 px-3 py-1 bg-primary text-white rounded-md disabled:opacity-50"
                    >
                      Previous
                    </button>
                    <span className="mx-2">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="ml-2 px-3 py-1 bg-primary text-white rounded-md disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                )}
                <div className="text-center mt-8">
                  <Link
                    to={`/search-results?${new URLSearchParams({
                      category: selectedCategory.slug,
                    }).toString()}`}
                    className="inline-block bg-primary text-white font-bold py-2 px-6 rounded-full hover:bg-primary-dark transition duration-300"
                  >
                    View All {selectedCategory.name} Items
                  </Link>
                </div>
              </>
            )}
          </>
        )}

        {!selectedCategory && !loading.categories && !error.categories && (
          <p className="text-center text-gray-600 mt-8">
            Select a category to view available items.
          </p>
        )}

        <div className="mt-12 text-center">
          <p className="text-gray-600">
            Can't find what you're looking for?{' '}
            <Link to="/contact" className="text-primary hover:text-primary-dark font-medium">
              Contact us
            </Link>{' '}
            and we'll help you find the right item.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CategoriesPage;
