import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getPopularAssets } from '../services/api';
import AssetCard from '../components/AssetCard';
import { toast } from 'react-toastify';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';
import { handleRateLimit } from '../utils/rateLimitHandler';
import { ArrowPathIcon, FireIcon } from '@heroicons/react/24/outline';
import { useInView } from 'react-intersection-observer';

const ITEMS_PER_PAGE = 12;

const PopularAssetsPage = () => {
  const [popularAssets, setPopularAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  // Color scheme
  const colors = {
    primary: 'bg-orange-600 hover:bg-orange-700 text-white',
    secondary: 'bg-orange-500 hover:bg-orange-600 text-white',
    accent: 'bg-orange-200 hover:bg-orange-300 text-orange-900',
    background: 'bg-gray-100', // Light grey background
    text: 'text-gray-800', // Dark text for contrast
    muted: 'text-gray-600', // Slightly lighter text for secondary elements
  };

  const fetchPopularAssets = useCallback(async (pageNumber) => {
    try {
      setLoading(true);
      setError(null);
      const response = await getPopularAssets({ page: pageNumber, page_size: ITEMS_PER_PAGE });
      
      if (!response || !response.data) {
        throw new Error('Invalid response from server');
      }

      let newAssets = [];
      let total = 0;

      if (Array.isArray(response.data)) {
        newAssets = response.data;
        total = response.data.length;
      } else if (response.data.results && Array.isArray(response.data.results)) {
        newAssets = response.data.results;
        total = response.data.count || response.data.results.length;
      } else {
        throw new Error('Unexpected data structure in response');
      }

      if (pageNumber === 1) {
        setPopularAssets(newAssets);
      } else {
        setPopularAssets((prevAssets) => {
          const uniqueNewAssets = newAssets.filter(newAsset => 
            !prevAssets.some(prevAsset => prevAsset.id === newAsset.id)
          );
          return [...prevAssets, ...uniqueNewAssets];
        });
      }
      setTotalItems(total);
      setHasMore(newAssets.length === ITEMS_PER_PAGE);
    } catch (err) {
      console.error('Error fetching popular items:', err);
      if (err.response && err.response.status === 429) {
        const retryAfter = err.response.headers['retry-after'] || 60;
        handleRateLimit('popularAssets', retryAfter);
        setError(`Rate limited. Please try again in ${retryAfter} seconds.`);
      } else {
        setError(`Failed to fetch popular items: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPopularAssets(1);
  }, [fetchPopularAssets]);

  useEffect(() => {
    if (inView && !loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, loading, hasMore]);

  useEffect(() => {
    if (page > 1) {
      fetchPopularAssets(page);
    }
  }, [page, fetchPopularAssets]);

  const handleRefresh = () => {
    setPage(1);
    fetchPopularAssets(1);
    toast.success('Popular items refreshed successfully!');
  };

  const renderAssets = useMemo(() => {
    if (loading && popularAssets.length === 0) {
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
            <div key={index} className={`${colors.background} p-4 rounded-lg shadow animate-pulse`}>
              <div className="w-full h-48 bg-orange-200 rounded mb-4"></div>
              <div className="h-4 bg-orange-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-orange-200 rounded w-1/2"></div>
            </div>
          ))}
        </div>
      );
    }

    if (error) {
      return <ErrorMessage message={error} />;
    }

    if (popularAssets.length === 0) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className={`text-center ${colors.text} mt-8 p-8 ${colors.background} rounded-lg shadow`}
        >
          <FireIcon className={`h-12 w-12 ${colors.muted} mx-auto mb-4`} />
          <p className="text-xl font-semibold">No popular items found.</p>
          <p className="mt-2">Check back later for trending items!</p>
        </motion.div>
      );
    }

    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8"
        >
          <AnimatePresence>
            {popularAssets.map((asset) => (
              <motion.div
                key={asset.id}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3 }}
              >
                <AssetCard asset={asset} />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
        {loading && (
          <div className="flex justify-center items-center h-20">
            <LoadingSpinner />
          </div>
        )}
      </>
    );
  }, [popularAssets, loading, error, colors]);

  return (
    <div className={`${colors.background} min-h-screen py-8`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col sm:flex-row justify-between items-center mb-8"
        >
          <h1 className={`text-3xl font-bold ${colors.text} flex items-center mb-4 sm:mb-0`}>
            <FireIcon className="h-8 w-8 text-orange-500 mr-2" />
            Popular Items
          </h1>
          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <span className={colors.muted}>
              Showing {popularAssets.length} of {totalItems} items
            </span>
            <button
              onClick={handleRefresh}
              className={`w-full sm:w-auto ${colors.primary} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center justify-center`}
              disabled={loading}
            >
              <ArrowPathIcon className={`h-5 w-5 mr-2 ${loading ? 'animate-spin' : ''}`} />
              {loading ? 'Refreshing...' : 'Refresh'}
            </button>
          </div>
        </motion.div>

        {renderAssets}

        {hasMore && (
          <div ref={ref} className="h-20">
            {/* Intersection Observer target */}
          </div>
        )}

        {!hasMore && popularAssets.length > 0 && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className={`text-center ${colors.muted} mt-8`}
          >
            You've reached the end of the list.
          </motion.p>
        )}
      </div>
    </div>
  );
};

export default PopularAssetsPage;
