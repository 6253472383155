import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HeartIcon, ShoppingCartIcon, StarIcon, CurrencyDollarIcon, ClockIcon, TagIcon, MapPinIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import { motion, AnimatePresence } from 'framer-motion';
import { useWishlist } from '../contexts/WishlistContext';
import { useCart } from '../contexts/CartContext';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import { formatCurrency } from '../utils/formatters';

const AssetCard = ({ asset, isWishlistPage = false }) => {
  const { isInWishlist, addToWishlist, removeFromWishlist, loading: wishlistLoading } = useWishlist();
  const { addToCart, loading: cartLoading } = useCart();
  const { user } = useAuth();
  const [showPricing, setShowPricing] = useState(false);

  // Updated color scheme
  const colors = {
    primary: 'bg-orange-600 hover:bg-orange-700 text-white',
    secondary: 'bg-orange-500 hover:bg-orange-600 text-white',
    accent: 'text-orange-600 hover:text-orange-700',
    background: 'bg-white',
    text: 'text-gray-800',
    muted: 'text-gray-600',
    border: 'border-gray-200',
  };

  const handleWishlistToggle = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user) {
      toast.error('Please login to manage your wishlist');
      return;
    }
    if (wishlistLoading || !asset || !asset.id) return;

    try {
      if (isInWishlist(asset.id)) {
        await removeFromWishlist(asset.id);
        toast.success('Removed from wishlist');
      } else {
        await addToWishlist(asset.id);
        toast.success('Added to wishlist');
      }
    } catch (error) {
      console.error('Wishlist operation failed:', error);
      toast.error('Failed to update wishlist. Please try again.');
    }
  }, [user, wishlistLoading, isInWishlist, removeFromWishlist, addToWishlist, asset]);

  const handleAddToCart = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user) {
      toast.error('Please login to add items to cart');
      return;
    }
    if (cartLoading || !asset || !asset.id || !asset.is_available) return;

    try {
      await addToCart(asset.id, 1, 1);
      toast.success('Added to cart');
    } catch (err) {
      console.error('Error adding item to cart:', err);
      toast.error('Failed to add item to cart. Please try again.');
    }
  }, [user, cartLoading, addToCart, asset]);

  const truncate = (text, length) => {
    if (!text) return '';
    return text.length > length ? `${text.substring(0, length)}...` : text;
  };

  const getRates = useMemo(() => {
    if (!asset) return [];
    return [
      { rate: asset.hourly_rate, period: 'hour' },
      { rate: asset.daily_rate, period: 'day' },
      { rate: asset.weekly_rate, period: 'week' },
      { rate: asset.monthly_rate, period: 'month' }
    ].filter(item => parseFloat(item.rate) > 0)
      .map(item => ({ ...item, formattedRate: formatCurrency(parseFloat(item.rate)) }));
  }, [asset]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
      className={`${colors.background} rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl h-full flex flex-col border ${colors.border}`}
    >
      <div className="relative">
        <img
          src={asset.images && asset.images.length > 0 ? asset.images[0] : 'https://via.placeholder.com/300x200?text=No+Image'}
          alt={asset.name}
          className="w-full h-48 object-cover"
        />
        <span className={`absolute top-2 left-2 ${asset.is_available ? 'bg-green-500' : 'bg-red-500'} text-white text-xs font-bold px-2 py-1 rounded-full`}>
          {asset.is_available ? 'Available' : 'Unavailable'}
        </span>
        {asset.is_featured && (
          <span className="absolute top-2 right-2 bg-yellow-400 text-black text-xs font-bold px-2 py-1 rounded-full">
            Featured
          </span>
        )}
      </div>
      <div className="p-4 flex flex-col flex-grow">
        <div className="flex justify-between items-start mb-2">
          <h3 className={`text-lg font-semibold ${colors.text} truncate`}>
            {truncate(asset.name, 20)}
          </h3>
          <button
            onClick={handleWishlistToggle}
            disabled={wishlistLoading || !user}
            className={`${colors.accent} focus:outline-none transition duration-150 ease-in-out ${(wishlistLoading || !user) ? 'opacity-50 cursor-not-allowed' : ''}`}
            aria-label={isInWishlist(asset.id) ? "Remove from wishlist" : "Add to wishlist"}
          >
            {isInWishlist(asset.id) ? (
              <HeartIconSolid className="h-5 w-5 text-red-600" />
            ) : (
              <HeartIcon className="h-5 w-5" />
            )}
          </button>
        </div>
        <div className={`text-xs ${colors.muted} mb-1 flex items-center`}>
          <TagIcon className="h-3 w-3 mr-1" />
          {truncate(asset.category?.name || 'Uncategorized', 15)}
        </div>
        <div className={`text-xs ${colors.muted} mb-1 flex items-center`}>
          <ClockIcon className="h-3 w-3 mr-1" />
          Min: {asset.minimum_rental_period || 'N/A'} hr(s)
        </div>
        <div className={`text-xs ${colors.muted} mb-2 flex items-center`}>
          <MapPinIcon className="h-3 w-3 mr-1" />
          {truncate(asset.location?.name || 'Location N/A', 15)}
        </div>
        <div className="mt-1 mb-2">
          <button
            onClick={() => setShowPricing(!showPricing)}
            className={`flex items-center ${colors.accent} focus:outline-none transition duration-150 ease-in-out text-xs`}
          >
            <InformationCircleIcon className="h-3 w-3 mr-1" />
            {showPricing ? 'Hide Pricing' : 'Show Pricing'}
          </button>
          <AnimatePresence>
            {showPricing && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="mt-1 text-xs"
              >
                {getRates.map((rate, index) => (
                  <div key={index} className="flex justify-between items-center">
                    <span className={colors.muted}>{rate.period}ly:</span>
                    <span className={`font-medium ${colors.text}`}>{rate.formattedRate}</span>
                  </div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="flex items-center mb-2 text-xs">
          <StarIcon className="h-3 w-3 text-yellow-400 mr-1" />
          <span className={colors.muted}>
            {asset.average_rating ? asset.average_rating.toFixed(1) : 'N/A'} ({asset.total_rentals || 0} rentals)
          </span>
        </div>
        <div className="mt-auto flex justify-between items-center">
          <Link
            to={`/assets/${asset.slug}`}
            className={`${colors.primary} text-center font-bold py-1 px-2 rounded text-xs transition duration-300`}
          >
            View Details
          </Link>
          {!isWishlistPage && (
            <button
              onClick={handleAddToCart}
              disabled={cartLoading || !asset.is_available || !user}
              className={`${colors.secondary} font-bold py-1 px-2 rounded text-xs transition duration-300 flex items-center ${(cartLoading || !asset.is_available || !user) ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <ShoppingCartIcon className="h-3 w-3 mr-1" />
              Add to Cart
            </button>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default AssetCard;