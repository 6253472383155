import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../components/ui/tabs";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Alert, AlertDescription } from "../../components/ui/alert";
import { format, parseISO } from 'date-fns';
import { RefreshCw } from 'lucide-react';
import apiService from '../../services/api';

const RewardsManagement = () => {
  const [activeTab, setActiveTab] = useState('loyalty');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = useCallback(async (page = 1) => {
    setIsLoading(true);
    setError(null);
    try {
      let response;
      switch (activeTab) {
        case 'loyalty':
          response = await apiService.getLoyaltyPrograms({ page });
          break;
        case 'coupons':
          response = await apiService.getCoupons({ page });
          break;
        case 'campaigns':
          response = await apiService.getPromotionalCampaigns({ page });
          break;
        default:
          throw new Error('Invalid tab');
      }
      console.log('API Response:', response.data); // Log the response data
      setData(response.data.results || []);
      setCurrentPage(page);
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
    } catch (err) {
      setError('Failed to fetch data. Please try again.');
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [activeTab]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatDate = (dateValue) => {
    if (!dateValue) return 'N/A';
    try {
      return format(parseISO(dateValue), 'yyyy-MM-dd');
    } catch (error) {
      console.error(`Error parsing date: ${dateValue}`, error);
      return 'Invalid Date';
    }
  };

  const getCellContent = (item, header) => {
    switch (header) {
      case 'Name':
        return item.name || 'N/A';
      case 'Points per KES':
        return item.points_per_dollar || 'N/A';
      case 'Min Points Redemption':
        return item.min_points_redemption || 'N/A';
      case 'Code':
        return item.code || 'N/A';
      case 'Discount':
        return item.discount_value ? `${item.discount_value}${item.discount_type === 'percentage' ? '%' : ' KES'}` : 'N/A';
      case 'Valid Until':
        return formatDate(item.valid_to || item.valid_until);
      case 'Start Date':
        return formatDate(item.start_date);
      case 'End Date':
        return formatDate(item.end_date);
      case 'Status':
        return (
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${item.is_active ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {item.is_active ? 'Active' : 'Inactive'}
          </span>
        );
      default:
        return 'N/A';
    }
  };

  const renderTable = () => {
    const headers = {
      loyalty: ['Name', 'Points per KES', 'Min Points Redemption', 'Status'],
      coupons: ['Code', 'Discount', 'Valid Until', 'Status'],
      campaigns: ['Name', 'Start Date', 'End Date', 'Status'],
    };

    return (
      <Table>
        <TableHeader>
          <TableRow>
            {headers[activeTab].map((header) => (
              <TableHead key={header}>{header}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              {headers[activeTab].map((header) => (
                <TableCell key={header}>{getCellContent(item, header)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Card className="w-full max-w-4xl mx-auto">
      <CardHeader>
        <CardTitle>Rewards Management</CardTitle>
        <CardDescription>View your loyalty programs, coupons, and promotional campaigns</CardDescription>
      </CardHeader>
      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div className="flex justify-between items-center mb-4">
          <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
            <TabsList>
              <TabsTrigger value="loyalty">Loyalty Programs</TabsTrigger>
              <TabsTrigger value="coupons">Coupons</TabsTrigger>
              <TabsTrigger value="campaigns">Promotional Campaigns</TabsTrigger>
            </TabsList>
          </Tabs>
          <Button variant="outline" onClick={() => fetchData(currentPage)} disabled={isLoading}>
            <RefreshCw className={`w-4 h-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <RefreshCw className="w-8 h-8 animate-spin" />
          </div>
        ) : data.length > 0 ? (
          renderTable()
        ) : (
          <p className="text-center py-4">No data available.</p>
        )}
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchData(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchData(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default RewardsManagement;