import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import { CreditCard, ShieldCheck, AlertCircle, RefreshCw, Phone } from 'lucide-react';
import apiService from '../../services/api';
import Spinner from '../../components/Spinner';
import ErrorMessage from '../../components/ErrorMessage';
import { formatCurrency } from '../../utils/formatters';

const PaymentStatus = {
  IDLE: 'idle',
  INITIATED: 'initiated',
  CHECKING: 'checking',
  COMPLETED: 'completed',
  FAILED: 'failed',
  TIMEOUT: 'timeout',
  ERROR: 'error'
};

const useExponentialBackoff = (initialDelay, maxDelay, maxAttempts) => {
  const [delay, setDelay] = useState(initialDelay);
  const [attempts, setAttempts] = useState(0);

  const reset = useCallback(() => {
    setDelay(initialDelay);
    setAttempts(0);
  }, [initialDelay]);

  const next = useCallback(() => {
    setAttempts((prevAttempts) => prevAttempts + 1);
    setDelay((prevDelay) => Math.min(prevDelay * 2, maxDelay));
  }, [maxDelay]);

  return { delay, attempts, reset, next, maxAttempts };
};

const MpesaPaymentPage = () => {
  const [state, setState] = useState({
    orderData: null,
    phoneNumber: '',
    loading: false,
    paymentStatus: PaymentStatus.IDLE,
    checkoutRequestId: null,
    error: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { delay, attempts, reset, next, maxAttempts } = useExponentialBackoff(1000, 32000, 15);

  const updateState = useCallback((newState) => {
    setState(prevState => ({ ...prevState, ...newState }));
  }, []);

  useEffect(() => {
    const loadOrderData = () => {
      if (location.state && location.state.orderData) {
        return location.state.orderData;
      }
      const storedOrderData = sessionStorage.getItem('orderData');
      if (storedOrderData) {
        return JSON.parse(storedOrderData);
      }
      return null;
    };

    const orderData = loadOrderData();
    if (orderData) {
      updateState({ 
        orderData, 
        phoneNumber: orderData.billing_info?.phone || ''
      });
    } else {
      toast.error('No order data found. Please start over.');
      navigate('/checkout');
    }
  }, [navigate, updateState, location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateState({ loading: true, error: null, paymentStatus: PaymentStatus.IDLE });
  
    if (!state.orderData || !state.orderData.id) {
      updateState({ error: 'Invalid order data. Please try again.', loading: false, paymentStatus: PaymentStatus.ERROR });
      return;
    }
  
    const paymentData = {
      order_id: state.orderData.id,
      payment_method: 'mpesa',
      phone_number: state.phoneNumber,
      amount: calculateTotal(),
    };
  
    try {
      const response = await apiService.initiatePayment(paymentData);
  
      if (response.checkout_request_id) {
        updateState({
          checkoutRequestId: response.checkout_request_id,
          accountReference: response.payment_id || state.orderData.id,
          paymentStatus: PaymentStatus.INITIATED
        });
        toast.info('M-Pesa payment request sent. Please check your phone to complete the transaction.');
        reset();
        checkPaymentStatus(response.checkout_request_id);
      } else {
        throw new Error('Failed to initiate M-Pesa payment: Missing checkout request ID');
      }
    } catch (error) {
      console.error('M-Pesa payment error:', error);
      updateState({
        error: error.response?.data?.error || error.message || 'Failed to initiate payment. Please try again.',
        paymentStatus: PaymentStatus.ERROR
      });
      toast.error('An error occurred while processing your payment. Please try again.');
    } finally {
      updateState({ loading: false });
    }
  };

  const checkPaymentStatus = useCallback(async (checkoutRequestId) => {
    updateState({ paymentStatus: PaymentStatus.CHECKING });
    
    try {
      const response = await apiService.checkPaymentStatus(checkoutRequestId);

      if (response.payment_status === 'completed') {
        updateState({ paymentStatus: PaymentStatus.COMPLETED });
        toast.success('Payment completed successfully!');
        navigate(`/order-confirmation/${state.orderData.id}`, {
          state: { paymentInitiationResponse: response, orderId: state.orderData.id }
        });
      } else if (response.payment_status === 'failed') {
        updateState({
          paymentStatus: PaymentStatus.FAILED,
          error: 'Payment failed. Please try again.'
        });
        toast.error('Payment failed. Please try again.');
      } else if (response.payment_status === 'pending') {
        if (attempts < maxAttempts) {
          next();
          setTimeout(() => checkPaymentStatus(checkoutRequestId), delay);
        } else {
          handlePaymentTimeout(state.orderData.id);
        }
      } else {
        throw new Error(`Unexpected payment status: ${response.payment_status}`);
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      if (attempts < maxAttempts) {
        next();
        setTimeout(() => checkPaymentStatus(checkoutRequestId), delay);
      } else {
        updateState({
          error: 'Error checking payment status. Please contact support.',
          paymentStatus: PaymentStatus.ERROR
        });
      }
    }
  }, [attempts, delay, maxAttempts, navigate, next, state.orderData]);

  const handlePaymentTimeout = async (orderId) => {
    try {
      await apiService.handlePaymentTimeout(orderId);
      updateState({
        paymentStatus: PaymentStatus.TIMEOUT,
        error: 'Payment status check timed out. Please check your M-Pesa app or contact support.'
      });
      toast.warn('Payment status check timed out. Please check your M-Pesa app or contact support.');
    } catch (error) {
      console.error('Error handling payment timeout:', error);
      updateState({
        error: 'Error handling payment timeout. Please contact support.',
        paymentStatus: PaymentStatus.ERROR
      });
    }
  };

  const calculateTotal = useCallback(() => {
    if (!state.orderData || !state.orderData.items) return 0;
    const subtotal = state.orderData.items.reduce((total, item) => {
      return total + (parseFloat(item.unit_price) * item.quantity * item.hours);
    }, 0);
    const couponDiscount = parseFloat(state.orderData.coupon_discount) || 0;
    return Math.max(subtotal - couponDiscount, 0);
  }, [state.orderData]);

  const renderPaymentStatus = () => {
    const statusConfig = {
      [PaymentStatus.INITIATED]: {
        className: "bg-blue-100 border-l-4 border-blue-500 text-blue-700",
        message: "Payment initiated. Please check your phone for the M-Pesa prompt."
      },
      [PaymentStatus.CHECKING]: {
        className: "bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700",
        message: "Checking payment status... This may take a few moments."
      },
      [PaymentStatus.COMPLETED]: {
        className: "bg-green-100 border-l-4 border-green-500 text-green-700",
        message: "Payment completed successfully!"
      },
      [PaymentStatus.FAILED]: {
        className: "bg-red-100 border-l-4 border-red-500 text-red-700",
        message: state.error || "Payment failed. Please try again."
      },
      [PaymentStatus.TIMEOUT]: {
        className: "bg-red-100 border-l-4 border-red-500 text-red-700",
        message: state.error || "Payment timed out. Please check your M-Pesa app or try again."
      },
      [PaymentStatus.ERROR]: {
        className: "bg-red-100 border-l-4 border-red-500 text-red-700",
        message: state.error || "An error occurred. Please try again or contact support."
      }
    };

    const config = statusConfig[state.paymentStatus];
    if (!config) return null;

    return (
      <div className={`${config.className} p-4 rounded-md mb-4`}>
        <p className="text-sm font-medium">{config.message}</p>
      </div>
    );
  };

  const renderOrderSummary = () => {
    if (!state.orderData || !state.orderData.items) {
      return <p>No order items found.</p>;
    }
  
    const subtotal = state.orderData.items.reduce((total, item) => {
      return total + (parseFloat(item.unit_price) * item.quantity * item.hours);
    }, 0);
    const couponDiscount = parseFloat(state.orderData.coupon_discount) || 0;
  
    return (
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Order Items:</h3>
        <ul>
          {state.orderData.items.map((item, index) => (
            <li key={index} className="flex justify-between items-center mb-2">
              <span className="text-sm">
                {item.asset_name || `Item ${index + 1}`} (x{item.quantity})
              </span>
              <div className="flex items-center space-x-4">
                <span className="text-sm">
                  {item.hours || 1} hour(s)
                </span>
                <span className="text-sm font-medium">
                  {formatCurrency(parseFloat(item.unit_price) * item.quantity * item.hours)}
                </span>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-between items-center mt-4 font-medium">
          <span>Subtotal</span>
          <span>{formatCurrency(subtotal)}</span>
        </div>
        {couponDiscount > 0 && (
          <div className="flex justify-between items-center mt-2 text-green-600">
            <span>Coupon Discount</span>
            <span>-{formatCurrency(couponDiscount)}</span>
          </div>
        )}
        <div className="flex justify-between items-center mt-2 font-bold">
          <span>Total</span>
          <span>{formatCurrency(calculateTotal())}</span>
        </div>
      </div>
    );
  };

  const renderPaymentForm = () => (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
          M-Pesa Phone Number
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Phone className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            placeholder="254 7XX XXX XXX"
            value={state.phoneNumber}
            onChange={(e) => updateState({ phoneNumber: e.target.value })}
            required
            disabled={state.paymentStatus !== PaymentStatus.IDLE && state.paymentStatus !== PaymentStatus.FAILED}
          />
        </div>
      </div>
      <button
        type="submit"
        disabled={state.loading || state.paymentStatus === PaymentStatus.CHECKING || state.paymentStatus === PaymentStatus.COMPLETED}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {state.loading ? 'Processing...' : state.paymentStatus === PaymentStatus.CHECKING ? 'Checking payment status...' : 'Pay with M-Pesa'}
      </button>
    </form>
  );

  const handleRetry = () => {
    updateState({ paymentStatus: PaymentStatus.IDLE, error: null });
    reset();
  };

  if (state.loading && state.paymentStatus === PaymentStatus.IDLE) {
    return <Spinner />;
  }

  if (state.error && state.paymentStatus === PaymentStatus.IDLE) {
    return <ErrorMessage message={state.error} />;
  }

  if (!state.orderData) {
    return <ErrorMessage message="Failed to load order data. Please try again." />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-3xl mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-gray-900 mb-8 flex items-center">
        <CreditCard className="h-8 w-8 mr-2 text-green-500" />
        M-Pesa Payment
      </h1>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
          {renderOrderSummary()}
          <AnimatePresence>
            {renderPaymentStatus()}
          </AnimatePresence>
          {(state.paymentStatus === PaymentStatus.IDLE || state.paymentStatus === PaymentStatus.FAILED) && renderPaymentForm()}
          {(state.paymentStatus === PaymentStatus.ERROR || state.paymentStatus === PaymentStatus.TIMEOUT) && (
            <button
              onClick={handleRetry}
              className="mt-4 w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <RefreshCw className="h-5 w-5 mr-2" />
              Retry Payment
            </button>
          )}
        </div>
      </div>
      <PaymentInstructions />
      {state.paymentStatus === PaymentStatus.CHECKING && <PaymentCheckingNotice />}
      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Need Help?</h3>
        <p className="text-sm text-gray-600 mb-2">
          If you're experiencing any issues with your payment, please don't hesitate to contact our support team.
        </p>
        <a 
          href="/contact" 
          className="text-indigo-600 hover:text-indigo-800 transition-colors duration-200"
          >
          Contact Support
        </a>
      </div>
    </motion.div>
  );
};

const PaymentInstructions = () => (
  <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-md mb-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <ShieldCheck className="h-5 w-5 text-blue-400" />
      </div>
      <div className="ml-3">
        <p className="text-sm text-blue-700">
          You'll receive a prompt on your phone to complete the M-Pesa payment. Please ensure your phone is on and has sufficient balance.
        </p>
        <ul className="list-disc list-inside mt-2 text-sm text-blue-600">
          <li>Enter your M-Pesa PIN when prompted</li>
          <li>Do not refresh or close this page during the payment process</li>
          <li>If you don't receive a prompt, check your phone's notifications</li>
        </ul>
      </div>
    </div>
  </div>
);

const PaymentCheckingNotice = () => (
  <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md mb-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <AlertCircle className="h-5 w-5 text-yellow-400" />
      </div>
      <div className="ml-3">
        <p className="text-sm text-yellow-700">
          We are checking the payment status. This may take a few minutes. Please don't close this page.
        </p>
        <p className="text-sm text-yellow-600 mt-2">
          If the payment is taking longer than expected, please check your M-Pesa app or SMS for confirmation.
        </p>
      </div>
    </div>
  </div>
);

export default MpesaPaymentPage;