import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAssets } from '../services/api';
import AssetCard from '../components/AssetCard';
import { motion } from 'framer-motion';
import { SparklesIcon } from '@heroicons/react/24/outline';

// Updated color scheme
const colors = {
  primary: 'bg-orange-600 hover:bg-orange-700 text-white',
  secondary: 'bg-orange-500 hover:bg-orange-600 text-white',
  accent: 'text-orange-600',
  background: 'bg-gray-100', // Light grey background
  text: 'text-gray-800', // Dark text for contrast
  muted: 'text-gray-600', // Slightly lighter text for secondary elements
  card: 'bg-white', // White background for cards
};

const NewArrivalsPage = () => {
  const [newAssets, setNewAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchNewArrivals = async (pageNumber) => {
    try {
      setLoading(true);
      const response = await getAssets({ 
        ordering: '-created_at', 
        page: pageNumber, 
        page_size: 12 
      });
      const newData = response.data.results;
      if (pageNumber === 1) {
        setNewAssets(newData);
      } else {
        setNewAssets(prevAssets => [...prevAssets, ...newData]);
      }
      setHasMore(response.data.next !== null);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching new arrivals:', err);
      setError('Failed to fetch new arrivals. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNewArrivals(1);
  }, []);

  const loadMore = () => {
    if (!loading && hasMore) {
      setPage(prevPage => prevPage + 1);
      fetchNewArrivals(page + 1);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100
      }
    }
  };

  return (
    <div className={`${colors.background} min-h-screen py-12 px-4 sm:px-6 lg:px-8`}>
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <motion.h1 
            className={`text-4xl font-bold ${colors.text} mb-4 flex items-center justify-center`}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <SparklesIcon className="h-8 w-8 text-yellow-400 mr-2" />
            New Arrivals
          </motion.h1>
          <motion.p 
            className={`text-xl ${colors.muted}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            Discover our latest additions to the rental collection
          </motion.p>
        </div>
        
        {error && (
          <div className="text-center text-red-600 mb-8">
            {error}
          </div>
        )}

        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {newAssets.map((asset) => (
            <motion.div key={asset.id} variants={childVariants}>
              <AssetCard asset={asset} />
            </motion.div>
          ))}
        </motion.div>

        {loading && (
          <div className="text-center mt-8">
            <div className={`inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 ${colors.accent}`}></div>
          </div>
        )}

        {!loading && hasMore && (
          <div className="text-center mt-12">
            <button
              onClick={loadMore}
              className={`${colors.primary} font-bold py-2 px-4 rounded transition duration-300`}
            >
              Load More
            </button>
          </div>
        )}

        {!loading && !hasMore && newAssets.length > 0 && (
          <div className={`text-center mt-12 ${colors.muted}`}>
            You've reached the end of the list.
          </div>
        )}

        <div className="mt-12 text-center">
          <Link
            to="/assets"
            className={`inline-block ${colors.secondary} font-bold py-2 px-4 rounded transition duration-300`}
          >
            View All Assets
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewArrivalsPage;