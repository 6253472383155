import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FunnelIcon, ArrowsUpDownIcon, HeartIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import AssetCard from '../components/AssetCard';
import Spinner from '../components/Spinner';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import apiService from '../services/api';

const WishlistPage = () => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterCategory, setFilterCategory] = useState('');
  const [isClearing, setIsClearing] = useState(false);

  const fetchWishlist = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.getWishlist();
      console.log('Fetched wishlist:', response.data);
      if (response && response.data && Array.isArray(response.data.items)) {
        setWishlistItems(response.data.items.map(item => item.asset));
      } else {
        setWishlistItems([]);
        console.error('Unexpected wishlist data structure:', response.data);
      }
    } catch (err) {
      console.error('Error fetching wishlist:', err);
      setError('Failed to load wishlist. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWishlist();
  }, [fetchWishlist]);

  const categories = useMemo(() => {
    if (!Array.isArray(wishlistItems) || wishlistItems.length === 0) return [];
    return [...new Set(wishlistItems.map(item => item.category?.name).filter(Boolean))];
  }, [wishlistItems]);

  const filteredAndSortedItems = useMemo(() => {
    if (!Array.isArray(wishlistItems) || wishlistItems.length === 0) return [];
    let items = wishlistItems;
    
    if (filterCategory) {
      items = items.filter(item => item.category?.name === filterCategory);
    }

    return items.sort((a, b) => {
      const aValue = a[sortBy] || '';
      const bValue = b[sortBy] || '';
      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [wishlistItems, filterCategory, sortBy, sortOrder]);

  const toggleSortOrder = useCallback(() => {
    setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
  }, []);

  const handleRemoveFromWishlist = useCallback(async (assetId) => {
    try {
      await apiService.removeFromWishlist({ assetId });
      setWishlistItems(prevItems => prevItems.filter(item => item.id !== assetId));
      toast.success('Item removed from wishlist');
    } catch (err) {
      console.error('Failed to remove item from wishlist:', err);
      toast.error('Failed to remove item from wishlist');
    }
  }, []);

  const handleClearWishlist = useCallback(async () => {
    if (window.confirm('Are you sure you want to clear your entire wishlist?')) {
      setIsClearing(true);
      try {
        await apiService.clearWishlist();
        setWishlistItems([]);
        toast.success('Wishlist cleared successfully');
      } catch (err) {
        console.error('Failed to clear wishlist:', err);
        toast.error('Failed to clear wishlist');
      } finally {
        setIsClearing(false);
      }
    }
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <button
          onClick={fetchWishlist}
          className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition duration-300"
        >
          Try Again
        </button>
      </div>
    );
  }

  if (wishlistItems.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 text-center">
        <HeartIcon className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">Your wishlist is empty</h3>
        <p className="mt-1 text-sm text-gray-500">Explore our assets and add items to your wishlist.</p>
        <div className="mt-6">
          <Link
            to="/assets"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Explore Assets
          </Link>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8"
    >
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Your Wishlist</h1>
      
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
        <div className="flex items-center">
          <FunnelIcon className="h-5 w-5 text-gray-400 mr-2" />
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">All Categories</option>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
        <div className="flex items-center">
          <ArrowsUpDownIcon className="h-5 w-5 text-gray-400 mr-2" />
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mr-2"
          >
            <option value="name">Name</option>
            <option value="hourly_rate">Price</option>
            <option value="category">Category</option>
          </select>
          <button
            onClick={toggleSortOrder}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center transition duration-300"
          >
            {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
          </button>
        </div>
      </div>
      
      <AnimatePresence>
        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          layout
        >
          {filteredAndSortedItems.map((item) => (
            <motion.div
              key={item.id}
              layout
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <AssetCard 
                asset={item} 
                isWishlistPage={true} 
                onRemoveFromWishlist={() => handleRemoveFromWishlist(item.id)}
              />
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>
      
      {wishlistItems.length > 0 && (
        <motion.div 
          className="mt-8 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <button
            onClick={handleClearWishlist}
            disabled={isClearing}
            className={`bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ${isClearing ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isClearing ? 'Clearing...' : 'Clear Wishlist'}
          </button>
        </motion.div>
      )}
    </motion.div>
  );
};

export default WishlistPage;