import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { RefreshCw, Search } from 'lucide-react';
import Spinner from '../../components/Spinner';
import apiService from '../../services/api';

const WishlistManagement = () => {
  const [wishlist, setWishlist] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedWishlistItem, setSelectedWishlistItem] = useState(null);
  const [isWishlistItemDialogOpen, setIsWishlistItemDialogOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const fetchWishlist = useCallback(async (page = 1) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await apiService.getWishlist({ page, search: searchTerm });
      if (response && response.data) {
        setWishlist(response.data);
        setTotalPages(Math.ceil(response.data.items.length / 10) || 1); // Assuming 10 items per page
        setCurrentPage(page);
      } else {
        setWishlist(null);
        setError("Unexpected response structure from the server.");
      }
    } catch (error) {
      console.error('Failed to fetch wishlist:', error);
      setError("Failed to fetch wishlist. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchWishlist();
  }, [fetchWishlist]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchWishlist(currentPage);
    setIsRefreshing(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleRemoveFromWishlist = async (itemId) => {
    try {
      await apiService.removeFromWishlist({ asset_id: itemId });
      await fetchWishlist(currentPage);
    } catch (error) {
      console.error('Failed to remove item from wishlist:', error);
      setError("Failed to remove item from wishlist. Please try again.");
    }
  };

  const getUserDisplayName = (user) => {
    if (!user) return 'Unknown User';
    return user.username || user.email || 'Unnamed User';
  };

  const renderWishlistItemDetails = (item) => {
    return (
      <div>
        <p><strong>User:</strong> {getUserDisplayName(wishlist.user)}</p>
        <p><strong>Email:</strong> {wishlist.user?.email || 'No email available'}</p>
        <p><strong>Asset:</strong> {item.asset?.name || 'Unknown Asset'}</p>
        <p><strong>Added At:</strong> {item.added_at ? format(new Date(item.added_at), 'yyyy-MM-dd HH:mm') : 'Unknown Date'}</p>
        <p><strong>Asset Description:</strong> {item.asset?.description || 'No description available'}</p>
        <p><strong>Asset Price:</strong> {item.asset?.hourly_rate ? `$${item.asset.hourly_rate}/hour` : 'Price not available'}</p>
      </div>
    );
  };

  return (
    <div className="mb-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
      <div className="p-4 bg-primary text-white">
        <h2 className="text-2xl font-bold">Wishlist Management</h2>
      </div>
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <Search className="w-5 h-5 mr-2 text-gray-500" />
            <Input
              placeholder="Search wishlist items..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-64"
            />
          </div>
          <Button onClick={handleRefresh} className="bg-accent text-white" disabled={isRefreshing}>
            {isRefreshing ? 'Refreshing...' : 'Refresh'}
            <RefreshCw className="ml-2 h-4 w-4" />
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : error ? (
          <p className="text-center py-4 text-red-500">{error}</p>
        ) : wishlist && wishlist.items.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>User</TableHead>
                  <TableHead>Asset</TableHead>
                  <TableHead>Added At</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {wishlist.items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{getUserDisplayName(wishlist.user)}</TableCell>
                    <TableCell>{item.asset?.name || 'Unknown Asset'}</TableCell>
                    <TableCell>{item.added_at ? format(new Date(item.added_at), 'yyyy-MM-dd HH:mm') : 'Unknown Date'}</TableCell>
                    <TableCell>
                      <Button variant="outline" size="sm" onClick={() => {
                        setSelectedWishlistItem(item);
                        setIsWishlistItemDialogOpen(true);
                      }}>
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No wishlist items available.</p>
        )}
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchWishlist(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchWishlist(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
        </div>
      </div>
      <Dialog open={isWishlistItemDialogOpen} onOpenChange={setIsWishlistItemDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Wishlist Item Details</DialogTitle>
          </DialogHeader>
          {selectedWishlistItem && renderWishlistItemDetails(selectedWishlistItem)}
          <DialogFooter>
            <Button onClick={() => setIsWishlistItemDialogOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WishlistManagement;