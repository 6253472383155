import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { RefreshCw, Search, Eye, ArrowUpDown } from 'lucide-react';
import Spinner from '../../components/Spinner';
import apiService from '../../services/api';

const PaymentManagement = () => {
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const fetchPayments = useCallback(async (page = 1) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await apiService.getPayments({ 
        page, 
        search: searchTerm,
        ordering: sortConfig.key ? `${sortConfig.direction === 'ascending' ? '' : '-'}${sortConfig.key}` : null
      });
      setPayments(response.data.results || []);
      setTotalPages(Math.ceil(response.data.count / 10));
      setCurrentPage(page);
    } catch (error) {
      console.error('Failed to fetch payments:', error);
      setError('Failed to fetch payments. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, sortConfig]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchPayments(currentPage);
    setIsRefreshing(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleStatusUpdate = async (paymentId, newStatus) => {
    try {
      await apiService.updatePaymentStatus(paymentId, { status: newStatus });
      setIsPaymentDialogOpen(false);
      fetchPayments(currentPage);
    } catch (error) {
      console.error('Failed to update payment status:', error);
      setError('Failed to update payment status. Please try again.');
    }
  };

  const formatAmount = (amount) => {
    if (typeof amount === 'number') {
      return amount.toFixed(2);
    } else if (typeof amount === 'string') {
      return parseFloat(amount).toFixed(2);
    }
    return 'N/A';
  };

  const renderPaymentDetails = (payment) => {
    return (
      <div>
        <p><strong>Payment ID:</strong> {payment.id}</p>
        <p><strong>User:</strong> {payment.user.last_name || 'N/A'}</p>
        <p><strong>Order ID:</strong> {payment.order?.id || 'N/A'}</p>
        <p><strong>Amount:</strong> KES {formatAmount(payment.amount)}</p>
        <p><strong>Payment Method:</strong> {payment.payment_method}</p>
        <p><strong>Status:</strong> {payment.status}</p>
        <p><strong>Transaction ID:</strong> {payment.transaction_id || 'N/A'}</p>
        <p><strong>Created At:</strong> {payment.created_at ? format(new Date(payment.created_at), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}</p>
        <p><strong>Updated At:</strong> {payment.updated_at ? format(new Date(payment.updated_at), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}</p>
        
        {payment.payment_method === 'paypal' && payment.paypal_payment && (
          <div>
            <h3 className="mt-4 font-semibold">PayPal Details</h3>
            <p><strong>PayPal Payment ID:</strong> {payment.paypal_payment.paypal_payment_id}</p>
            <p><strong>Payer ID:</strong> {payment.paypal_payment.payer_id || 'N/A'}</p>
          </div>
        )}
        
        {payment.payment_method === 'mpesa' && payment.mpesa_payment && (
          <div>
            <h3 className="mt-4 font-semibold">M-Pesa Details</h3>
            <p><strong>Phone Number:</strong> {payment.mpesa_payment.phone_number}</p>
            <p><strong>Checkout Request ID:</strong> {payment.mpesa_payment.checkout_request_id}</p>
            <p><strong>Merchant Request ID:</strong> {payment.mpesa_payment.merchant_request_id}</p>
          </div>
        )}
        
        {payment.payment_method === 'flutterwave' && payment.flutterwave_payment && (
          <div>
            <h3 className="mt-4 font-semibold">Flutterwave Details</h3>
            <p><strong>Flutterwave Reference:</strong> {payment.flutterwave_payment.flw_ref || 'N/A'}</p>
            <p><strong>Transaction Reference:</strong> {payment.flutterwave_payment.tx_ref}</p>
          </div>
        )}
        
        <div className="mt-4">
          <Select
            value={payment.status}
            onValueChange={(value) => handleStatusUpdate(payment.id, value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Update Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="pending">Pending</SelectItem>
              <SelectItem value="completed">Completed</SelectItem>
              <SelectItem value="failed">Failed</SelectItem>
              <SelectItem value="refunded">Refunded</SelectItem>
              <SelectItem value="timeout">Timeout</SelectItem>
              <SelectItem value="cancelled">Cancelled</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    );
  };

  return (
    <div className="mb-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
      <div className="p-4 bg-primary text-white">
        <h2 className="text-2xl font-bold">Payment Management</h2>
      </div>
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <Search className="w-5 h-5 mr-2 text-gray-500" />
            <Input
              placeholder="Search payments..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-64"
            />
          </div>
          <Button onClick={handleRefresh} className="bg-accent text-white" disabled={isRefreshing}>
            {isRefreshing ? 'Refreshing...' : 'Refresh'}
            <RefreshCw className="ml-2 h-4 w-4" />
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : error ? (
          <p className="text-center py-4 text-red-500">{error}</p>
        ) : payments.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('id')}>
                    ID <ArrowUpDown className="ml-2 h-4 w-4" />
                  </TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('user__username')}>
                    User <ArrowUpDown className="ml-2 h-4 w-4" />
                  </TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('amount')}>
                    Amount <ArrowUpDown className="ml-2 h-4 w-4" />
                  </TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('payment_method')}>
                    Method <ArrowUpDown className="ml-2 h-4 w-4" />
                  </TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('status')}>
                    Status <ArrowUpDown className="ml-2 h-4 w-4" />
                  </TableHead>
                  <TableHead className="cursor-pointer" onClick={() => handleSort('created_at')}>
                    Date <ArrowUpDown className="ml-2 h-4 w-4" />
                  </TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {payments.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell>{payment.id}</TableCell>
                    <TableCell>{payment.user?.username || 'N/A'}</TableCell>
                    <TableCell>KES {formatAmount(payment.amount)}</TableCell>
                    <TableCell>{payment.payment_method}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
                        payment.status === 'completed' ? 'bg-green-200 text-green-800' :
                        payment.status === 'pending' ? 'bg-yellow-200 text-yellow-800' :
                        payment.status === 'failed' ? 'bg-red-200 text-red-800' :
                        payment.status === 'refunded' ? 'bg-blue-200 text-blue-800' :
                        payment.status === 'timeout' ? 'bg-orange-200 text-orange-800' :
                        'bg-gray-200 text-gray-800'
                      }`}>
                        {payment.status}
                      </span>
                    </TableCell>
                    <TableCell>{payment.created_at ? format(new Date(payment.created_at), 'yyyy-MM-dd HH:mm') : 'N/A'}</TableCell>
                    <TableCell>
                      <Button variant="outline" size="sm" onClick={() => {
                        setSelectedPayment(payment);
                        setIsPaymentDialogOpen(true);
                      }}>
                        <Eye className="mr-2 h-4 w-4" />
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="text-center py-4">No payments available.</p>
        )}
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={() => fetchPayments(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
          <span>Page {currentPage} of {totalPages}</span>
          <Button onClick={() => fetchPayments(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
        </div>
      </div>
      <Dialog open={isPaymentDialogOpen} onOpenChange={setIsPaymentDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Payment Details</DialogTitle>
          </DialogHeader>
          {selectedPayment && renderPaymentDetails(selectedPayment)}
          <DialogFooter>
            <Button onClick={() => setIsPaymentDialogOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PaymentManagement;