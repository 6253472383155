import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FaUser, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { motion } from 'framer-motion';

const LoginPage = () => {
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
    showPassword: false,
    rememberMe: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showResendActivation, setShowResendActivation] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { user, login, resendActivation, isAdmin } = useAuth();
  const from = location.state?.from?.pathname || "/profile";

  useEffect(() => {
    if (user) {
      navigate(isAdmin() ? '/admin/dashboard' : from, { replace: true });
    }
  }, [user, isAdmin, navigate, from]);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.identifier.trim()) newErrors.identifier = "Email or username is required";
    if (!formData.password) newErrors.password = "Password is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData.identifier, formData.password]);

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    setErrors(prev => ({ ...prev, [name]: null, general: null }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true);
    try {
      await login(formData.identifier, formData.password, formData.rememberMe);
    } catch (error) {
      console.error('Login error:', error);
      let errorMessage = error.response?.data?.error || error.message || 'Failed to log in. Please try again.';
      if (error.response?.status === 400 && error.response?.data?.error === "Account is not activated.") {
        setShowResendActivation(true);
        errorMessage = "Your account is not activated. Please check your email for the activation link or request a new one.";
      }
      setErrors({ general: errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendActivation = async () => {
    setIsLoading(true);
    try {
      await resendActivation(formData.identifier);
      setShowResendActivation(false);
      setErrors({ general: "Activation email sent. Please check your inbox." });
    } catch (error) {
      console.error('Resend activation error:', error);
      setErrors({ general: "Failed to resend activation email. Please try again." });
    } finally {
      setIsLoading(false);
    }
  };

  const inputClassName = useMemo(() => (field) => `
    appearance-none rounded-none relative block w-full px-3 py-2 pl-10 border
    ${errors[field] ? 'border-red-500' : 'border-gray-300'}
    placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500
    focus:border-indigo-500 focus:z-10 sm:text-sm
  `, [errors]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="identifier" className="sr-only">Email address or Username</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaUser className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="identifier"
                  name="identifier"
                  type="text"
                  autoComplete="username"
                  required
                  className={inputClassName('identifier')}
                  placeholder="Email address or Username"
                  value={formData.identifier}
                  onChange={handleChange}
                />
              </div>
              {errors.identifier && <p className="mt-2 text-sm text-red-600">{errors.identifier}</p>}
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaLock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="password"
                  name="password"
                  type={formData.showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  className={inputClassName('password')}
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ ...prev, showPassword: !prev.showPassword }))}
                    className="text-gray-400 focus:outline-none"
                  >
                    {formData.showPassword ? <FaEyeSlash className="h-5 w-5" /> : <FaEye className="h-5 w-5" />}
                  </button>
                </div>
              </div>
              {errors.password && <p className="mt-2 text-sm text-red-600">{errors.password}</p>}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="rememberMe"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                checked={formData.rememberMe}
                onChange={handleChange}
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>
            <div className="text-sm">
              <Link to="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                Forgot your password?
              </Link>
            </div>
          </div>

          {errors.general && (
            <p className="mt-2 text-sm text-red-600 text-center">{errors.general}</p>
          )}

          {showResendActivation && (
            <div className="text-center">
              <p className="text-sm text-gray-600">Didn't receive the activation email?</p>
              <button
                type="button"
                onClick={handleResendActivation}
                className="mt-2 text-indigo-600 hover:text-indigo-500 font-medium"
              >
                Resend activation email
              </button>
            </div>
          )}

          <div>
            <motion.button
              type="submit"
              disabled={isLoading}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isLoading ? 'Signing in...' : 'Sign in'}
            </motion.button>
          </div>
          <p className="mt-2 text-center text-sm text-gray-600">
            Don't have an account?{' '}
            <Link to="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
              Sign up
            </Link>
          </p>
        </form>
      </div>
    </motion.div>
  );
};

export default LoginPage;