import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TrashIcon, PlusIcon, MinusIcon, ShoppingCartIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useCart } from '../contexts/CartContext';
import { formatCurrency } from '../utils/formatters';
import Spinner from '../components/Spinner';
import ErrorMessage from '../components/ErrorMessage';
import EmptyState from '../components/EmptyState';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';

const CartPage = () => {
  const { 
    cartItems, 
    totalItems, 
    totalPrice, 
    loading, 
    error, 
    removeItemFromCart, 
    updateCartItem, 
    clearCart, 
    fetchCart 
  } = useCart();
  const [selectedItems, setSelectedItems] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchCart().then(data => {
      console.log('Cart data:', data);  // Log the cart data
    });
  }, [fetchCart]);

  useEffect(() => {
    const initialSelected = (cartItems || []).reduce((acc, item) => {
      if (item && item.asset && item.asset.id) {
        acc[item.asset.id] = true;
      }
      return acc;
    }, {});
    setSelectedItems(initialSelected);
    console.log('Cart Items:', cartItems);  // Log the cart items
  }, [cartItems]);

  const toggleItemSelection = useCallback((assetId) => {
    setSelectedItems(prev => ({
      ...prev,
      [assetId]: !prev[assetId]
    }));
  }, []);

  const getItemRate = useCallback((item) => {
    console.log('Getting rate for item:', item);  // Log the item
    if (item.hourly_rate != null) {
      return item.hourly_rate;
    } else if (item.price != null) {
      return item.price;
    } else if (item.asset && item.asset.hourly_rate != null) {
      return item.asset.hourly_rate;
    } else {
      console.warn('No rate found for item:', item);
      return 0;
    }
  }, []);

  const calculateItemTotal = useCallback((item) => {
    if (!item) return 0;
    const rate = getItemRate(item);
    return rate * (item.quantity || 1) * (item.hours || 1);
  }, [getItemRate]);

  const calculateSelectedTotal = useCallback(() => {
    return (cartItems || []).reduce((total, item) => {
      if (item && item.asset && item.asset.id && selectedItems[item.asset.id]) {
        return total + calculateItemTotal(item);
      }
      return total;
    }, 0);
  }, [cartItems, selectedItems, calculateItemTotal]);

  const handleRemoveFromCart = useCallback(async (assetId) => {
    try {
      await removeItemFromCart(assetId);
      toast.success('Item removed from cart');
    } catch (err) {
      toast.error('Failed to remove item from cart');
      console.error('Error removing item:', err);
    }
  }, [removeItemFromCart]);

  const handleUpdateCartItem = useCallback(async (assetId, updates) => {
    try {
      await updateCartItem(assetId, updates);
      toast.success('Cart updated');
    } catch (err) {
      toast.error('Failed to update cart');
      console.error('Error updating cart:', err);
    }
  }, [updateCartItem]);

  const handleClearCart = useCallback(async () => {
    if (window.confirm('Are you sure you want to clear your entire cart?')) {
      try {
        await clearCart();
        toast.success('Cart cleared');
        setSelectedItems({});
      } catch (err) {
        toast.error('Failed to clear cart');
        console.error('Error clearing cart:', err);
      }
    }
  }, [clearCart]);

  const handleCheckout = useCallback(() => {
    const selectedCartItems = (cartItems || []).filter(item => item && item.asset && item.asset.id && selectedItems[item.asset.id])
      .map(item => ({
        ...item,
        total: calculateItemTotal(item)
      }));
    if (selectedCartItems.length === 0) {
      toast.error('Please select at least one item to checkout');
      return;
    }
    navigate('/checkout', { state: { selectedItems: selectedCartItems } });
  }, [cartItems, selectedItems, calculateItemTotal, navigate]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!cartItems || cartItems.length === 0) {
    return (
      <EmptyState
        icon={ShoppingCartIcon}
        title="Your cart is empty"
        description="Looks like you haven't added any items to your cart yet."
        actionText="Browse Assets"
        actionLink="/assets"
      />
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-3xl font-extrabold text-gray-900 mb-8"
      >
        Your Cart
      </motion.h1>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Select
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Asset
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Hourly Rate
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Hours
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Subtotal
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <AnimatePresence>
                {(cartItems || []).map((item) => {
                  if (!item || !item.asset) return null;
                  const itemRate = getItemRate(item);
                  return (
                    <motion.tr
                      key={item.asset.id}
                      className="hover:bg-gray-50 transition-colors duration-200"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={!!selectedItems[item.asset.id]}
                          onChange={() => toggleItemSelection(item.asset.id)}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <Link to={`/assets/${item.asset.slug || ''}`} className="text-sm font-medium text-gray-900 hover:text-indigo-600">
                              {item.asset.name || 'Unknown Asset'}
                            </Link>
                            <p className="text-xs text-gray-500">{item.asset.category?.name || 'Uncategorized'}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{formatCurrency(itemRate)}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <button
                            onClick={() => handleUpdateCartItem(item.asset.id, { quantity: Math.max(1, item.quantity - 1) })}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200"
                          >
                            <MinusIcon className="h-5 w-5" />
                          </button>
                          <span className="mx-2 text-sm text-gray-900">{item.quantity}</span>
                          <button
                            onClick={() => handleUpdateCartItem(item.asset.id, { quantity: item.quantity + 1 })}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200"
                          >
                            <PlusIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <button
                            onClick={() => handleUpdateCartItem(item.asset.id, { hours: Math.max(1, item.hours - 1) })}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200"
                          >
                            <MinusIcon className="h-5 w-5" />
                          </button>
                          <span className="mx-2 text-sm text-gray-900">{item.hours}</span>
                          <button
                            onClick={() => handleUpdateCartItem(item.asset.id, { hours: item.hours + 1 })}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200"
                          >
                            <PlusIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {formatCurrency(calculateItemTotal(item))}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => handleRemoveFromCart(item.asset.id)}
                          className="text-red-600 hover:text-red-900 focus:outline-none transition-colors duration-200"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </td>
                    </motion.tr>
                  );
                })}
              </AnimatePresence>
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-8 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleClearCart}
          className="w-full sm:w-auto bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md transition-colors duration-200"
        >
          Clear Cart
        </motion.button>
        <div className="text-2xl font-bold text-gray-900">
          Selected Total: {formatCurrency(calculateSelectedTotal())}
        </div>
      </div>
      <div className="mt-8">
        <motion.div
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <button
            onClick={handleCheckout}
            className="block w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-4 rounded-md text-center transition-colors duration-200"
          >
            Proceed to Checkout
          </button>
        </motion.div>
      </div>
      {cartItems.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mt-8 bg-yellow-50 border-l-4 border-yellow-400 p-4"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Remember, adding items to your cart does not reserve them. Complete your checkout to secure your rental.
              </p>
            </div>
          </div>
        </motion.div>
      )}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="mt-8"
      >
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Rental Terms</h2>
        <ul className="list-disc pl-5 space-y-2 text-sm text-gray-600">
          <li>Rental periods are calculated on an hourly basis.</li>
          <li>A security deposit may be required for some items.</li>
          <li>Late returns may incur additional charges.</li>
          <li>Please review each item's specific rental terms before checkout.</li>
        </ul>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="mt-8 bg-blue-50 p-4 rounded-md"
      >
        <h2 className="text-lg font-semibold text-blue-800 mb-2">Need Help?</h2>
        <p className="text-sm text-blue-600">
          If you have any questions about your cart or the rental process, our support team is here to help.
          <Link to="/contact" className="ml-1 font-medium underline">Contact us</Link>
        </p>
      </motion.div>
    </div>
  );
};

export default CartPage;