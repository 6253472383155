import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

// Updated color scheme
const colors = {
  primary: 'bg-orange-600 text-white',
  secondary: 'bg-orange-500 text-white',
  accent: 'text-orange-600',
  background: 'bg-gray-100', // Light grey background
  text: 'text-gray-800', // Dark text for contrast
  muted: 'text-gray-600', // Slightly lighter text for secondary elements
  card: 'bg-white', // White background for cards
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`text-lg font-medium ${colors.text}`}>{question}</span>
        {isOpen ? (
          <FaChevronUp className={`h-5 w-5 ${colors.accent}`} />
        ) : (
          <FaChevronDown className={`h-5 w-5 ${colors.accent}`} />
        )}
      </button>
      {isOpen && (
        <div className="mt-2 pr-12">
          <p className={`text-base ${colors.muted}`}>{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQPage = () => {
  const faqs = [
    {
      question: "How does AliHire work?",
      answer: "AliHire connects people who need to hire items, with those who have items available for rent. You can browse available items, make reservations, and manage your rentals through our platform."
    },
    {
      question: "How do I list my item for rent?",
      answer: "To list your item, sign up for an account, go to your dashboard, and click on 'List an Item'. Follow the prompts to add details about your item, set pricing, and upload photos."
    },
    {
      question: "What types of items can I rent on AliHire?",
      answer: "AliHire supports a wide range of items, including but not limited to vehicles, equipment, tools, electronics, and more. Check our categories page for a full list of supported item types."
    },
    {
      question: "How are payments handled?",
      answer: "Payments are processed securely through our platform. We accept major credit cards and other popular payment methods like M-Pesa. Renters pay when they make a reservation, and item owners receive their payments after the rental period, minus our service fee."
    },
    {
      question: "What if the item is damaged during the rental?",
      answer: "We have an optional comprehensive insurance policy to cover potential damages. Both renters and owners should document the item's condition before and after the rental. In case of damage, if the item is under the insurance policy, contact our support team immediately to initiate the claims process."
    },
  ];

  return (
    <div className={`${colors.background} min-h-screen py-12 px-4 sm:px-6 lg:px-8`}>
      <div className="max-w-3xl mx-auto">
        <h1 className={`text-4xl font-bold text-center ${colors.text} mb-12`}>Frequently Asked Questions</h1>
        <div className={`${colors.card} shadow overflow-hidden sm:rounded-lg`}>
          <div className="px-4 py-5 sm:p-6">
            {faqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>
        <div className="mt-8 text-center">
          <p className={colors.muted}>
            Can't find the answer you're looking for? Please{' '}
            <a href="/contact" className={`${colors.accent} hover:text-orange-700 font-medium`}>
              contact our support team
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
