import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import apiService from '../../services/api';
import * as Icons from 'lucide-react';
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "../../components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Badge } from "../../components/ui/badge";
import { Input } from "../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import Spinner from '../../components/Spinner';
import { formatCurrency, formatDate } from '../../utils/formatters';
import { toast } from 'react-toastify';

const getStatusColor = (status) => {
  const statusColors = {
    pending: 'bg-yellow-100 text-yellow-800',
    processing: 'bg-blue-100 text-blue-800',
    shipped: 'bg-purple-100 text-purple-800',
    delivered: 'bg-green-100 text-green-800',
    cancelled: 'bg-red-100 text-red-800',
    paid: 'bg-green-100 text-green-800',
  };
  return statusColors[status.toLowerCase()] || 'bg-gray-100 text-gray-800';
};

const OrdersContent = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.getUserOrders({
        page,
        search: searchTerm,
        status: statusFilter === 'all' ? '' : statusFilter
      });
      if (response && response.data && Array.isArray(response.data.results)) {
        setOrders(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
      } else {
        throw new Error('Unexpected API response structure');
      }
    } catch (err) {
      console.error('Error fetching orders:', err);
      setError('Failed to load orders. Please try again later.');
      setOrders([]);
    } finally {
      setLoading(false);
    }
  }, [page, searchTerm, statusFilter]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  // Reset page to 1 when search term or status filter changes
  useEffect(() => {
    setPage(1);
  }, [searchTerm, statusFilter]);

  const handleSearch = (e) => {
    e.preventDefault();
    fetchOrders();
  };

  const handleViewDetails = useCallback(async (orderId) => {
    try {
      const response = await apiService.getCustomerOrderDetails({ id: orderId });
      navigate(`/order-confirmation/${orderId}`, { state: { orderData: response.data } });
    } catch (err) {
      console.error('Error fetching order details:', err);
      toast.error('Failed to load order details. Please try again.');
    }
  }, [navigate]);

  const handleCancelOrder = useCallback(async (orderId) => {
    try {
      await apiService.cancelOrder(orderId);
      toast.success('Order cancelled successfully');
      fetchOrders();
    } catch (err) {
      console.error('Error cancelling order:', err);
      toast.error('Failed to cancel order. Please try again.');
    }
  }, [fetchOrders]);

  const handleExportOrders = async () => {
    try {
      const response = await apiService.exportOrders();
      // Handle the exported data (e.g., download as CSV)
      console.log('Exported orders:', response.data);
      toast.success('Orders exported successfully');
    } catch (err) {
      console.error('Error exporting orders:', err);
      toast.error('Failed to export orders. Please try again.');
    }
  };

  const renderOrderTable = () => (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Order Number</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Total Amount</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {orders.map((order) => (
          <TableRow key={order.id}>
            <TableCell>{order.order_number}</TableCell>
            <TableCell>{formatDate(order.order_date)}</TableCell>
            <TableCell>
              {order.total_amount != null ? formatCurrency(parseFloat(order.total_amount)) : 'N/A'}
            </TableCell>
            <TableCell>
              <Badge className={getStatusColor(order.status)}>
                {order.status_display || order.status}
              </Badge>
            </TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <Button variant="outline" size="sm" onClick={() => handleViewDetails(order.id)}>
                  <Icons.Eye className="mr-2 h-4 w-4" /> View
                </Button>
                {order.status.toLowerCase() === 'pending' && (
                  <Button variant="destructive" size="sm" onClick={() => handleCancelOrder(order.id)}>
                    <Icons.X className="mr-2 h-4 w-4" /> Cancel
                  </Button>
                )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const renderPagination = () => (
    <div className="flex items-center justify-between mt-4">
      <Button
        onClick={() => setPage(prev => Math.max(prev - 1, 1))}
        disabled={page === 1 || loading}
      >
        <Icons.ChevronLeft className="mr-2 h-4 w-4" /> Previous
      </Button>
      <span>Page {page} of {totalPages}</span>
      <Button
        onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}
        disabled={page === totalPages || loading}
      >
        Next <Icons.ChevronRight className="ml-2 h-4 w-4" />
      </Button>
    </div>
  );

  return (
    <Card className="w-full max-w-6xl mx-auto mb-8">
      <CardHeader>
        <CardTitle className="text-2xl">Order History</CardTitle>
        <CardDescription>View and manage your past orders</CardDescription>
      </CardHeader>
      <CardContent>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-2 sm:space-y-0 sm:space-x-2">
          <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
            <Input
              placeholder="Search orders..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-sm"
            />
            <Select value={statusFilter} onValueChange={setStatusFilter}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Filter by status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Statuses</SelectItem>
                <SelectItem value="pending">Pending</SelectItem>
                <SelectItem value="processing">Processing</SelectItem>
                <SelectItem value="shipped">Shipped</SelectItem>
                <SelectItem value="delivered">Delivered</SelectItem>
                <SelectItem value="cancelled">Cancelled</SelectItem>
                <SelectItem value="paid">Paid</SelectItem>
              </SelectContent>
            </Select>
            <Button onClick={fetchOrders}>Apply Filters</Button>
          </div>
          <Button onClick={handleExportOrders} variant="outline">
            <Icons.Download className="mr-2 h-4 w-4" /> Export Orders
          </Button>
        </div>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        {loading && orders.length === 0 ? (
          <div className="flex justify-center items-center h-64">
            <Spinner size="large" />
          </div>
        ) : orders.length > 0 ? (
          <div className="space-y-6">
            {renderOrderTable()}
            {renderPagination()}
          </div>
        ) : (
          <div className="text-center py-8">
            <Icons.ShoppingBag className="w-16 h-16 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-600">No orders found. Start shopping to see your order history!</p>
          </div>
        )}
      </CardContent>
      <CardFooter>
        <OrderAnalytics />
      </CardFooter>
    </Card>
  );
};

const OrderAnalytics = () => {
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await apiService.getOrderAnalytics();
        console.log('Analytics response:', response.data); // Debug log
        setAnalytics(response.data);
      } catch (err) {
        console.error('Error fetching order analytics:', err);
        setError('Failed to load order analytics.');
      } finally {
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, []);

  if (loading) return <Spinner />;
  if (error) return <Alert variant="destructive"><AlertTitle>Error</AlertTitle><AlertDescription>{error}</AlertDescription></Alert>;
  if (!analytics) return null;

  // Calculate total spent if it's not provided by the API
  const totalSpent = analytics.total_revenue ?? analytics.total_spent ?? 0;

  return (
    <div className="w-full mt-8">
      <h3 className="text-xl font-semibold mb-4">Order Summary</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Total Orders</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-3xl font-bold">{analytics.total_orders || 0}</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Total Spent</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-3xl font-bold">
              {totalSpent !== null && totalSpent !== undefined
                ? formatCurrency(totalSpent)
                : 'N/A'}
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Average Order Value</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-3xl font-bold">
              {analytics.average_order_value != null
                ? formatCurrency(parseFloat(analytics.average_order_value))
                : 'N/A'}
            </p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default OrdersContent;