import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import api from '../services/api';
import { motion } from 'framer-motion';

const AccountActivationPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isActivated, setIsActivated] = useState(false);
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const activateUserAccount = async () => {
      try {
        const response = await api.activateAccount({ uidb64, token });
        setIsActivated(true);
        toast.success('Account activated successfully. You can now login.');
       
        // Attempt to log in the user automatically
        try {
          const userData = response.data.user;
          await login(userData.email, userData.password);
          toast.success('You have been automatically logged in.');
          setTimeout(() => navigate('/profile'), 3000); // Redirect to user profile page after 3 seconds
        } catch (loginError) {
          console.error('Auto-login failed:', loginError);
          setTimeout(() => navigate('/login'), 5000); // Redirect to login page after 5 seconds if auto-login fails
        }
      } catch (error) {
        console.error('Activation error:', error);
        toast.error(error.response?.data?.message || 'Failed to activate account. Please try again or contact support.');
      } finally {
        setIsLoading(false);
      }
    };
    activateUserAccount();
  }, [uidb64, token, login, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-4">Activating your account...</h2>
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-600 mx-auto"></div>
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="min-h-screen flex items-center justify-center bg-gray-50"
    >
      <div className="max-w-md w-full space-y-8 p-8 bg-white shadow-lg rounded-lg">
        <div className="text-center">
          {isActivated ? (
            <FaCheckCircle className="mx-auto h-12 w-12 text-green-500" />
          ) : (
            <FaTimesCircle className="mx-auto h-12 w-12 text-red-500" />
          )}
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            {isActivated ? 'Account Activated!' : 'Activation Failed'}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {isActivated
              ? 'Your account has been successfully activated. You will be redirected shortly.'
              : 'We encountered an issue while activating your account. Please try again or contact support.'}
          </p>
          {isActivated && (
            <div className="mt-4">
              <p className="text-sm text-gray-500">Redirecting you in a few seconds...</p>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default AccountActivationPage;