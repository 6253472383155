import React from 'react';

const Spinner = () => (
  <div className="flex justify-center items-center h-screen" role="status">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
    <span className="sr-only">Loading...</span>
  </div>
);


export default Spinner;