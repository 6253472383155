import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  CheckCircle, CreditCard, Truck, Download, AlertTriangle, Package, 
  RefreshCw, ChevronDown, ChevronUp, ArrowLeft
} from 'lucide-react';
import apiService from '../services/api';
import Spinner from '../components/Spinner';
import ErrorMessage from '../components/ErrorMessage';
import { formatCurrency, formatDate } from '../utils/formatters';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../images/logos/logo_1.png';

const OrderConfirmationPage = () => {
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfGenerating, setPdfGenerating] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    orderDetails: true,
    paymentInfo: true,
    shippingInfo: true,
    orderSummary: true,
  });
  const navigate = useNavigate();
  const { orderId } = useParams();
  const location = useLocation();

  const fetchOrderDetails = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      let orderIdToUse = orderId || 
                         location.state?.paymentDetails?.order_id || 
                         location.state?.orderId;

      if (!orderIdToUse) {
        throw new Error('No valid Order ID found');
      }

      const response = await apiService.getCustomerOrderDetails({ id: orderIdToUse });

      if (!response || !response.data) {
        throw new Error('Invalid order details received');
      }

      const processedOrderData = {
        ...response.data,
        items: response.data.items.map(item => ({
          ...item,
          hours: item.hours || 1,
          unit_price: parseFloat(item.unit_price) || 0,
          quantity: parseInt(item.quantity) || 1
        })),
        total_amount: parseFloat(response.data.total_amount) || 0,
        subtotal: response.data.items.reduce((acc, item) => 
          acc + (item.unit_price * item.quantity * (item.hours || 1)), 0
        ),
        tax: parseFloat(response.data.tax) || 0,
        shipping: parseFloat(response.data.shipping) || 0,
        discount: parseFloat(response.data.discount) || 0,
      };

      setOrderData(processedOrderData);
    } catch (err) {
      console.error('Error in fetchOrderDetails:', err);
      setError(`Failed to fetch order details: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [orderId, location.state]);

  useEffect(() => {
    fetchOrderDetails();
  }, [fetchOrderDetails]);

  const calculateItemTotal = useCallback((item) => {
    return (item.unit_price * item.quantity * item.hours) || 0;
  }, []);

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleDownloadSummary = useCallback(() => {
    if (!orderData) {
      console.error('No order data available for PDF generation');
      return;
    }
  
    setPdfGenerating(true);
  
    try {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
  
      // Add logo
      doc.addImage(logo, 'PNG', 10, 10, 50, 20);
  
      // Add title
      doc.setFontSize(24);
      doc.setTextColor(41, 128, 185);
      doc.text('Order Confirmation', pageWidth / 2, 40, { align: 'center' });
  
      // Add order details
      doc.setFontSize(12);
      doc.setTextColor(52, 73, 94);
      doc.text(`Order: ${orderData.order_number}`, 10, 60);
      doc.text(`Date: ${formatDate(orderData.order_date)}`, 10, 70);
      doc.text(`Status: ${orderData.status}`, 10, 80);
  
      // Add order items
      doc.autoTable({
        startY: 90,
        head: [['Item', 'Quantity', 'Hours', 'Unit Price', 'Total']],
        body: orderData.items.map(item => [
          item.asset?.name || 'N/A',
          item.quantity,
          item.hours,
          formatCurrency(item.unit_price),
          formatCurrency(calculateItemTotal(item))
        ]),
        styles: { fontSize: 10, cellPadding: 5 },
        headStyles: { fillColor: [41, 128, 185], textColor: 255 },
        alternateRowStyles: { fillColor: [245, 247, 250] },
      });
  
      const finalY = doc.lastAutoTable.finalY || 90;
  
      // Add order summary table
      doc.autoTable({
        startY: finalY + 10,
        head: [['Order Summary', 'Subtotal', 'Tax', 'Shipping', 'Discount', 'Total']],
        body: [[
          '',
          formatCurrency(orderData.subtotal),
          formatCurrency(orderData.tax),
          formatCurrency(orderData.shipping),
          formatCurrency(orderData.discount),
          formatCurrency(orderData.total_amount)
        ]],
        styles: { fontSize: 10, cellPadding: 5 },
        headStyles: { fillColor: [52, 152, 219], textColor: 255 },
        bodyStyles: { fillColor: [236, 240, 241] },
        columnStyles: { 0: { cellWidth: 30 }, 5: { fontStyle: 'bold' } },
      });
  
      // Add payment info table
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        head: [['Payment Information', 'Method', 'Status']],
        body: [[
          '',
          orderData.payment_gateway || 'N/A',
          orderData.status || 'N/A'
        ]],
        styles: { fontSize: 10, cellPadding: 5 },
        headStyles: { fillColor: [46, 204, 113], textColor: 255 },
        bodyStyles: { fillColor: [236, 240, 241] },
        columnStyles: { 0: { cellWidth: 50 } },
      });
  
      // Add shipping info table
      const shippingInfoBody = orderData.shipping_info
        ? [[
            '',
            orderData.shipping_info.address || 'N/A',
            orderData.shipping_info.city || 'N/A',
            orderData.shipping_info.postal_code || 'N/A',
            orderData.shipping_info.country || 'N/A'
          ]]
        : [['', 'No shipping information available']];
  
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        head: [['Shipping Information', 'Address', 'City', 'Postal Code', 'Country']],
        body: shippingInfoBody,
        styles: { fontSize: 10, cellPadding: 5 },
        headStyles: { fillColor: [230, 126, 34], textColor: 255 },
        bodyStyles: { fillColor: [236, 240, 241] },
        columnStyles: { 0: { cellWidth: 50 } },
      });
  
      // Add footer
      doc.setFillColor(41, 128, 185);
      doc.rect(0, pageHeight - 25, pageWidth, 25, 'F');
      doc.setFontSize(8);
      doc.setTextColor(255, 255, 255);
      doc.text('AliHire - Rent Anything - Connecting those who need to hire assets with those who have assets to rent.', pageWidth / 2, pageHeight - 15, { align: 'center' });
      doc.text('© 2024 AliHire - Rent Anything. All Rights Reserved.', pageWidth / 2, pageHeight - 7, { align: 'center' });
  
      // Save the PDF
      doc.save(`order_summary_${orderData.order_number}.pdf`);
    } catch (err) {
      console.error('Error generating PDF:', err);
      alert('Failed to generate PDF. Please try again.');
    } finally {
      setPdfGenerating(false);
    }
  }, [orderData, calculateItemTotal]);

  if (loading) return <Spinner />;
  if (error) return <ErrorMessage message={error} onRetry={fetchOrderDetails} />;
  if (!orderData) return <ErrorMessage message="No order data available" onRetry={fetchOrderDetails} />;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="max-w-4xl mx-auto px-4 py-8 sm:px-6 lg:px-8"
    >
      <img src={logo} alt="AliHire - Rent Anything Logo" className="h-16 mb-8 mx-auto" />
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl flex items-center">
              <CheckCircle className="h-10 w-10 mr-4 text-green-500" />
              Order Confirmation
            </h1>
            <span className={`px-3 py-1 rounded-full text-sm font-semibold ${
              orderData.status === 'paid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
            }`}>
              {orderData.status || 'Pending'}
            </span>
          </div>
          
          <Section 
            title="Order Details" 
            icon={<Package className="h-6 w-6 mr-2 text-indigo-600" />}
            isExpanded={expandedSections.orderDetails}
            toggleExpanded={() => toggleSection('orderDetails')}
          >
            <div className="space-y-2">
              <InfoItem label="Order Number" value={orderData.order_number} />
              <InfoItem label="Order Date" value={formatDate(orderData.order_date)} />
              <InfoItem label="Status" value={orderData.status} />
            </div>
          </Section>

          <Section 
            title="Payment Information" 
            icon={<CreditCard className="h-6 w-6 mr-2 text-blue-500" />}
            isExpanded={expandedSections.paymentInfo}
            toggleExpanded={() => toggleSection('paymentInfo')}
          >
            <div className="space-y-2">
              <InfoItem label="Method" value={orderData.payment_gateway} />
              <InfoItem label="Status" value={orderData.status} />
              <InfoItem label="Amount" value={formatCurrency(orderData.total_amount)} />
            </div>
          </Section>

          <Section 
            title="Shipping Information" 
            icon={<Truck className="h-6 w-6 mr-2 text-green-500" />}
            isExpanded={expandedSections.shippingInfo}
            toggleExpanded={() => toggleSection('shippingInfo')}
          >
            {orderData.shipping_info ? (
              <div className="space-y-2">
                <p>{orderData.shipping_info.address}</p>
                <p>{orderData.shipping_info.city}, {orderData.shipping_info.postal_code}</p>
                <p>{orderData.shipping_info.country}</p>
              </div>
            ) : (
              <p>No shipping information available</p>
            )}
          </Section>

          <Section 
            title="Order Summary" 
            icon={<Package className="h-6 w-6 mr-2 text-purple-500" />}
            isExpanded={expandedSections.orderSummary}
            toggleExpanded={() => toggleSection('orderSummary')}
          >
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hours</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Unit Price</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {orderData.items.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.asset?.name || 'N/A'}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.quantity}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.hours}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">{formatCurrency(item.unit_price)}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">{formatCurrency(calculateItemTotal(item))}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-4 bg-gray-50 p-4 rounded-md">
              <OrderSummaryItem label="Subtotal" value={formatCurrency(orderData.subtotal)} />
              <OrderSummaryItem label="Tax" value={formatCurrency(orderData.tax)} />
              <OrderSummaryItem label="Shipping" value={formatCurrency(orderData.shipping)} />
              {orderData.discount > 0 && (
                <OrderSummaryItem label="Discount" value={`-${formatCurrency(orderData.discount)}`} className="text-green-600" />
              )}
              <OrderSummaryItem label="Total" value={formatCurrency(orderData.total_amount)} className="text-lg font-bold text-blue-600 mt-4" />
            </div>
          </Section>
        </div>
      </div>
      <div className="mt-8 flex flex-col sm:flex-row justify-between">
      <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleDownloadSummary}
          disabled={pdfGenerating}
          className="w-full sm:w-auto mb-4 sm:mb-0 px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {pdfGenerating ? (
            <>
              <RefreshCw className="h-5 w-5 mr-2 animate-spin" />
              Generating PDF...
            </>
          ) : (
            <>
              <Download className="h-5 w-5 mr-2" />
              Download Order Summary (PDF)
            </>
          )}
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/assets')}
          className="w-full sm:w-auto px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Continue Shopping
        </motion.button>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="mt-8 bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md"
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <AlertTriangle className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Important Note</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>Please ensure all rental equipment is returned on time to avoid additional charges. For any questions or concerns, please contact our support team.</p>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

// Helper Components
const Section = ({ title, icon, children, isExpanded, toggleExpanded }) => (
  <div className="mb-6 border-t border-gray-200 pt-4">
    <div className="flex justify-between items-center cursor-pointer" onClick={toggleExpanded}>
      <h2 className="text-xl font-semibold flex items-center">
        {icon}
        {title}
      </h2>
      {isExpanded ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
    </div>
    <AnimatePresence>
      {isExpanded && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
          className="mt-4"
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  </div>
);

const InfoItem = ({ label, value }) => (
  <p>
    <span className="font-medium">{label}:</span> {value || 'N/A'}
  </p>
);

const OrderSummaryItem = ({ label, value, className = "" }) => (
  <div className={`flex justify-between items-center ${className}`}>
    <span className="text-gray-600">{label}:</span>
    <span className="font-semibold">{value}</span>
  </div>
);

export default OrderConfirmationPage;