import api, { setAuthToken, fetchCSRFToken } from './api';
import { jwtDecode } from 'jwt-decode';

class AuthService {
  constructor() {
    this.api = api;
    this.tokenRefreshTimeout = null;
  }

  setTokens(accessToken, refreshToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    setAuthToken(accessToken);
  }

  getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  removeTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setAuthToken(null);
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  }

  removeUser() {
    localStorage.removeItem('user');
  }

  handleApiResponse(response) {
    if (!response || !response.data) {
      throw new Error('Invalid response from server');
    }
    return response.data;
  }

  isTokenExpired(token) {
    if (!token) return true;
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  }

  async refreshToken() {
    try {
      const refreshToken = this.getRefreshToken();
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }
      const response = await this.api.refreshToken({}, { refresh: refreshToken });
      const data = this.handleApiResponse(response);
      if (data.access) {
        this.setTokens(data.access, refreshToken);
        this.scheduleTokenRefresh(data.access);
        return data.access;
      } else {
        throw new Error('Invalid token refresh response');
      }
    } catch (error) {
      console.error('Token refresh error:', error);
      this.logout();
      throw error;
    }
  }

  scheduleTokenRefresh(token) {
    if (this.tokenRefreshTimeout) {
      clearTimeout(this.tokenRefreshTimeout);
    }
    const decodedToken = jwtDecode(token);
    const expiresIn = (decodedToken.exp * 1000) - Date.now() - 60000; // Refresh 1 minute before expiration
    this.tokenRefreshTimeout = setTimeout(() => this.refreshToken(), expiresIn);
  }

  async ensureValidToken() {
    const token = this.getAccessToken();
    if (this.isTokenExpired(token)) {
      return this.refreshToken();
    }
    return token;
  }

  async login(identifier, password) {
    try {
      await this.initializeCSRFToken();
      const response = await this.api.login({}, { identifier, password });
      const data = this.handleApiResponse(response);
      if (data.access && data.refresh && data.user) {
        this.setTokens(data.access, data.refresh);
        this.setUser(data.user);
        this.scheduleTokenRefresh(data.access);
        return data.user;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  logout() {
    if (this.tokenRefreshTimeout) {
      clearTimeout(this.tokenRefreshTimeout);
    }
    this.removeTokens();
    this.removeUser();
  }

  async register(userData) {
    try {
      const response = await this.api.register({}, userData);
      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  }

  async registerVendor(vendorData) {
    try {
      const response = await this.api.registerVendor({}, vendorData);
      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Vendor registration error:', error);
      throw error;
    }
  }

  async loginWithGoogle(accessToken) {
    try {
      const response = await this.api.loginWithGoogle({}, { access_token: accessToken });
      const data = this.handleApiResponse(response);
      if (data.access && data.refresh && data.user) {
        this.setTokens(data.access, data.refresh);
        this.setUser(data.user);
        this.scheduleTokenRefresh(data.access);
        return data.user;
      } else {
        throw new Error('Invalid Google login response');
      }
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  }

  async loginWithFacebook(accessToken) {
    try {
      const response = await this.api.loginWithFacebook({}, { access_token: accessToken });
      const data = this.handleApiResponse(response);
      if (data.access && data.refresh && data.user) {
        this.setTokens(data.access, data.refresh);
        this.setUser(data.user);
        this.scheduleTokenRefresh(data.access);
        return data.user;
      } else {
        throw new Error('Invalid Facebook login response');
      }
    } catch (error) {
      console.error('Facebook login error:', error);
      throw error;
    }
  }

  isAuthenticated() {
    const token = this.getAccessToken();
    return !!token && !this.isTokenExpired(token);
  }

  async getUserProfile() {
    try {
      await this.ensureValidToken();
      const response = await this.api.getUserProfile();
      const data = this.handleApiResponse(response);
      // Explicitly update the is_vendor status
      data.is_vendor = !!data.vendor;
      this.setUser(data);
      return data;
    } catch (error) {
      console.error('Get user profile error:', error);
      throw error;
    }
  }

  async updateVendorStatus(isVendor) {
    const user = this.getUser();
    if (user) {
      user.is_vendor = isVendor;
      this.setUser(user);
      // Optionally, you might want to call the API to ensure the backend is updated
      await this.updateUserProfile(user);
    }
  }

  async updateUserProfile(userData) {
    try {
      const response = await this.api.updateUserProfile({}, userData);
      const data = this.handleApiResponse(response);
      this.setUser(data);
      return data;
    } catch (error) {
      console.error('Update user profile error:', error);
      throw error;
    }
  }

  async resetPassword(email) {
    try {
      const response = await this.api.resetPassword({}, { email });
      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Reset password error:', error);
      throw error;
    }
  }

  async confirmResetPassword(uidb64, token, newPassword) {
    try {
      const response = await this.api.confirmResetPassword({}, { uidb64, token, new_password: newPassword });
      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Confirm reset password error:', error);
      throw error;
    }
  }

  async changePassword(oldPassword, newPassword) {
    try {
      const response = await this.api.changePassword({}, { old_password: oldPassword, new_password: newPassword });
      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Change password error:', error);
      throw error;
    }
  }

  async deleteAccount() {
    try {
      await this.api.deleteAccount();
      this.removeTokens();
      this.removeUser();
    } catch (error) {
      console.error('Delete account error:', error);
      throw error;
    }
  }

  async activateAccount(uidb64, token) {
    try {
      const response = await this.api.activateAccount({ uidb64, token });
      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Activate account error:', error);
      throw error;
    }
  }

  isAdmin() {
    const user = this.getUser();
    return user && user.is_staff;
  }

  isVendor() {
    const user = this.getUser();
    return user && user.is_vendor;
  }

  async resendActivation(email) {
    try {
      const response = await this.api.resendActivation({}, { email });
      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Resend activation error:', error);
      throw error;
    }
  }

  async initializeCSRFToken() {
    try {
      await fetchCSRFToken();
    } catch (error) {
      console.error('Failed to initialize CSRF token:', error);
      throw error;
    }
  }
}

const authService = new AuthService();
export default authService;