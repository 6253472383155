import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen scroll-smooth">
      <Navbar className="z-50" />
      <main className="flex-grow pt-16 sm:pt-15 pb-8"> 
        
          {children}
       
      </main>
      <Footer className="absolute bottom-0 w-full" />
    </div>
  );
};

export default Layout;