import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from "../../components/ui/use-toast";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
// Chart imports
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';

// Icon imports
import { DollarSign, Clock, CheckCircle, Percent, Package, Activity, TrendingUp, Plus, RefreshCw } from 'lucide-react';

import apiService from '../../services/api';
import * as Icons from 'lucide-react';
import Spinner from '../../components/Spinner';
import ErrorMessage from '../../components/ErrorMessage';

// Utility functions
const isValidNumber = (value) => !isNaN(parseFloat(value)) && isFinite(value);
const safeNumber = (value, fallback = 0) => isValidNumber(value) ? parseFloat(value) : fallback;
const safeString = (value, fallback = 'N/A') => typeof value === 'string' ? value : fallback;

const formatCurrency = (value) => {
  if (!isValidNumber(value)) return 'N/A';
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KES' }).format(value);
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleDateString();
};

const VendorDashboard = ({ colors }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const { toast } = useToast();
  const navigate = useNavigate();

  const fetchDashboardData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.getVendorDashboard();
      console.log('Dashboard data:', response);
      
      // Log specific parts of the response
      console.log('Asset earnings:', response.asset_earnings);
      console.log('Total earnings:', response.total_earnings);
      console.log('Pending earnings:', response.pending_earnings);
      console.log('Paid earnings:', response.paid_earnings);
  
      setDashboardData(response);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError(err.message || 'Failed to fetch dashboard data');
      toast({
        title: "Error",
        description: "Failed to fetch dashboard data. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);
  
  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const handleAddAsset = () => {
    navigate('/list-asset');
  };

  const handleRefresh = () => {
    fetchDashboardData();
  };

  const StatsCard = ({ title, value, icon, description }) => (
    <Card className={colors.muted}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium text-orange-500">{safeString(title)}</CardTitle>
        {icon}
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold text-orange-900">{safeString(value)}</div>
        {description && <p className="text-xs text-orange-600 mt-1">{safeString(description)}</p>}
      </CardContent>
    </Card>
  );

  const renderOverviewTab = () => {
    if (!dashboardData) return <ErrorMessage message="No dashboard data available" />;
  
    console.log('Dashboard Data:', dashboardData);
  
    const {
      total_earnings = 0,
      pending_earnings = 0,
      paid_earnings = 0,
      total_service_fees = 0,
      monthly_earnings = [],
      recent_rentals = [],
      total_assets,
      active_rentals_count
    } = dashboardData;
  
    console.log('Extracted values:',
      { total_assets, active_rentals_count }
    );
  
    // Helper function to safely display numeric values
    const displayValue = (value) => {
      if (typeof value === 'number') return value.toString();
      if (typeof value === 'string' && !isNaN(parseFloat(value))) return parseFloat(value).toString();
      return 'N/A';
    };
  
    return (
      <div className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <StatsCard
            title="Total Earnings"
            value={formatCurrency(safeNumber(total_earnings))}
            icon={<Icons.DollarSign className="h-4 w-4 text-orange-500" />}
          />
          <StatsCard
            title="Pending Earnings"
            value={formatCurrency(safeNumber(pending_earnings))}
            icon={<Icons.Clock className="h-4 w-4 text-orange-500" />}
          />
          <StatsCard
            title="Paid Earnings"
            value={formatCurrency(safeNumber(paid_earnings))}
            icon={<Icons.CheckCircle className="h-4 w-4 text-orange-500" />}
          />
          <StatsCard
            title="Total Service Fees"
            value={formatCurrency(safeNumber(total_service_fees))}
            icon={<Icons.Percent className="h-4 w-4 text-orange-500" />}
          />
          <StatsCard
            title="Total Assets"
            value={displayValue(total_assets)}
            icon={<Icons.Package className="h-4 w-4 text-orange-500" />}
          />
          <StatsCard
            title="Active Rentals"
            value={displayValue(active_rentals_count)}
            icon={<Icons.Activity className="h-4 w-4 text-orange-500" />}
          />
        </div>
  
        <Card className={colors.card}>
          <CardHeader>
            <CardTitle>Monthly Earnings</CardTitle>
          </CardHeader>
          <CardContent className="h-[300px]">
            {monthly_earnings && monthly_earnings.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={monthly_earnings}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="earnings" fill="#f97316" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <p className="text-center text-orange-600">No earnings data available.</p>
            )}
          </CardContent>
        </Card>
  
        <Card className={colors.card}>
          <CardHeader>
            <CardTitle>Recent Rentals</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Asset</TableHead>
                  <TableHead>Renter</TableHead>
                  <TableHead>Start Time</TableHead>
                  <TableHead>End Time</TableHead>
                  <TableHead>Total Price</TableHead>
                  <TableHead>Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {recent_rentals && recent_rentals.length > 0 ? (
                  recent_rentals.map((rental) => (
                    <TableRow key={rental.id}>
                      <TableCell>{safeString(rental.asset?.name)}</TableCell>
                      <TableCell>{`${safeString(rental.user?.first_name)} ${safeString(rental.user?.last_name)}`}</TableCell>
                      <TableCell>{formatDate(rental.start_time)}</TableCell>
                      <TableCell>{formatDate(rental.end_time)}</TableCell>
                      <TableCell>{formatCurrency(safeNumber(rental.total_price))}</TableCell>
                      <TableCell>{safeString(rental.status)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} className="text-center text-orange-600">No recent rentals found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  };
  

  const renderAssetsTab = () => {
    if (!dashboardData || !Array.isArray(dashboardData.assets)) {
      return <ErrorMessage message="No assets data available or invalid data format" />;
    }

    return (
      <Card className={colors.card}>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Your Assets</CardTitle>
          <Button onClick={handleAddAsset} className={`${colors.accent}`}>
            <Icons.Plus className="w-4 h-4 mr-2" />
            Add New Asset
          </Button>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Category</TableHead>
                <TableHead>Hourly Rate</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dashboardData.assets.length > 0 ? (
                dashboardData.assets.map((asset) => (
                  <TableRow key={asset.id}>
                    <TableCell>{safeString(asset.name)}</TableCell>
                    <TableCell>{safeString(asset.category?.name)}</TableCell>
                    <TableCell>{formatCurrency(safeNumber(asset.hourly_rate))}</TableCell>
                    <TableCell>{asset.is_available ? 'Available' : 'Unavailable'}</TableCell>
                    <TableCell>
                      <Button variant="outline" size="sm" onClick={() => navigate(`/assets/${asset.slug}`)}>
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="text-center text-orange-600">No assets found. Click 'Add New Asset' to get started.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  };

  const renderEarningsTab = () => {
    if (!dashboardData) return <ErrorMessage message="No earnings data available" />;
  
    const {
      total_earnings = 0,
      pending_earnings = 0,
      paid_earnings = 0,
      total_service_fees = 0,
      asset_earnings = [],
      service_fee_percentage = 0
    } = dashboardData;
  
    const pieChartData = [
      { name: 'Net Earnings', value: safeNumber(paid_earnings) + safeNumber(pending_earnings) },
      { name: 'Service Fees', value: safeNumber(total_service_fees) },
    ];
    const COLORS = ['#0088FE', '#00C49F'];
  
    return (
      <div className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <StatsCard
            title="Total Earnings"
            value={formatCurrency(safeNumber(total_earnings))}
            icon={<Icons.TrendingUp className="h-4 w-4 text-orange-500" />}
          />
          <StatsCard
            title="Pending Earnings"
            value={formatCurrency(safeNumber(pending_earnings))}
            icon={<Icons.Clock className="h-4 w-4 text-orange-500" />}
          />
          <StatsCard
            title="Paid Earnings"
            value={formatCurrency(safeNumber(paid_earnings))}
            icon={<Icons.CheckCircle className="h-4 w-4 text-orange-500" />}
          />
          <StatsCard
            title="Total Service Fees"
            value={formatCurrency(safeNumber(total_service_fees))}
            icon={<Icons.Percent className="h-4 w-4 text-orange-500" />}
          />
        </div>
  
        <Card className={colors.card}>
          <CardHeader>
            <CardTitle>Earnings Distribution</CardTitle>
          </CardHeader>
          <CardContent className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
  
        <Card className={colors.card}>
          <CardHeader>
            <CardTitle>Asset Earnings</CardTitle>
            <CardDescription>Current Service Fee: {safeNumber(service_fee_percentage)}%</CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Asset</TableHead>
                  <TableHead>Rental Date</TableHead>
                  <TableHead>Gross Amount</TableHead>
                  <TableHead>Net Amount</TableHead>
                  <TableHead>Paid to Vendor</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Array.isArray(asset_earnings) && asset_earnings.length > 0 ? (
                  asset_earnings.map((earning) => (
                    <TableRow key={earning.id}>
                      <TableCell>{safeString(earning.asset_name)}</TableCell>
                      <TableCell>{formatDate(earning.rental_date)}</TableCell>
                      <TableCell>{formatCurrency(safeNumber(earning.gross_amount))}</TableCell>
                      <TableCell>{formatCurrency(safeNumber(earning.net_amount))}</TableCell>
                      <TableCell>{earning.is_paid_to_vendor ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center text-orange-600">No asset earnings found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner size="large" />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!dashboardData) {
    return <ErrorMessage message="No dashboard data available" />;
  }

  return (
    <Card className={`w-full ${colors.card}`}>
      <CardHeader className="flex flex-row items-center justify-between">
        <div>
          <CardTitle className="text-2xl font-bold text-orange-900">Vendor Dashboard</CardTitle>
          <CardDescription className="text-orange-600">Manage your vendor activities and performance</CardDescription>
        </div>
        <Button onClick={handleRefresh} variant="outline" size="sm">
          <RefreshCw className="w-4 h-4 mr-2" />
          Refresh
        </Button>
      </CardHeader>
      <CardContent>
        <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-4">
          <TabsList className="w-full flex justify-start overflow-x-auto">
            <TabsTrigger value="overview" className="flex-1">Overview</TabsTrigger>
            <TabsTrigger value="assets" className="flex-1">Assets</TabsTrigger>
            <TabsTrigger value="earnings" className="flex-1">Earnings</TabsTrigger>
          </TabsList>
          <TabsContent value="overview">{renderOverviewTab()}</TabsContent>
          <TabsContent value="assets">{renderAssetsTab()}</TabsContent>
          <TabsContent value="earnings">{renderEarningsTab()}</TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

// Error Boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage message="Something went wrong. Please refresh the page and try again." />;
    }

    return this.props.children;
  }
}

// Wrap the VendorDashboard component with ErrorBoundary
const SafeVendorDashboard = (props) => (
  <ErrorBoundary>
    <VendorDashboard {...props} />
  </ErrorBoundary>
);

export default SafeVendorDashboard;