import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MagnifyingGlassIcon, ArrowsUpDownIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { getAssets, getCategories, getLocations } from '../services/api';
import AssetCard from '../components/AssetCard';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const SearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [sortBy, setSortBy] = useState('relevance');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filters, setFilters] = useState({
    search: '',
    category: '',
    minPrice: '',
    maxPrice: '',
    location: '',
    pickup_date: null,
    return_date: null,
  });
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);

  const parseDateString = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  const parseSearchParams = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {
      search: searchParams.get('search') || '',
      category: searchParams.get('category') || '',
      location: searchParams.get('location') || '',
      pickup_date: parseDateString(searchParams.get('pickup_date')),
      return_date: parseDateString(searchParams.get('return_date')),
      minPrice: searchParams.get('minPrice') || '',
      maxPrice: searchParams.get('maxPrice') || '',
    };
    console.log('Parsed search params:', params);
    return params;
  }, [location.search]);

  useEffect(() => {
    const params = parseSearchParams();
    setFilters(params);
    setCurrentPage(1);
    fetchSearchResults(params);
  }, [location.search, parseSearchParams]);


  const fetchCategories = useCallback(async () => {
    try {
      const response = await getCategories();
      if (response.data && Array.isArray(response.data.results)) {
        setCategories(response.data.results);
      } else {
        throw new Error('Unexpected categories data structure');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error('Failed to load categories. Please try again.');
      setCategories([]);
    }
  }, []);

  const fetchLocations = useCallback(async () => {
    try {
      const response = await getLocations();
      if (response.data && Array.isArray(response.data.results)) {
        setLocations(response.data.results);
      } else {
        throw new Error('Unexpected locations data structure');
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      toast.error('Failed to load locations. Please try again.');
      setLocations([]);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
    fetchLocations();
  }, [fetchCategories, fetchLocations]);

  const fetchSearchResults = useCallback(async (params) => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching search results with params:', params);
      const response = await getAssets({
        search: params.search,
        category: params.category,
        page: currentPage,
        ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`,
        location: params.location || undefined,
        min_price: params.minPrice || undefined,
        max_price: params.maxPrice || undefined,
        pickup_date: params.pickup_date ? params.pickup_date.toISOString() : undefined,
        return_date: params.return_date ? params.return_date.toISOString() : undefined,
        available: true, // Add this line to only show available assets
      });
      console.log('Search results:', response.data);
      setSearchResults(response.data.results || []);
      setTotalResults(response.data.count || 0);
      setTotalPages(Math.ceil((response.data.count || 0) / (response.data.page_size || 10)));
    } catch (err) {
      console.error('Error fetching search results:', err);
      setError('An error occurred while fetching search results. Please try again.');
      toast.error('Failed to fetch search results. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [currentPage, sortBy, sortOrder]);
  


  useEffect(() => {
    fetchSearchResults(filters);
  }, [fetchSearchResults, filters, currentPage, sortBy, sortOrder]);

  const handleSortChange = (newSortBy) => {
    if (newSortBy === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setSortOrder('desc');
    }
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    setCurrentPage(1);
  };

  const handleDateChange = (date, name) => {
    setFilters(prevFilters => ({ ...prevFilters, [name]: date }));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };


  const handleSearch = (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        if (value instanceof Date) {
          searchParams.append(key, value.toISOString());
        } else {
          searchParams.append(key, value);
        }
      }
    });
    const searchString = searchParams.toString();
    console.log('Search string:', searchString);
    navigate(`/search-results?${searchString}`);
  };

  const renderSortIcon = (columnName) => {
    if (sortBy !== columnName) return <ArrowsUpDownIcon className="h-4 w-4 inline" />;
    return sortOrder === 'asc' ? <ChevronUpIcon className="h-4 w-4 inline" /> : <ChevronDownIcon className="h-4 w-4 inline" />;
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Search Results for "{filters.search}"</h1>

      <form onSubmit={handleSearch} className="mb-8">
        <div className="flex flex-wrap gap-4">
          <div className="flex-grow">
            <input
              type="text"
              name="search"
              value={filters.search}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary"
              placeholder="Search assets..."
            />
          </div>
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <MagnifyingGlassIcon className="h-5 w-5 mr-2" />
            Search
          </button>
        </div>
      </form>

      <div className="mb-8 bg-white p-4 rounded-lg shadow">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
          <select
            name="category"
            value={filters.category}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary"
          >
            <option value="">All Categories</option>
            {categories.map(category => (
              <option key={category.id} value={category.slug}>{category.name}</option>
            ))}
          </select>
          <select
            name="location"
            value={filters.location}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary"
          >
            <option value="">All Locations</option>
            {locations.map(location => (
              <option key={location.id} value={location.id}>{location.name}</option>
            ))}
          </select>
          <DatePicker
            selected={filters.pickup_date}
            onChange={(date) => handleDateChange(date, 'pickup_date')}
            placeholderText="Pickup Date"
            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary"
            isClearable
          />
          <DatePicker
            selected={filters.return_date}
            onChange={(date) => handleDateChange(date, 'return_date')}
            placeholderText="Return Date"
            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary"
            isClearable
          />
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center space-x-2 mb-2 sm:mb-0">
            <input
              type="number"
              name="minPrice"
              value={filters.minPrice}
              onChange={handleFilterChange}
              placeholder="Min Price"
              className="w-24 px-2 py-1 rounded-md border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary"
            />
            <span>-</span>
            <input
              type="number"
              name="maxPrice"
              value={filters.maxPrice}
              onChange={handleFilterChange}
              placeholder="Max Price"
              className="w-24 px-2 py-1 rounded-md border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary"
            />
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium text-gray-700">Sort by:</span>
            <button
              onClick={() => handleSortChange('relevance')}
              className={`px-3 py-2 text-sm font-medium rounded-md ${sortBy === 'relevance' ? 'bg-primary-100 text-primary-700' : 'text-gray-700 hover:bg-gray-100'
                }`}
            >
              Relevance {renderSortIcon('relevance')}
            </button>
            <button
              onClick={() => handleSortChange('hourly_rate')}
              className={`px-3 py-2 text-sm font-medium rounded-md ${sortBy === 'hourly_rate' ? 'bg-primary-100 text-primary-700' : 'text-gray-700 hover:bg-gray-100'
                }`}
            >
              Price {renderSortIcon('hourly_rate')}
            </button>
            <button
              onClick={() => handleSortChange('created_at')}
              className={`px-3 py-2 text-sm font-medium rounded-md ${sortBy === 'created_at' ? 'bg-primary-100 text-primary-700' : 'text-gray-700 hover:bg-gray-100'
                }`}
            >
              Date {renderSortIcon('created_at')}
            </button>
          </div>
        </div>
      </div>

      {/* Search results */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
        </div>
      ) : error ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      ) : searchResults.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-xl text-gray-600">No results found for "{filters.search}"</p>
        </div>
      ) : (
        <>
          <p className="text-gray-600 mb-4">Showing {searchResults.length} of {totalResults} results</p>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {searchResults.map((asset) => (
              <AssetCard key={asset.id} asset={asset} />
            ))}
          </div>
        </>
      )}

      {totalPages > 1 && (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 mt-8">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{(currentPage - 1) * 10 + 1}</span> to <span className="font-medium">{Math.min(currentPage * 10, totalResults)}</span> of{' '}
                <span className="font-medium">{totalResults}</span> results
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                {[...Array(totalPages).keys()].map((page) => (
                  <button
                    key={page + 1}
                    onClick={() => handlePageChange(page + 1)}
                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === page + 1
                        ? 'z-10 bg-primary-50 border-primary-500 text-primary-600'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                      }`}
                  >
                    {page + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResultsPage;