import React from 'react';
import { FaSearch, FaCalendarAlt, FaHandshake, FaMoneyBillWave, FaStar } from 'react-icons/fa';

// Updated color scheme
const colors = {
  primary: 'bg-orange-600 text-white',
  secondary: 'bg-orange-500 text-white',
  accent: 'text-orange-600',
  background: 'bg-gray-100', // Light grey background
  text: 'text-gray-800', // Dark text for contrast
  muted: 'text-gray-600', // Slightly lighter text for secondary elements
  card: 'bg-white', // White background for cards
};

const Step = ({ icon, title, description }) => (
  <div className={`${colors.card} border border-gray-200 rounded-lg p-6 flex flex-col items-center text-center shadow-sm`}>
    {React.cloneElement(icon, { className: `h-12 w-12 ${colors.accent}` })}
    <h3 className={`mt-4 text-lg font-medium ${colors.text}`}>{title}</h3>
    <p className={`mt-2 text-sm ${colors.muted}`}>{description}</p>
  </div>
);

const Section = ({ title, children }) => (
  <div className={`${colors.card} shadow overflow-hidden sm:rounded-lg mb-12`}>
    <div className="px-4 py-5 sm:p-6">
      <h2 className={`text-2xl font-semibold ${colors.text} mb-4`}>{title}</h2>
      {children}
    </div>
  </div>
);

const HowItWorksPage = () => {
  const steps = [
    {
      icon: <FaSearch />,
      title: "Search and Discover",
      description: "Browse through our wide selection of items. Use filters to narrow down your search based on category, location, and dates."
    },
    {
      icon: <FaCalendarAlt />,
      title: "Book and Reserve",
      description: "Once you find the perfect item, check its availability and make a reservation for your desired dates."
    },
    {
      icon: <FaHandshake />,
      title: "Meet and Collect",
      description: "Arrange a meeting with the item owner to collect the item. Verify its condition and complete any necessary paperwork."
    },
    {
      icon: <FaMoneyBillWave />,
      title: "Use and Return",
      description: "Enjoy using the item for your rental period. Return it to the owner in the same condition you received it."
    },
    {
      icon: <FaStar />,
      title: "Rate and Review",
      description: "After your rental, leave a review of your experience to help other users and improve our community."
    }
  ];

  return (
    <div className={`${colors.background} min-h-screen py-12 px-4 sm:px-6 lg:px-8`}>
      <div className="max-w-7xl mx-auto">
        <h1 className={`text-4xl font-bold text-center ${colors.text} mb-12`}>How AliHire Works</h1>
        
        <Section title="Renting an Item">
          <p className={`${colors.muted} mb-6`}>
            We have made renting an item on AliHire very simple and straightforward. Follow these steps to get started:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {steps.map((step, index) => (
              <Step key={index} {...step} />
            ))}
          </div>
        </Section>

        <Section title="Listing Your Item">
          <p className={`${colors.muted} mb-6`}>
            Want to earn money by renting out your items? Here's how to list your item on AliHire:
          </p>
          <ol className={`list-decimal pl-6 space-y-4 ${colors.muted}`}>
            <li>Create an account or log in to your existing account.</li>
            <li>Click on "List Your Item" in the navigation menu.</li>
            <li>Provide detailed information about your item, including its category, condition, and availability.</li>
            <li>Upload clear, high-quality photos of your item.</li>
            <li>Set your rental rates and any additional fees or requirements.</li>
            <li>Review and publish your listing.</li>
            <li>Respond to rental requests and manage your bookings through your dashboard.</li>
          </ol>
        </Section>

        <Section title="Safety and Trust">
          <p className={`${colors.muted} mb-6`}>
            At AliHire, we prioritize the safety and trust of our community. Here's how we ensure a secure rental experience:
          </p>
          <ul className={`list-disc pl-6 space-y-4 ${colors.muted}`}>
            <li>Verification: We give users the chance to meet and verify the identity of each other to promote trust and accountability.</li>
            <li>Secure Payments: All transactions are processed securely through our platform.</li>
            <li>Insurance Options: We offer optional insurance coverage for added peace of mind during rentals.</li>
            <li>Review System: Our review system helps maintain quality and trust within the community.</li>
            <li>24/7 Support: Our customer support team is always available to assist with any issues or concerns.</li>
          </ul>
        </Section>
      </div>
    </div>
  );
};

export default HowItWorksPage;
