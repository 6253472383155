import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { useWishlist } from '../contexts/WishlistContext';
import { useAuth } from '../contexts/AuthContext';
import AssetDetails from './asset details/AssetDetails';
import {
  getAssetDetails,
  getAssetAvailability,
  createReservation,
  getAssetReviews,
  getSimilarAssets,
  createReview,
  createCancelToken
} from '../services/api';
import { toast } from 'react-toastify';
import { format, parseISO, addMonths, subMonths, isSameDay, differenceInDays, differenceInHours, startOfMonth, endOfMonth, eachDayOfInterval, isWithinInterval } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import {
  TagIcon,
  CurrencyDollarIcon,
  StarIcon,
  ShoppingCartIcon,
  HeartIcon,
  MapPinIcon,
  ClockIcon,
  CogIcon,
  UserGroupIcon,
  TruckIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import axios from 'axios';

const formatCurrency = (value) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  if (typeof numericValue === 'number' && !isNaN(numericValue)) {
    return `KES ${numericValue.toLocaleString('en-KE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
  return value.toString();
};

const AssetDetailsPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [asset, setAsset] = useState(null);
  const [availability, setAvailability] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [similarAssets, setSimilarAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState('');
  const [hours, setHours] = useState(1);
  const [selectedRentalPeriod, setSelectedRentalPeriod] = useState('daily');
  const [reservationLoading, setReservationLoading] = useState(false);
  const [reservationError, setReservationError] = useState(null);
  
  const { addToCart, loading: cartLoading } = useCart();
  const { isInWishlist, addToWishlist, removeFromWishlist, loading: wishlistLoading } = useWishlist();
  
  const { user } = useAuth();
  const [userReview, setUserReview] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showReviewForm, setShowReviewForm] = useState(false);

  const fetchAssetDetails = useCallback(async () => {
    if (!slug) {
      setError('Invalid asset. Redirecting to assets page...');
      setTimeout(() => navigate('/assets'), 3000);
      return;
    }
  
    const cancelToken = createCancelToken();
  
    try {
      setLoading(true);
      setError(null);
      
      const today = new Date();
      const nextMonth = addMonths(today, 1);
      
      const availabilityParams = {
        start_date: format(today, 'yyyy-MM-dd'),
        end_date: format(nextMonth, 'yyyy-MM-dd')
      };
  
      const [assetResponse, availabilityData, reviewsResponse, similarAssetsResponse] = await Promise.all([
        getAssetDetails({ slug }, cancelToken),
        getAssetAvailability({ slug }, availabilityParams, cancelToken),
        getAssetReviews({ slug }, cancelToken),
        getSimilarAssets({ slug }, cancelToken)
      ]);
  
      setAsset(assetResponse.data);
      
      // Ensure availabilityData is an array before setting it
      if (Array.isArray(availabilityData)) {
        setAvailability(availabilityData);
      } else {
        console.error('Unexpected availability data format:', availabilityData);
        setError('Failed to fetch availability data. Please try again later.');
      }
      
      setReviews(reviewsResponse.data);
      setSimilarAssets(similarAssetsResponse.data || []);
  
    } catch (err) {
      if (err.name === 'CanceledError') {
        console.log('Request canceled:', err.message);
      } else {
        console.error('Error fetching asset details:', err);
        let errorMessage = 'Failed to fetch asset details. Please try again later.';
        
        if (err.response && err.response.data && err.response.data.error) {
          errorMessage = err.response.data.error;
        } else if (err.message) {
          errorMessage = err.message;
        }
        
        setError(errorMessage);
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  }, [slug, navigate]);
  
  // Don't forget to include this useEffect to call fetchAssetDetails
  useEffect(() => {
    fetchAssetDetails();
  }, [fetchAssetDetails]);


  useEffect(() => {
    fetchAssetDetails();
  }, [fetchAssetDetails]);

  const handleSubmitReview = async (reviewData) => {
    if (!user) {
      toast.error('Please login to submit a review');
      return;
    }
    try {
      const reviewPayload = {
        ...reviewData,
        asset_slug: slug 
      };
      await createReview(slug, reviewPayload);
      toast.success('Review submitted successfully!');
      setShowReviewForm(false);
      fetchAssetDetails();
    } catch (error) {
      console.error('Error submitting review:', error);
      toast.error('Failed to submit review. Please try again.');
    }
  };

  const handleAddToCart = async () => {
    if (!user) {
      toast.error('Please login to add items to cart');
      return;
    }
    if (!asset) {
      toast.error('Asset information is not available');
      return;
    }
    try {
      await addToCart(asset.id, 1, 1); // Assuming default quantity and hours are 1
      toast.success('Added to cart');
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add to cart. Please try again.');
    }
  };


  const handleToggleWishlist = async () => {
    if (!user) {
      toast.error('Please login to manage your wishlist');
      return;
    }
    if (!asset) {
      toast.error('Asset information is not available');
      return;
    }
    try {
      if (isInWishlist(asset.id)) {
        await removeFromWishlist(asset.id);
        toast.success('Removed from wishlist');
      } else {
        await addToWishlist(asset.id);
        toast.success('Added to wishlist');
      }
    } catch (error) {
      console.error('Error updating wishlist:', error);
      toast.error('Failed to update wishlist. Please try again.');
    }
  };

  const handleReservation = async () => {
    if (!user) {
      toast.error('Please login to make a reservation');
      return;
    }
    if (!startDate || !startTime || !endDate || !endTime) {
      toast.error('Please select both start and end dates and times');
      return;
    }
    if (!asset) {
      toast.error('Asset information is not available');
      return;
    }
    setReservationLoading(true);
    setReservationError(null);
    try {
      const startDateTime = `${startDate}T${startTime}:00`;
      const endDateTime = `${endDate}T${endTime}:00`;
      const reservationData = {
        asset_id: asset.id,
        start_time: startDateTime,
        end_time: endDateTime,
        rental_period: selectedRentalPeriod
      };
      const response = await createReservation(reservationData);
      toast.success('Reservation created successfully');
      fetchAssetDetails();
    } catch (err) {
      console.error('Error creating reservation:', err);
      setReservationError(err.response?.data?.error || 'Failed to create reservation. Please try again.');
      toast.error(err.response?.data?.error || 'Failed to create reservation. Please try again.');
    } finally {
      setReservationLoading(false);
    }
  };

  const handleImageNavigation = (direction) => {
    if (asset && asset.images && asset.images.length > 0) {
      if (direction === 'next') {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % asset.images.length);
      } else {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + asset.images.length) % asset.images.length);
      }
    }
  };

  const handleDateChange = (type, value) => {
    if (type === 'startDate') {
      setStartDate(value);
      if (endDate && value > endDate) {
        setEndDate(value);
      }
    } else if (type === 'startTime') {
      setStartTime(value);
    } else if (type === 'endDate') {
      setEndDate(value);
    } else if (type === 'endTime') {
      setEndTime(value);
    } else if (type === 'hours') {
      setHours(value);
    }
  };

  const isDateAvailable = useCallback((date) => {
    const availabilityInfo = availability.find(slot => isSameDay(parseISO(slot.date), date));
    return availabilityInfo ? availabilityInfo.is_available : false;
  }, [availability]);

  const calculateTotalPrice = useMemo(() => {
    if (!startDate || !endDate || !asset) return 0;

    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);

    let totalPrice = 0;

    switch (selectedRentalPeriod) {
      case 'hourly':
        totalPrice = asset.hourly_rate * hours;
        break;
      case 'daily':
        const days = differenceInDays(end, start) + 1;
        totalPrice = asset.daily_rate * days;
        break;
      case 'weekly':
        const weeks = Math.ceil(differenceInDays(end, start) / 7);
        totalPrice = asset.weekly_rate * weeks;
        break;
      case 'monthly':
        const months = Math.ceil(differenceInDays(end, start) / 30);
        totalPrice = asset.monthly_rate * months;
        break;
      default:
        break;
    }

    return totalPrice;
  }, [startDate, endDate, startTime, endTime, selectedRentalPeriod, asset, hours]);

  const isReservationValid = useMemo(() => {
    if (!startDate || !startTime || !endDate || !endTime || !asset) return false;

    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);

    const isWithinAvailability = availability.every(slot => {
      const slotDate = parseISO(slot.date);
      return !isWithinInterval(slotDate, { start, end }) || slot.is_available;
    });

    return isWithinAvailability && start < end;
  }, [startDate, startTime, endDate, endTime, asset, availability]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error || !asset) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error || 'Asset not found.'}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="col-span-2 space-y-6">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl md:text-4xl font-bold text-gray-900"
            >
              {asset.name}
            </motion.h1>
            <AssetImages asset={asset} currentImageIndex={currentImageIndex} handleImageNavigation={handleImageNavigation} />
            {asset && <AssetDetails asset={asset} />}
            <AssetPricing asset={asset} />
            <AssetDescription description={asset.description} />
            <Reviews
              reviews={reviews}
              assetId={asset.id}
              onSubmitReview={handleSubmitReview}
              showReviewForm={showReviewForm}
              setShowReviewForm={setShowReviewForm}
              userReview={userReview}
              user={user}
            />
          </div>
          <div className="mt-10 lg:mt-0 space-y-6">
            <ReservationForm
              asset={asset}
              startDate={startDate}
              startTime={startTime}
              endDate={endDate}
              endTime={endTime}
              hours={hours}
              selectedRentalPeriod={selectedRentalPeriod}
              setStartDate={(date) => handleDateChange('startDate', date)}
              setStartTime={(time) => handleDateChange('startTime', time)}
              setEndDate={(date) => handleDateChange('endDate', date)}
              setEndTime={(time) => handleDateChange('endTime', time)}
              setHours={(value) => handleDateChange('hours', value)}
              setSelectedRentalPeriod={setSelectedRentalPeriod}
              handleReservation={handleReservation}
              user={user}
              totalPrice={calculateTotalPrice}
              isDateAvailable={isDateAvailable}
              isReservationValid={isReservationValid}
              reservationLoading={reservationLoading}
              reservationError={reservationError}
            />
            <AvailabilityCalendar
              availability={availability}
              startDate={startDate}
              endDate={endDate}
            />
            <ActionButtons
        asset={asset}
        handleAddToCart={handleAddToCart}
        handleToggleWishlist={handleToggleWishlist}
        isInWishlist={isInWishlist}
        cartLoading={cartLoading}
        wishlistLoading={wishlistLoading}
        user={user}
      />
            <SimilarAssets assets={similarAssets} />
          </div>
        </div>
        <FAQ assetType={asset.category?.name} />
      </div>
    </div>
  );
};

const AssetImages = ({ asset, currentImageIndex, handleImageNavigation }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden"
  >
    <img
      src={asset.images && asset.images.length > 0
        ? asset.images[currentImageIndex]
        : 'https://via.placeholder.com/800x600?text=No+Image'}
      alt={`${asset.name} - Image ${currentImageIndex + 1}`}
      className="w-full h-full object-cover rounded-lg shadow-lg"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = 'https://via.placeholder.com/800x600?text=Error+Loading+Image';
      }}
    />
    {asset.images && asset.images.length > 1 && (
      <>
        <button
          onClick={() => handleImageNavigation('prev')}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition duration-300"
          aria-label="Previous image"
        >
          <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
        </button>
        <button
          onClick={() => handleImageNavigation('next')}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition duration-300"
          aria-label="Next image"
        >
          <ChevronRightIcon className="h-6 w-6 text-gray-600" />
        </button>
      </>
    )}
  </motion.div>
);

const DetailItem = ({ icon, label, value }) => (
  <div className="flex items-center space-x-3">
    <div className="flex-shrink-0 h-6 w-6 text-gray-400">{icon}</div>
    <div>
      <p className="text-sm font-medium text-gray-500">{label}</p>
      <p className="mt-1 text-sm font-semibold text-gray-900">{value}</p>
    </div>
  </div>
);

const AssetPricing = ({ asset }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.3 }}
    className="bg-white rounded-lg shadow-md p-6"
  >
    <h2 className="text-xl font-semibold mb-4">Pricing</h2>
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
      <PriceItem label="Hourly" price={asset.hourly_rate} />
      <PriceItem label="Daily" price={asset.daily_rate} />
      <PriceItem label="Weekly" price={asset.weekly_rate} />
      <PriceItem label="Monthly" price={asset.monthly_rate} />
    </div>
    <div className="mt-4 text-xs text-gray-600">
      <p>Deposit required: <span className="font-medium text-gray-900">{formatCurrency(asset.deposit_required)}</span></p>
    </div>
  </motion.div>
);

const PriceItem = ({ label, price }) => (
  <div className="text-center p-3 bg-gray-100 rounded-lg">
    <p className="text-xs font-medium text-gray-600">{label}</p>
    <p className="mt-1 text-sm font-semibold text-gray-900">{formatCurrency(price)}</p>
  </div>
);

const AssetDescription = ({ description }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.4 }}
    className="bg-white rounded-lg shadow-md p-6"
  >
    <h2 className="text-2xl font-semibold mb-4">Description</h2>
    <p className="text-gray-700 whitespace-pre-wrap">{description}</p>
  </motion.div>
);

const Reviews = ({ reviews, assetId, onSubmitReview, showReviewForm, setShowReviewForm, userReview, user }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.5 }}
    className="bg-white rounded-lg shadow-md p-6"
  >
    <h2 className="text-2xl font-semibold mb-4">Reviews</h2>
    {reviews.length > 0 ? (
      <div className="space-y-4">
        {reviews.map((review) => (
          <div key={review.id} className="border-b pb-4">
            <div className="flex items-center mb-2">
              <StarRating rating={review.rating} />
              <span className="ml-2 text-sm text-gray-500">
                {format(parseISO(review.created_at), 'MMMM d, yyyy')}
              </span>
            </div>
            <p className="text-gray-700">{review.comment}</p>
            <p className="mt-1 text-sm text-gray-500">By {review.user.username}</p>
          </div>
        ))}
      </div>
    ) : (
      <p className="text-gray-500">No reviews yet.</p>
    )}
    {user && !userReview && !showReviewForm && (
      <button
        onClick={() => setShowReviewForm(true)}
        className="mt-4 bg-primary text-white rounded-md py-2 px-4 text-sm hover:bg-primaryDark transition duration-300"
      >
        Write a Review
      </button>
    )}
    {showReviewForm && (
      <ReviewForm onSubmit={onSubmitReview} onCancel={() => setShowReviewForm(false)} />
    )}
  </motion.div>
);

const StarRating = ({ rating }) => {
  return (
    <div className="flex items-center">
      {[1, 2, 3, 4, 5].map((star) => (
        <svg
          key={star}
          className={`h-5 w-5 ${star <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      ))}
    </div>
  );
};

const ReviewForm = ({ onSubmit, onCancel }) => {
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ rating, comment });
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4 space-y-4">
      <div>
        <label htmlFor="rating" className="block text-sm font-medium text-gray-700">Rating</label>
        <select
          id="rating"
          value={rating}
          onChange={(e) => setRating(Number(e.target.value))}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
        >
          {[1, 2, 3, 4, 5].map((value) => (
            <option key={value} value={value}>{value} Star{value !== 1 ? 's' : ''}</option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Comment</label>
        <textarea
          id="comment"
          rows="3"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="mt-1 block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
          placeholder="Write your review here..."
        ></textarea>
      </div>
      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-primary text-white rounded-md py-2 px-4 text-sm hover:bg-primaryDark transition duration-300"
        >
          Submit Review
        </button>
      </div>
    </form>
  );
};

const ReservationForm = ({
  asset,
  startDate,
  startTime,
  endDate,
  endTime,
  selectedRentalPeriod,
  setStartDate,
  setStartTime,
  setEndDate,
  setEndTime,
  setSelectedRentalPeriod,
  handleReservation,
  user,
  isDateAvailable,
  reservationLoading,
  reservationError
}) => {
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    calculateTotalPrice();
  }, [startDate, startTime, endDate, endTime, selectedRentalPeriod, quantity]);

  const calculateTotalPrice = () => {
    if (!startDate || !endDate || !asset) {
      setTotalPrice(0);
      return;
    }

    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    const diffWeeks = Math.floor(diffDays / 7);
    const diffMonths = Math.floor(diffDays / 30);

    let price = 0;
    let errorMessage = null;

    switch (selectedRentalPeriod) {
      case 'hourly':
        price = asset.hourly_rate * diffHours * quantity;
        break;
      case 'daily':
        price = asset.daily_rate * diffDays * quantity;
        break;
      case 'weekly':
        if (diffDays < 7) {
          errorMessage = "Minimum rental period for weekly rate is 7 days.";
        } else {
          price = asset.weekly_rate * diffWeeks * quantity;
          const extraDays = diffDays % 7;
          if (extraDays > 0) {
            price += asset.daily_rate * extraDays * quantity;
          }
        }
        break;
      case 'monthly':
        if (diffDays < 30) {
          errorMessage = "Minimum rental period for monthly rate is 30 days.";
        } else {
          price = asset.monthly_rate * diffMonths * quantity;
          const extraDays = diffDays % 30;
          if (extraDays > 0) {
            price += asset.daily_rate * extraDays * quantity;
          }
        }
        break;
      default:
        break;
    }

    setTotalPrice(price);
    setError(errorMessage);
  };

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value);
    setQuantity(value > 0 ? value : 1);
  };

  const isReservationValid = () => {
    if (!startDate || !endDate || !startTime || !endTime) return false;

    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);

    if (start >= end) return false;

    const days = eachDayOfInterval({ start, end });
    return days.every(day => isDateAvailable(day));
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      className="bg-white rounded-lg shadow-md p-6"
    >
      <h2 className="text-2xl font-semibold mb-4">Reservation</h2>
      <div className="space-y-4">
        <div>
          <label htmlFor="rental-period" className="block text-sm font-medium text-gray-700 mb-1">
            Rental Period
          </label>
          <select
            id="rental-period"
            value={selectedRentalPeriod}
            onChange={(e) => setSelectedRentalPeriod(e.target.value)}
            className="w-full p-2 border rounded-md text-sm focus:ring-primary focus:border-primary"
          >
            <option value="hourly">Hourly</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
        <div>
          <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-1">
            Quantity
          </label>
          <input
            id="quantity"
            type="number"
            min="1"
            value={quantity}
            onChange={handleQuantityChange}
            className="w-full p-2 border rounded-md text-sm focus:ring-primary focus:border-primary"
          />
        </div>
        <div>
          <label htmlFor="start-date" className="block text-sm font-medium text-gray-700 mb-1">
            Start Date
          </label>
          <input
            id="start-date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full p-2 border rounded-md text-sm focus:ring-primary focus:border-primary"
            min={new Date().toISOString().split('T')[0]}
          />
        </div>
        <div>
          <label htmlFor="start-time" className="block text-sm font-medium text-gray-700 mb-1">
            Start Time
          </label>
          <input
            id="start-time"
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="w-full p-2 border rounded-md text-sm focus:ring-primary focus:border-primary"
          />
        </div>
        <div>
          <label htmlFor="end-date" className="block text-sm font-medium text-gray-700 mb-1">
            End Date
          </label>
          <input
            id="end-date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full p-2 border rounded-md text-sm focus:ring-primary focus:border-primary"
            min={startDate}
          />
        </div>
        <div>
          <label htmlFor="end-time" className="block text-sm font-medium text-gray-700 mb-1">
            End Time
          </label>
          <input
            id="end-time"
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            className="w-full p-2 border rounded-md text-sm focus:ring-primary focus:border-primary"
          />
        </div>
        {totalPrice > 0 && (
          <div className="text-xl font-semibold text-primary">
            Total: {formatCurrency(totalPrice)}
          </div>
        )}
        {error && (
          <div className="text-red-500 text-sm">{error}</div>
        )}
        {reservationError && (
          <div className="text-red-500 text-sm">{reservationError}</div>
        )}
        <button
          onClick={handleReservation}
          className="w-full bg-primary text-white rounded-md py-3 px-4 text-sm font-medium hover:bg-primaryDark disabled:opacity-50 disabled:cursor-not-allowed transition duration-300"
          disabled={reservationLoading || !user || !isReservationValid()}
        >
          {reservationLoading ? 'Processing...' : (asset.is_available ? 'Reserve Now' : 'Not Available')}
        </button>
      </div>
    </motion.div>
  );
};

const AvailabilityCalendar = ({ availability, startDate, endDate }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const handlePrevMonth = () => {
    setCurrentMonth(prevMonth => subMonths(prevMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(prevMonth => addMonths(prevMonth, 1));
  };

  const getDaysInMonth = (date) => {
    const start = startOfMonth(date);
    const end = endOfMonth(date);
    return eachDayOfInterval({ start, end });
  };

  const days = getDaysInMonth(currentMonth);
  const firstDayOfMonth = days[0].getDay();

  const getAvailabilityStatus = (date) => {
    const availabilityInfo = availability.find(slot => isSameDay(parseISO(slot.date), date));
    if (!availabilityInfo) return 'unknown';
    return availabilityInfo.is_available ? 'available' : 'not-available';
  };

  const statusColors = {
    'unknown': 'bg-gray-300',
    'not-available': 'bg-red-400',
    'reserved': 'bg-blue-400',
    'available': 'bg-green-400'
  };

  const isDateInRange = (date) => {
    if (!startDate || !endDate) return false;
    return isWithinInterval(date, { start: parseISO(startDate), end: parseISO(endDate) });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
      className="bg-white rounded-lg shadow-md p-6"
    >
      <h2 className="text-2xl font-semibold mb-4">Availability</h2>
      <div className="flex justify-between items-center mb-4">
        <button onClick={handlePrevMonth} className="text-gray-600 hover:text-gray-900 transition duration-300">
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
        <h3 className="text-lg font-semibold">
          {format(currentMonth, 'MMMM yyyy')}
        </h3>
        <button onClick={handleNextMonth} className="text-gray-600 hover:text-gray-900 transition duration-300">
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 text-center">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="text-gray-500 text-xs font-medium">{day}</div>
        ))}
        {Array.from({ length: firstDayOfMonth }).map((_, index) => (
          <div key={`empty-${index}`} className="h-8"></div>
        ))}
        {days.map(day => {
          const dateString = format(day, 'yyyy-MM-dd');
          const status = getAvailabilityStatus(day);
          const isSelected = isDateInRange(day);
          return (
            <div
              key={dateString}
              className={`h-8 flex flex-col justify-center items-center text-xs rounded-md ${
                isSelected ? 'bg-yellow-400 text-white' : statusColors[status]
              } ${status === 'available' ? 'text-gray-800' : 'text-white'}`}
            >
              <span className="font-semibold">{format(day, 'd')}</span>
            </div>
          );
        })}
      </div>
      <div className="mt-4 grid grid-cols-2 gap-2">
        <div className="flex items-center">
          <div className="w-4 h-4 bg-green-400 rounded-full mr-2"></div>
          <span className="text-sm">Available</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-blue-400 rounded-full mr-2"></div>
          <span className="text-sm">Booked</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-red-400 rounded-full mr-2"></div>
          <span className="text-sm">Not Available</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-yellow-400 rounded-full mr-2"></div>
          <span className="text-sm">Selected Period</span>
        </div>
      </div>
    </motion.div>
  );
};

const ActionButtons = ({
  asset,
  handleAddToCart,
  handleToggleWishlist,
  isInWishlist,
  cartLoading,
  wishlistLoading,
  user
}) => {
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const handleAddToCartClick = async () => {
    setIsAddingToCart(true);
    await handleAddToCart();
    setIsAddingToCart(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.5 }}
      className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4"
    >
      <button
        onClick={handleAddToCartClick}
        className="flex-1 bg-primary text-white rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium hover:bg-primaryDark disabled:opacity-50 disabled:cursor-not-allowed transition duration-300"
        disabled={isAddingToCart || !user || !asset.is_available}
      >
        <ShoppingCartIcon className="h-5 w-5 mr-2" />
        {isAddingToCart ? 'Adding...' : 'Add to Cart'}
      </button>
      <button
        onClick={handleToggleWishlist}
        className={`flex-1 bg-white border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium transition duration-300 ${
          isInWishlist(asset.id) ? 'text-secondary border-secondary' : 'text-gray-700 border-gray-300 hover:bg-gray-50'
        }`}
        disabled={wishlistLoading || !user}
      >
        {isInWishlist(asset.id) ? (
          <HeartIconSolid className="h-5 w-5 mr-2" />
        ) : (
          <HeartIcon className="h-5 w-5 mr-2" />
        )}
        {wishlistLoading ? 'Updating...' : (isInWishlist(asset.id) ? 'Remove from Wishlist' : 'Add to Wishlist')}
      </button>
    </motion.div>
  );
};

const SimilarAssets = ({ assets }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.4 }}
    className="bg-white rounded-lg shadow-md p-6"
  >
    <h2 className="text-2xl font-semibold mb-4">Similar Assets</h2>
    {assets.length > 0 ? (
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {assets.map((asset) => (
          <div key={asset.id} className="border rounded-md p-4 hover:shadow-lg transition duration-300">
            <img
              src={asset.images && asset.images.length > 0 ? asset.images[0] : 'https://via.placeholder.com/150'}
              alt={asset.name}
              className="w-full h-32 object-cover rounded-md mb-2"
            />
            <h3 className="font-semibold text-lg mb-1">{asset.name}</h3>
            <p className="text-gray-600 mb-2">{formatCurrency(asset.hourly_rate)} / hour</p>
            <a
              href={`/assets/${asset.slug}`}
              className="text-primary hover:text-primaryDark transition duration-300 text-sm font-medium"
            >
              View Details
            </a>
          </div>
        ))}
      </div>
    ) : (
      <p className="text-gray-600">No similar assets found.</p>
    )}
  </motion.div>
);

const FAQ = ({ assetType }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const faqs = [
    {
      question: `What is the minimum rental period for ${assetType}?`,
      answer: "The minimum rental period varies by asset. Please check the asset details for specific information.",
    },
    {
      question: "How do I make a reservation?",
      answer: "To make a reservation, select your desired start and end dates and times, then click the 'Reserve Now' button. You'll need to be logged in to complete the reservation.",
    },
    {
      question: "Is insurance required?",
      answer: "Insurance requirements vary by asset. Some assets may require insurance, while others may not. Check the asset details or contact us for more information.",
    },
    {
      question: "What happens if I return the asset late?",
      answer: "Late returns may incur additional charges. Please contact us immediately if you anticipate returning the asset later than scheduled.",
    },
    {
      question: "Can I cancel my reservation?",
      answer: "Cancellation policies may vary. Please refer to our cancellation policy or contact our customer support for specific details about your reservation.",
    },
    {
      question: "How do I report an issue with the asset?",
      answer: "If you encounter any issues with the asset, please contact our customer support immediately. We'll work to resolve the problem as quickly as possible.",
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.6 }}
      className="bg-white rounded-lg shadow-md p-6 mt-8"
    >
      <h2 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-200 pb-4">
            <button
              className="flex justify-between items-center w-full text-left focus:outline-none"
              onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
            >
              <h3 className="font-semibold text-lg">{faq.question}</h3>
              <ChevronRightIcon
                className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
                  expandedIndex === index ? 'rotate-90' : ''
                }`}
              />
            </button>
            <AnimatePresence>
              {expandedIndex === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <p className="text-gray-700 mt-2">{faq.answer}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default AssetDetailsPage;