import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import apiService from '../services/api';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [couponDiscount, setCouponDiscount] = useState(0);

  const fetchCart = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await apiService.getCart();
      if (response.data && Array.isArray(response.data.items)) {
        setCartItems(response.data.items);
        setTotalItems(response.data.total_items || 0);
        setTotalPrice(response.data.total_price || 0);
        setCouponDiscount(response.data.coupon_discount || 0);
      } else {
        throw new Error('Unexpected response structure from server');
      }
    } catch (err) {
      console.error('Error fetching cart:', err);
      setError('Failed to fetch cart. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  const addToCart = useCallback(async (assetId, quantity = 1, hours = 1) => {
    try {
      setLoading(true);
      setError(null);
      await apiService.addToCart({}, { asset_id: assetId, quantity, hours });
      await fetchCart();
    } catch (err) {
      console.error('Error adding item to cart:', err);
      setError('Failed to add item to cart. Please try again.');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart]);

  const removeItemFromCart = useCallback(async (assetId) => {
    try {
      setLoading(true);
      setError(null);
      await apiService.removeFromCart({}, { asset_id: assetId });
      await fetchCart();
    } catch (err) {
      console.error('Error removing item from cart:', err);
      setError('Failed to remove item from cart. Please try again.');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart]);

  const updateCartItem = useCallback(async (assetId, updates) => {
    try {
      setLoading(true);
      setError(null);
      await apiService.updateCartItem({}, { asset_id: assetId, ...updates });
      await fetchCart();
    } catch (err) {
      console.error('Error updating cart item:', err);
      setError('Failed to update cart item. Please try again.');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart]);

  const clearCart = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      await apiService.clearCart();
      await fetchCart();
    } catch (err) {
      console.error('Error clearing cart:', err);
      setError('Failed to clear cart. Please try again.');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart]);

  const applyCoupon = useCallback(async (couponCode) => {
    try {
      setLoading(true);
      setError(null);
      const response = await apiService.applyCoupon({}, { code: couponCode });
      setCouponDiscount(response.data.discount_amount);
      await fetchCart();
      return response.data;
    } catch (err) {
      console.error('Error applying coupon:', err);
      setError('Failed to apply coupon. Please try again.');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart]);

  const getSubtotal = useCallback(() => {
    return cartItems.reduce((total, item) => total + (item.price * item.quantity * item.hours), 0);
  }, [cartItems]);

  const value = {
    cartItems,
    totalItems,
    totalPrice,
    loading,
    error,
    couponDiscount,
    addToCart,
    removeItemFromCart,
    updateCartItem,
    clearCart,
    fetchCart,
    applyCoupon,
    getSubtotal
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default CartProvider;