import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FaEye, FaEyeSlash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import zxcvbn from 'zxcvbn';
import { motion, AnimatePresence } from 'framer-motion';

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    referralCode: '',
    isVendor: false,
    companyName: '',
    companyAddress: '',
    businessLicenseNumber: '',
    taxId: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const { register, registerVendor, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/profile');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCode = params.get('ref');
    if (referralCode) {
      setFormData(prevData => ({ ...prevData, referralCode }));
    }
  }, [location]);

  useEffect(() => {
    if (formData.password) {
      const result = zxcvbn(formData.password);
      setPasswordStrength(result.score);
    } else {
      setPasswordStrength(0);
    }
  }, [formData.password]);

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: null }));
  }, []);

  const handleBlur = useCallback((e) => {
    const { name } = e.target;
    setTouched(prev => ({ ...prev, [name]: true }));
  }, []);

  const validateField = useCallback((name, value) => {
    let error = null;
    switch (name) {
      case 'username':
        if (!value.trim()) error = "Username is required";
        else if (value.length < 3) error = "Username must be at least 3 characters";
        break;
      case 'email':
        if (!value.trim()) error = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(value)) error = "Email is invalid";
        break;
      case 'password':
        if (!value) error = "Password is required";
        else if (value.length < 8) error = "Password must be at least 8 characters";
        else if (passwordStrength < 3) error = "Please choose a stronger password";
        break;
      case 'confirmPassword':
        if (!value) error = "Please confirm your password";
        else if (value !== formData.password) error = "Passwords don't match";
        break;
      case 'firstName':
      case 'lastName':
        if (!value.trim()) error = `${name === 'firstName' ? 'First' : 'Last'} name is required`;
        break;
      default:
        break;
    }
    return error;
  }, [formData.password, passwordStrength]);

  const validateForm = useCallback(() => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      if (key !== 'isVendor' && key !== 'referralCode' && key !== 'companyName' && key !== 'companyAddress' && key !== 'businessLicenseNumber' && key !== 'taxId') {
        const error = validateField(key, formData[key]);
        if (error) newErrors[key] = error;
      }
    });
    if (!agreeTerms) newErrors.terms = "You must agree to the Terms of Service and Privacy Policy";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, agreeTerms, validateField]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    setIsLoading(true);
    setErrors({});
  
    try {
      const userData = {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        confirm_password: formData.confirmPassword,
        first_name: formData.firstName,
        last_name: formData.lastName,
        referral_code: formData.referralCode,
      };
  
      if (formData.isVendor) {
        const vendorData = {
          user: userData,
          vendor: {
            company_name: formData.companyName,
            company_address: formData.companyAddress,
            business_license_number: formData.businessLicenseNumber,
            tax_id: formData.taxId,
          },
        };
        await registerVendor(vendorData);
      } else {
        await register(userData);
      }
  
      console.log('Registration successful, displaying success toast');
      
      toast.success(
        <div>
          <h3 className="font-bold">Registration Successful!</h3>
          <p>Please check your email to activate your account. We're excited to have you join our community!</p>
        </div>,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
  
      // Clear form data
      setFormData({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        referralCode: '',
        isVendor: false,
        companyName: '',
        companyAddress: '',
        businessLicenseNumber: '',
        taxId: '',
      });
  
      // Reset touched state
      setTouched({});
  
      // Scroll to top of the page
      window.scrollTo(0, 0);
  
    } catch (error) {
      console.error('Registration error:', error);
      let errorMessage = 'Failed to register. Please try again.';
  
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        const newErrors = {};
  
        if (errorData.user) {
          Object.entries(errorData.user).forEach(([key, value]) => {
            newErrors[key] = Array.isArray(value) ? value.join(', ') : value;
          });
          delete errorData.user;
        }
  
        Object.entries(errorData).forEach(([key, value]) => {
          newErrors[key] = Array.isArray(value) ? value.join(', ') : value;
        });
  
        setErrors(newErrors);
  
        errorMessage = Object.entries(newErrors)
          .map(([key, value]) => `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`)
          .join('\n');
      }
  
      toast.error(
        <div>
          <h3 className="font-bold">Registration Failed</h3>
          <p>{errorMessage}</p>
        </div>,
        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };
  

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen flex items-center justify-center bg-background py-12 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-2xl w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-primary">Create your account</h2>
        </div>
        
        {/* Success Message */}
        {formData.username === '' && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4"
            role="alert"
          >
            <p className="font-bold">Registration Successful!</p>
            <p>Please check your email to activate your account. We're excited to have you join our community!</p>
          </motion.div>
        )}
  
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <InputField
              id="username"
              name="username"
              type="text"
              label="Username"
              value={formData.username}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.username && errors.username}
              touched={touched.username}
              required={true}
            />
            <InputField
              id="email"
              name="email"
              type="email"
              label="Email address"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && errors.email}
              touched={touched.email}
              required={true}
            />
            <InputField
              id="firstName"
              name="firstName"
              type="text"
              label="First Name"
              value={formData.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && errors.firstName}
              touched={touched.firstName}
              required={true}
            />
            <InputField
              id="lastName"
              name="lastName"
              type="text"
              label="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && errors.lastName}
              touched={touched.lastName}
              required={true}
            />
            <PasswordField
              id="password"
              name="password"
              label="Password"
              value={formData.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && errors.password}
              touched={touched.password}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              required={true}
            />
            <PasswordField
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && errors.confirmPassword}
              touched={touched.confirmPassword}
              showPassword={showConfirmPassword}
              setShowPassword={setShowConfirmPassword}
              required={true}
            />
            <InputField
              id="referralCode"
              name="referralCode"
              type="text"
              label="Referral Code (Optional)"
              value={formData.referralCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.referralCode && errors.referralCode}
              touched={touched.referralCode}
              required={false}
            />
          </div>
  
          <PasswordStrengthMeter strength={passwordStrength} />
  
          <div className="flex items-center mb-4">
            <input
              id="isVendor"
              name="isVendor"
              type="checkbox"
              className="h-4 w-4 text-primary focus:ring-primary-dark border-gray-300 rounded"
              checked={formData.isVendor}
              onChange={handleChange}
            />
            <label htmlFor="isVendor" className="ml-2 block text-sm text-text">
              Register as a vendor (optional)
            </label>
          </div>
  
          <AnimatePresence>
            {formData.isVendor && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="grid grid-cols-1 md:grid-cols-2 gap-6"
              >
                <InputField
                  id="companyName"
                  name="companyName"
                  type="text"
                  label="Company Name (Optional)"
                  value={formData.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.companyName}
                  touched={touched.companyName}
                  required={false}
                />
                <InputField
                  id="companyAddress"
                  name="companyAddress"
                  type="text"
                  label="Company Address (Optional)"
                  value={formData.companyAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.companyAddress}
                  touched={touched.companyAddress}
                  required={false}
                />
                <InputField
                  id="businessLicenseNumber"
                  name="businessLicenseNumber"
                  type="text"
                  label="Business License Number (Optional)"
                  value={formData.businessLicenseNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.businessLicenseNumber}
                  touched={touched.businessLicenseNumber}
                  required={false}
                />
                <InputField
                  id="taxId"
                  name="taxId"
                  type="text"
                  label="Tax ID (Optional)"
                  value={formData.taxId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.taxId}
                  touched={touched.taxId}
                  required={false}
                />
              </motion.div>
            )}
          </AnimatePresence>
  
          <div className="flex items-center">
            <input
              id="agree-terms"
              name="agree-terms"
              type="checkbox"
              className={`h-4 w-4 text-primary focus:ring-primary-dark border-gray-300 rounded ${errors.terms ? 'border-red-500' : ''}`}
              checked={agreeTerms}
              onChange={(e) => setAgreeTerms(e.target.checked)}
            />
            <label htmlFor="agree-terms" className="ml-2 block text-sm text-text">
              I agree to the <a href="/terms" className="font-medium text-primary hover:text-primary-dark">Terms of Service</a> and <a href="/privacy" className="font-medium text-primary hover:text-primary-dark">Privacy Policy</a>
            </label>
          </div>
          {errors.terms && <p className="mt-2 text-sm text-red-600">{errors.terms}</p>}
  
          <div>
            <motion.button
              type="submit"
              disabled={isLoading}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isLoading ? 'Signing up...' : `Sign up as ${formData.isVendor ? 'Vendor' : 'User'}`}
            </motion.button>
          </div>
        </form>
  
        <p className="mt-2 text-center text-sm text-text">
          Already have an account?{' '}
          <Link to="/login" className="font-medium text-primary hover:text-primary-dark">
            Sign in
          </Link>
        </p>
      </div>
      <ToastContainer position="top-center" />
    </motion.div>
  );
};

const InputField = ({ id, name, type, label, value, onChange, onBlur, error, touched, required }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium text-text mb-1">{label}</label>
    <div className="relative">
      <input
        id={id}
        name={name}
        type={type}
        required={required}
        className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
          error ? 'border-red-500' : touched && required ? 'border-green-500' : 'border-gray-300'
        } placeholder-gray-500 text-text focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm`}
        placeholder={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <AnimatePresence>
        {touched && required && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          >
            {error ? (
              <FaTimesCircle className="h-5 w-5 text-red-500" />
            ) : (
              <FaCheckCircle className="h-5 w-5 text-green-500" />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
    {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
  </div>
);

const PasswordField = ({ id, name, label, value, onChange, onBlur, error, touched, showPassword, setShowPassword, required }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium text-text mb-1">{label}</label>
    <div className="relative">
      <input
        id={id}
        name={name}
        type={showPassword ? "text" : "password"}
        autoComplete={id === "password" ? "new-password" : "current-password"}
        required={required}
        className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
          error ? 'border-red-500' : touched && required ? 'border-green-500' : 'border-gray-300'
        } placeholder-gray-500 text-text focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm pr-10`}
        placeholder={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <button
        type="button"
        className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <FaEyeSlash className="text-gray-500" /> : <FaEye className="text-gray-500" />}
      </button>
      <AnimatePresence>
        {touched && required && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            className="absolute inset-y-0 right-8 pr-3 flex items-center pointer-events-none"
          >
            {error ? (
              <FaTimesCircle className="h-5 w-5 text-red-500" />
            ) : (
              <FaCheckCircle className="h-5 w-5 text-green-500" />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
    {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
  </div>
);

const PasswordStrengthMeter = ({ strength }) => {
  const getPasswordStrengthColor = () => {
    const colors = ['bg-red-500', 'bg-orange-500', 'bg-yellow-500', 'bg-green-500', 'bg-green-600'];
    return colors[strength];
  };

  return (
    <div className="mt-4">
      <div className="flex mb-2">
        {[...Array(4)].map((_, index) => (
          <motion.div
            key={index}
            className={`h-2 w-1/4 ${index === 0 ? 'rounded-l-full' : ''} ${index === 3 ? 'rounded-r-full' : ''} ${strength >= index + 1 ? getPasswordStrengthColor() : 'bg-gray-200'}`}
            initial={{ width: 0 }}
            animate={{ width: '25%' }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          ></motion.div>
        ))}
      </div>
      <motion.p
        className="text-xs text-text"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        {strength === 0 && "Very weak"}
        {strength === 1 && "Weak"}
        {strength === 2 && "Fair"}
        {strength === 3 && "Strong"}
        {strength === 4 && "Very strong"}
      </motion.p>
    </div>
  );
};

export default RegisterPage;