import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import * as Icons from 'lucide-react';
import { formatDate } from '../../utils/formatters';
import apiService from '../../services/api';

const NotificationsContent = ({ notifications, setNotifications }) => {
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [filter, setFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const notificationsPerPage = 10;

  useEffect(() => {
    const filtered = notifications.filter(notification => {
      if (filter === 'all') return true;
      if (filter === 'unread') return !notification.is_read;
      return notification.notification_type === filter;
    });
    setFilteredNotifications(filtered);
  }, [notifications, filter]);

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
  const currentNotifications = filteredNotifications.slice(indexOfFirstNotification, indexOfLastNotification);

  const totalPages = Math.ceil(filteredNotifications.length / notificationsPerPage);

  const handleMarkAsRead = async (notificationId) => {
    try {
      await apiService.markNotificationAsRead(notificationId);
      const updatedNotifications = notifications.map(notification =>
        notification.id === notificationId ? { ...notification, is_read: true } : notification
      );
      setNotifications(updatedNotifications);
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await apiService.markAllNotificationsAsRead();
      const updatedNotifications = notifications.map(notification => ({ ...notification, is_read: true }));
      setNotifications(updatedNotifications);
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error);
    }
  };

  return (
    <Card className="w-full max-w-4xl mx-auto mb-8">
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          <span>Notifications</span>
          <Button variant="outline" size="sm" onClick={handleMarkAllAsRead}>
            Mark All as Read
          </Button>
        </CardTitle>
        <CardDescription>Stay updated with your latest activities</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <Select value={filter} onValueChange={setFilter}>
            <SelectTrigger>
              <SelectValue placeholder="Filter notifications" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Notifications</SelectItem>
              <SelectItem value="unread">Unread</SelectItem>
              <SelectItem value="rental_reminder">Rental Reminders</SelectItem>
              <SelectItem value="rental_overdue">Rental Overdue</SelectItem>
              <SelectItem value="payment_received">Payments</SelectItem>
              <SelectItem value="new_promotion">Promotions</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {currentNotifications.length > 0 ? (
          <ul className="space-y-4">
            {currentNotifications.map((notification) => (
              <li key={notification.id} className={`flex items-start space-x-3 p-3 rounded-lg ${notification.is_read ? 'bg-gray-50' : 'bg-blue-50'}`}>
                <div className="flex-shrink-0 mt-1">
                  {notification.notification_type === 'rental_reminder' && <Icons.Calendar className="w-6 h-6 text-blue-500" />}
                  {notification.notification_type === 'rental_overdue' && <Icons.AlertTriangle className="w-6 h-6 text-red-500" />}
                  {notification.notification_type === 'payment_received' && <Icons.CreditCard className="w-6 h-6 text-green-500" />}
                  {notification.notification_type === 'new_promotion' && <Icons.Gift className="w-6 h-6 text-purple-500" />}
                </div>
                <div className="flex-grow">
                  <p className="text-gray-800">{notification.message}</p>
                  <p className="text-sm text-gray-500">{formatDate(notification.created_at)}</p>
                </div>
                {!notification.is_read && (
                  <Button variant="ghost" size="sm" onClick={() => handleMarkAsRead(notification.id)}>
                    Mark as Read
                  </Button>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center py-8">
            <Icons.BellOff className="w-16 h-16 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-600">No notifications at the moment. We'll notify you of important updates!</p>
          </div>
        )}
        {filteredNotifications.length > notificationsPerPage && (
          <div className="mt-4 flex justify-between items-center">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>Page {currentPage} of {totalPages}</span>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default NotificationsContent;