import React from 'react';

const CancellationPolicyPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Cancellation Policy</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Renter Cancellations</h2>
            <p className="text-gray-600 mb-6">
              Renters may cancel their reservations subject to the following refund policy:
            </p>
            <ul className="list-disc pl-6 mb-6 text-gray-600">
              <li>More than 7 days before the rental start date: Full refund (less all applicable service fees)</li>
              <li>3-7 days before the rental start date: 50% refund (less all applicable service fees)</li>
              <li>Less than 3 days before the rental start date: No refund</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Owner Cancellations</h2>
            <p className="text-gray-600 mb-6">
              Asset owners should only cancel reservations in exceptional circumstances. If an owner needs to cancel a reservation, the following applies:
            </p>
            <ul className="list-disc pl-6 mb-6 text-gray-600">
              <li>The renter will receive a full refund</li>
              <li>AliHire shall charge any applicable service fees to the item owner, and may apply penalties to the owner's account, including but not limited to charges, and reduced visibility of their listings</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Extenuating Circumstances</h2>
            <p className="text-gray-600 mb-6">
              In cases of extenuating circumstances (e.g., natural disasters, serious illness), AliHire may waive cancellation penalties. Documentation may be required to support claims of extenuating circumstances.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Refund Process</h2>
            <p className="text-gray-600 mb-6">
              Refunds are usually processed within 5-10 business days and will be issued to the original payment method used for the reservation or at the discretion of AliHire.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Modifications to Reservations</h2>
            <p className="text-gray-600 mb-6">
              Modifications to existing reservations are subject to availability and may result in price changes. If a modification results in a higher price, the renter is responsible for paying the difference.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. No-shows</h2>
            <p className="text-gray-600 mb-6">
              If a renter fails to show up for their reservation without prior cancellation, it will be treated as a last-minute cancellation and no refund will be issued.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Dispute Resolution</h2>
            <p className="text-gray-600 mb-6">
              In the event of a dispute regarding a cancellation or refund, AliHire will review the circumstances and make a final decision based on this policy and any other relevant factors.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Changes to This Policy</h2>
            <p className="text-gray-600 mb-6">
              AliHire reserves the right to modify this Cancellation Policy at any time. Changes will be effective immediately upon posting to our website.
            </p>

            <p className="text-gray-600 mt-8">
              For any questions regarding this Cancellation Policy, please contact our support team at{' '}
              <a href="mailto:info.alihire@gmail.com" className="text-green-500 hover:text-green-600">
              info.alihire@gmail.com
              </a>
              .
            </p>

            <p className="text-gray-600 mt-8">
              Last Updated: 25th September, 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationPolicyPage;
