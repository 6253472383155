import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ReferralRedirect = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the registration page with the referral code as a query parameter
    navigate(`/register?ref=${code}`);
  }, [code, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-background">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
};

export default ReferralRedirect;