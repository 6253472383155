import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import api from '../services/api';
import { useAuth } from './AuthContext';

const WishlistContext = createContext();

export const useWishlist = () => {
  const context = useContext(WishlistContext);
  if (!context) {
    throw new Error('useWishlist must be used within a WishlistProvider');
  }
  return context;
};

export const WishlistProvider = ({ children }) => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const fetchWishlist = useCallback(async () => {
    if (!user) {
      setWishlistItems([]);
      return;
    }
    try {
      setLoading(true);
      const response = await api.getWishlist();
      if (response.data && Array.isArray(response.data.items)) {
        setWishlistItems(response.data.items);
      } else if (Array.isArray(response.data)) {
        setWishlistItems(response.data);
      } else {
        console.warn('Unexpected wishlist data structure:', response.data);
        setWishlistItems([]);
      }
      setError(null);
    } catch (err) {
      console.error('Failed to fetch wishlist:', err);
      setError('Failed to fetch wishlist. Please try again later.');
      setWishlistItems([]);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchWishlist();
  }, [fetchWishlist]);

  const addToWishlist = async (assetId) => {
    if (!user) {
      setError('Please log in to add items to your wishlist.');
      return;
    }
    try {
      setLoading(true);
      const response = await api.addToWishlist({}, { asset_id: assetId });
      if (response.data && response.data.item) {
        setWishlistItems(prevItems => [...prevItems, response.data.item]);
      } else {
        await fetchWishlist();
      }
    } catch (err) {
      console.error('Failed to add to wishlist:', err);
      setError('Failed to add to wishlist. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const removeFromWishlist = async (assetId) => {
    if (!user) {
      setError('Please log in to remove items from your wishlist.');
      return;
    }
    try {
      setLoading(true);
      await api.removeFromWishlist({}, { asset_id: assetId });
      setWishlistItems(prevItems => prevItems.filter(item => item.id !== assetId));
    } catch (err) {
      console.error('Failed to remove from wishlist:', err);
      setError('Failed to remove from wishlist. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const clearWishlist = async () => {
    if (!user) {
      setError('Please log in to clear your wishlist.');
      return;
    }
    try {
      setLoading(true);
      await api.clearWishlist();
      setWishlistItems([]);
    } catch (err) {
      console.error('Failed to clear wishlist:', err);
      setError('Failed to clear wishlist. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const isInWishlist = useCallback((assetId) => {
    return wishlistItems.some(item => item && item.id === assetId);
  }, [wishlistItems]);

  const value = {
    wishlistItems,
    loading,
    error,
    addToWishlist,
    removeFromWishlist,
    clearWishlist,
    isInWishlist,
    fetchWishlist
  };

  return (
    <WishlistContext.Provider value={value}>
      {children}
    </WishlistContext.Provider>
  );
};

export default WishlistProvider;