import React from 'react';
import { motion } from 'framer-motion';
import {
  TagIcon,
  CurrencyDollarIcon,
  StarIcon,
  MapPinIcon,
  ClockIcon,
  CogIcon,
  UserGroupIcon,
  TruckIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';

// Import the formatCurrency function
import { formatCurrency } from '../../utils/formatters';

const AssetDetails = ({ asset }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.2 }}
    className="bg-white rounded-lg shadow-md p-6"
  >
    <h2 className="text-2xl font-semibold mb-4">Asset Details</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
      <DetailItem icon={<TagIcon className="h-5 w-5" />} label="Category" value={asset.category?.name || 'Uncategorized'} />
      <DetailItem icon={<ClockIcon className="h-5 w-5" />} label="Min. Rental" value={`${asset.minimum_rental_period} hour(s)`} />
      <DetailItem icon={<CurrencyDollarIcon className="h-5 w-5" />} label="Deposit" value={formatCurrency(asset.deposit_required)} />
      <DetailItem icon={<MapPinIcon className="h-5 w-5" />} label="Location" value={asset.location?.name || 'Not specified'} />
      <DetailItem icon={<CogIcon className="h-5 w-5" />} label="Condition" value={asset.condition} />
      <DetailItem icon={<UserGroupIcon className="h-5 w-5" />} label="Total Rentals" value={asset.total_rentals} />
      <DetailItem 
        icon={<StarIcon className="h-5 w-5" />} 
        label="Average Rating" 
        value={asset.average_rating ? `${asset.average_rating.toFixed(1)} / 5` : 'N/A'} 
      />
      <DetailItem icon={<TruckIcon className="h-5 w-5" />} label="Serial Number" value={asset.serial_number || 'N/A'} />
    </div>
    <LocationDetails googleMapsUrl={asset.google_maps_url} location={asset.location?.name} />
  </motion.div>
);

const DetailItem = ({ icon, label, value }) => (
  <div className="flex items-center space-x-3">
    <div className="flex-shrink-0 h-6 w-6 text-gray-400">{icon}</div>
    <div>
      <p className="text-sm font-medium text-gray-500">{label}</p>
      <p className="mt-1 text-sm font-semibold text-gray-900">{value}</p>
    </div>
  </div>
);

const LocationDetails = ({ googleMapsUrl, location }) => {
  if (!googleMapsUrl && !location) {
    return null;
  }

  const isValidGoogleMapsUrl = (url) => {
    return url.startsWith('https://www.google.com/maps') || 
           url.startsWith('https://goo.gl/maps') ||
           url.startsWith('https://maps.app.goo.gl/');
  };

  const getEmbedUrl = (url) => {
    let embedUrl = '';
    const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyDeTAZjRVi3dEYCx3HJS1GH8q1tTrZjjcA';
    
    if (url.includes('@')) {
      // URL contains coordinates
      const coords = url.split('@')[1].split(',')[0] + ',' + url.split('@')[1].split(',')[1];
      embedUrl = `https://www.google.com/maps/embed/v1/view?key=${API_KEY}&center=${coords}&zoom=15`;
    } else if (url.includes('place/')) {
      // URL contains a place ID or name
      const placeId = url.split('place/')[1].split('/')[0];
      embedUrl = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${placeId}`;
    } else if (url.includes('maps.app.goo.gl') || url.includes('goo.gl/maps')) {
      // Short URL, we'll use it as a search term
      embedUrl = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${encodeURIComponent(url)}`;
    } else {
      // Fallback to using the entire URL as a search term
      embedUrl = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${encodeURIComponent(url)}`;
    }
    
    return embedUrl;
  };

  return (
    <div className="mt-6 border-t pt-6">
      <h3 className="text-lg font-semibold mb-3 flex items-center">
        <MapPinIcon className="h-5 w-5 mr-2 text-primary" />
        Location
      </h3>
      <div className="bg-gray-100 p-4 rounded-lg">
        <p className="text-gray-600 mb-2">
          {location || 'Location details not available'}
        </p>
        {googleMapsUrl && isValidGoogleMapsUrl(googleMapsUrl) ? (
          <div>
            <div className="aspect-w-16 aspect-h-9 mt-2">
              <iframe
                src={getEmbedUrl(googleMapsUrl)}
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-lg"
              ></iframe>
            </div>
            <a
              href={googleMapsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-2 inline-flex items-center text-primary hover:text-primaryDark"
            >
              View on Google Maps
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>
          </div>
        ) : googleMapsUrl ? (
          <p className="text-sm text-gray-500">
            Invalid Google Maps URL provided. Please contact the vendor for the correct location.
          </p>
        ) : (
          <p className="text-sm text-gray-500">
            Exact location will be provided after booking.
          </p>
        )}
      </div>
    </div>
  );
};

export default AssetDetails;