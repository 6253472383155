import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn, FaUsers, FaQuestionCircle, FaFileContract, FaShieldAlt, FaListUl, FaFire, FaRegCalendarAlt, FaChevronUp } from 'react-icons/fa';
import { MdEmail, MdPhone, MdLocationOn } from 'react-icons/md';
import { BiBuildings } from 'react-icons/bi';
import logo from '../images/logos/logo_1.png';
import apiService from '../services/api';

const colors = {
  primary: 'bg-primary', // Orange background
  secondary: 'bg-secondary', // Lighter background for contrast
  accent: 'text-accent', // Bright accent color for text
  text: 'text-white', // White for main text
  muted: 'text-gray-300', // Muted text color
};

const Footer = () => {
  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      await apiService.subscribeNewsletter(email);
      setSubscriptionStatus('Subscribed successfully!');
      setEmail('');
    } catch (error) {
      setSubscriptionStatus('Subscription failed. Please try again.');
      console.error('Newsletter subscription error:', error);
    }
  };

  return (
    <footer className={`${colors.primary} ${colors.text} z-40`}>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="space-y-4">
            <div className="bg-white p-2 rounded-lg shadow-lg inline-block">
              <img className="h-10 w-auto" src={logo} alt="AliHire - Rent Anything logo" />
            </div>
            <p className={`${colors.muted} text-sm leading-relaxed`}>
              Connecting someone who needs to hire a certain item, to someone who wants to rent out such an item. Simple, Convenient, Easy!
            </p>
            <div className="flex space-x-4">
              {[ 
                { icon: FaFacebookF, href: "https://alihire.com", label: "Facebook" }, 
                { icon: FaInstagram, href: "https://alihire.com", label: "Instagram" },
                { icon: FaTwitter, href: "/about", label: "Twitter" },
                { icon: FaLinkedinIn, href: "/about", label: "LinkedIn" },
              ].map((social) => (
                <a key={social.label} href={social.href} className={`${colors.muted} hover:${colors.accent} transition-colors duration-300`} target="_blank" rel="noopener noreferrer">
                  <span className="sr-only">{social.label}</span>
                  <social.icon className="h-5 w-5" />
                </a>
              ))}
            </div>
          </div>

          {/* Service Links */}
          <div>
            <h3 className={`text-sm font-semibold ${colors.muted} uppercase mb-2`}>SERVICE</h3>
            <ul className="space-y-2">
              {[ 
                { to: "/how-it-works", icon: FaUsers, label: "How AliHire Works" },
                { to: "/faq", icon: FaQuestionCircle, label: "FAQ" },
                { to: "/contact", icon: MdEmail, label: "Contact Us" },
              ].map((item) => (
                <li key={item.to}>
                  <Link to={item.to} className={`${colors.text} hover:${colors.muted} flex items-center transition-colors duration-300 text-sm`}>
                    <item.icon className="mr-2 flex-shrink-0" />
                    <span>{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Company Links */}
          <div>
            <h3 className={`text-sm font-semibold ${colors.muted} uppercase mb-2`}>COMPANY</h3>
            <ul className="space-y-2">
              {[ 
                { to: "/about", icon: BiBuildings, label: "About Us" },
                { to: "/privacy", icon: FaShieldAlt, label: "Privacy Policy" },
                { to: "/terms", icon: FaFileContract, label: "Terms of Service" },
                { to: "/cancellation", icon: FaRegCalendarAlt, label: "Cancellation Policy" },
              ].map((item) => (
                <li key={item.to}>
                  <Link to={item.to} className={`${colors.text} hover:${colors.muted} flex items-center transition-colors duration-300 text-sm`}>
                    <item.icon className="mr-2 flex-shrink-0" />
                    <span>{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Items and Contact Info */}
          <div>
            <h3 className={`text-sm font-semibold ${colors.muted} uppercase mb-2`}>BROWSE ITEMS</h3>
            <ul className="space-y-2">
              {[ 
                { to: "/categories", icon: FaListUl, label: "Categories" },
                { to: "/popular", icon: FaFire, label: "Popular Items" },
                { to: "/new", icon: FaRegCalendarAlt, label: "New Arrivals" },
              ].map((item) => (
                <li key={item.to}>
                  <Link to={item.to} className={`${colors.text} hover:${colors.muted} flex items-center transition-colors duration-300 text-sm`}>
                    <item.icon className="mr-2 flex-shrink-0" />
                    <span>{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Newsletter Subscription */}
        <div className="mt-6 pt-4">
          <h3 className={`text-sm font-semibold ${colors.muted} uppercase mb-2`}>SUBSCRIBE TO OUR NEWSLETTER</h3>
          <form onSubmit={handleSubscribe} className="flex flex-col sm:flex-row items-center">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full sm:w-64 px-2 py-1 rounded-l-md focus:outline-none focus:ring-2 focus:ring-orange-500 text-orange-900"
              required
            />
            <button
              type="submit"
              className={`mt-2 sm:mt-0 w-full sm:w-auto ${colors.secondary} hover:bg-orange-600 text-white font-bold py-1 px-2 rounded-r-md transition duration-300`}
            >
              Subscribe
            </button>
          </form>
          {subscriptionStatus && (
            <p className={`mt-2 text-sm ${colors.muted}`}>{subscriptionStatus}</p>
          )}
        </div>

        {/* Copyright and Back to Top */}
        <div className={`mt-6 pt-4 border-t border-orange-600 flex flex-col sm:flex-row justify-between items-center`}>
          <p className={`${colors.muted} text-sm`}>
            © {new Date().getFullYear()} AliHire - Aster Azure. All Rights Reserved.
          </p>
          <button 
            onClick={scrollToTop} 
            className={`mt-2 sm:mt-0 bg-orange-600 text-white font-bold py-1 px-3 rounded-full transition duration-300 flex items-center`}
          >
            <FaChevronUp className="mr-1" />
            Back to Top
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
