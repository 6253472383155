import React, { useState, useEffect } from 'react';
import { 
  BarChart, Bar, LineChart, Line, PieChart, Pie, 
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer 
} from 'recharts';
import WebSocketService from '../services/websocket';

const AnalyticsPage = () => {
  const [revenueData, setRevenueData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [userGrowthData, setUserGrowthData] = useState([]);
  const [kpis, setKpis] = useState({
    totalRevenue: 0,
    totalOrders: 0,
    averageOrderValue: 0,
    activeUsers: 0
  });

  useEffect(() => {
    fetchRevenueData();
    fetchCategoryData();
    fetchUserGrowthData();
    fetchKPIs();

    // Connect to WebSocket
    WebSocketService.connect('ws://your-backend-url/ws');

    // Add listeners for real-time updates
    WebSocketService.addListener('kpi_update', handleKpiUpdate);
    WebSocketService.addListener('revenue_update', handleRevenueUpdate);
    WebSocketService.addListener('category_update', handleCategoryUpdate);
    WebSocketService.addListener('user_growth_update', handleUserGrowthUpdate);

    // Cleanup function
    return () => {
      WebSocketService.removeListener('kpi_update', handleKpiUpdate);
      WebSocketService.removeListener('revenue_update', handleRevenueUpdate);
      WebSocketService.removeListener('category_update', handleCategoryUpdate);
      WebSocketService.removeListener('user_growth_update', handleUserGrowthUpdate);
    };
  }, []);

  const fetchRevenueData = async () => {
    try {
      const response = await fetch('/api/admin/analytics/revenue');
      const data = await response.json();
      setRevenueData(data);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
    }
  };

  const fetchCategoryData = async () => {
    try {
      const response = await fetch('/api/admin/analytics/categories');
      const data = await response.json();
      setCategoryData(data);
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

  const fetchUserGrowthData = async () => {
    try {
      const response = await fetch('/api/admin/analytics/user-growth');
      const data = await response.json();
      setUserGrowthData(data);
    } catch (error) {
      console.error('Error fetching user growth data:', error);
    }
  };

  const fetchKPIs = async () => {
    try {
      const response = await fetch('/api/admin/analytics/kpis');
      const data = await response.json();
      setKpis(data);
    } catch (error) {
      console.error('Error fetching KPIs:', error);
    }
  };

  const handleKpiUpdate = (newKpis) => {
    setKpis(prevKpis => ({ ...prevKpis, ...newKpis }));
  };

  const handleRevenueUpdate = (newRevenueData) => {
    setRevenueData(prevData => {
      const updatedData = [...prevData];
      const index = updatedData.findIndex(item => item.date === newRevenueData.date);
      if (index !== -1) {
        updatedData[index] = newRevenueData;
      } else {
        updatedData.push(newRevenueData);
      }
      return updatedData;
    });
  };

  const handleCategoryUpdate = (newCategoryData) => {
    setCategoryData(newCategoryData);
  };

  const handleUserGrowthUpdate = (newUserGrowthData) => {
    setUserGrowthData(prevData => {
      const updatedData = [...prevData];
      const index = updatedData.findIndex(item => item.date === newUserGrowthData.date);
      if (index !== -1) {
        updatedData[index] = newUserGrowthData;
      } else {
        updatedData.push(newUserGrowthData);
      }
      return updatedData;
    });
  };

  const KPICard = ({ title, value, unit }) => (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
      <p className="text-3xl font-bold text-indigo-600 mt-2">
        {unit === '$' ? `${unit}${value.toLocaleString()}` : value.toLocaleString()}
        {unit !== '$' && unit}
      </p>
    </div>
  );

  return (
    
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Analytics Dashboard</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <KPICard title="Total Revenue" value={kpis.totalRevenue} unit="$" />
          <KPICard title="Total Orders" value={kpis.totalOrders} unit="" />
          <KPICard title="Average Order Value" value={kpis.averageOrderValue} unit="$" />
          <KPICard title="Active Users" value={kpis.activeUsers} unit="" />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Revenue Over Time</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={revenueData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="revenue" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Revenue by Category</h2>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={categoryData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                />
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div className="bg-white rounded-lg shadow-md p-4 col-span-1 lg:col-span-2">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">User Growth</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={userGrowthData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="users" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
  
  );
};

export default AnalyticsPage;