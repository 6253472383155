import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

const ErrorMessage = ({ message }) => (
  <div className="bg-red-50 border-l-4 border-red-400 p-4 my-4" role="alert" aria-live="assertive">
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className="text-sm text-red-700">
          <span className="font-medium">Error:</span> {message}
        </p>
      </div>
    </div>
  </div>
);

export default ErrorMessage;