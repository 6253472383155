import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import api from '../services/api';

const notificationTypes = [
  { id: 'system', name: 'System Notifications' },
  { id: 'user', name: 'User Notifications' },
  { id: 'order', name: 'Order Notifications' },
  { id: 'inventory', name: 'Inventory Notifications' },
];

const NotificationPreferencesPage = () => {
  const [preferences, setPreferences] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);

  useEffect(() => {
    fetchPreferences();
  }, []);

  const fetchPreferences = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get('/users/notification-preferences');
      setPreferences(response.data.preferences);
    } catch (err) {
      console.error('Error fetching notification preferences:', err);
      setError('Failed to load preferences. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const savePreferences = async () => {
    try {
      setSaveStatus('Saving...');
      await api.put('/users/notification-preferences', { preferences });
      setSaveStatus('Saved successfully!');
      setTimeout(() => setSaveStatus(null), 3000);
    } catch (err) {
      console.error('Error saving notification preferences:', err);
      setSaveStatus('Failed to save. Please try again.');
      setTimeout(() => setSaveStatus(null), 3000);
    }
  };

  const handleToggle = (typeId) => {
    setPreferences(prev => ({
      ...prev,
      [typeId]: !prev[typeId]
    }));
  };

  if (loading) {
    return (
      
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
          </div>
        </div>
      
    );
  }

  if (error) {
    return (
      
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        </div>
      
    );
  }

  return (
    
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Notification Preferences</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="space-y-6">
              {notificationTypes.map((type) => (
                <Switch.Group key={type.id} as="div" className="flex items-center justify-between">
                  <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                    <span className="text-sm font-medium text-gray-900">{type.name}</span>
                    <span className="text-sm text-gray-500">Receive notifications for {type.name.toLowerCase()}</span>
                  </Switch.Label>
                  <Switch
                    checked={preferences[type.id] || false}
                    onChange={() => handleToggle(type.id)}
                    className={`${
                      preferences[type.id] ? 'bg-primary' : 'bg-gray-200'
                    } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        preferences[type.id] ? 'translate-x-5' : 'translate-x-0'
                      } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                    />
                  </Switch>
                </Switch.Group>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end space-x-4">
          {saveStatus && (
            <p className={`text-sm ${saveStatus === 'Saved successfully!' ? 'text-green-600' : 'text-red-600'}`}>
              {saveStatus}
            </p>
          )}
          <button
            onClick={savePreferences}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Save Preferences
          </button>
        </div>
      </div>
    
  );
};

export default NotificationPreferencesPage;