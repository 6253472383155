import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { ShoppingCart, Search, User, Calendar, DollarSign, Clock, Tag } from 'lucide-react';
import { format, isValid, parseISO } from 'date-fns';
import apiService from '../../services/api';
import Spinner from '../../components/Spinner';
import ErrorMessage from '../../components/ErrorMessage';
import { motion, AnimatePresence } from 'framer-motion';

const CartManagement = () => {
  const [cartData, setCartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCartDetailsDialogOpen, setIsCartDetailsDialogOpen] = useState(false);
  const [selectedCartItem, setSelectedCartItem] = useState(null);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-KE', { style: 'currency', currency: 'KES' }).format(amount);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'PPP') : 'Invalid Date';
  };

  const fetchCartData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await apiService.getCart({ search: searchTerm });
      console.log('API Response:', response);

      if (response && response.data) {
        setCartData(response.data);
      } else {
        console.error('Unexpected response structure:', response);
        setError('Unexpected response structure received from the server.');
        setCartData(null);
      }
    } catch (error) {
      console.error('Failed to fetch cart data:', error);
      setError('Failed to fetch cart data. Please try again later.');
      setCartData(null);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchCartData();
  }, [fetchCartData]);

  const handleViewCartDetails = (cartItem) => {
    setSelectedCartItem(cartItem);
    setIsCartDetailsDialogOpen(true);
  };

  const getUsername = (item) => {
    if (item.cart && item.cart.user && item.cart.user.username) {
      return item.cart.user.username;
    }
    if (cartData && cartData.user && cartData.user.username) {
      return cartData.user.username;
    }
    return 'Anonymous';
  };

  const renderCartDetails = (cartItem) => {
    if (!cartItem) return null;
    return (
      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <ShoppingCart className="h-5 w-5 text-gray-500" />
          <p><strong>Item ID:</strong> {cartItem.id}</p>
        </div>
        <div className="flex items-center space-x-2">
          <User className="h-5 w-5 text-gray-500" />
          <p><strong>User:</strong> {getUsername(cartItem)}</p>
        </div>
        <div className="flex items-center space-x-2">
          <Tag className="h-5 w-5 text-gray-500" />
          <p><strong>Asset:</strong> {cartItem.asset.name}</p>
        </div>
        <div className="flex items-center space-x-2">
          <DollarSign className="h-5 w-5 text-gray-500" />
          <p><strong>Price:</strong> {formatCurrency(cartItem.price)}</p>
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="h-5 w-5 text-gray-500" />
          <p><strong>Hours:</strong> {cartItem.hours}</p>
        </div>
        <p><strong>Quantity:</strong> {cartItem.quantity}</p>
        <p><strong>Subtotal:</strong> {formatCurrency(cartItem.subtotal)}</p>
        <p><strong>Hourly Rate:</strong> {formatCurrency(cartItem.hourly_rate || cartItem.asset.hourly_rate)}</p>
        {cartItem.asset.category && (
          <p><strong>Category:</strong> {cartItem.asset.category.name}</p>
        )}
      </div>
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!cartData || !cartData.items || cartData.items.length === 0) {
    return (
      <div className="text-center py-10">
        <ShoppingCart className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No items in cart</h3>
        <p className="mt-1 text-sm text-gray-500">There are currently no items in the cart.</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold flex items-center">
            <ShoppingCart className="mr-2 h-6 w-6" />
            Cart Management
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex justify-between items-center mb-6">
            <div className="relative w-full max-w-md">
              <Input
                placeholder="Search carts..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
            <Button onClick={fetchCartData} className="bg-primary text-white">
              Refresh
            </Button>
          </div>
          <div className="overflow-x-auto rounded-lg border border-gray-200">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>User</TableHead>
                  <TableHead>Asset</TableHead>
                  <TableHead>Quantity</TableHead>
                  <TableHead>Hours</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Subtotal</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <AnimatePresence>
                  {cartData.items.map((item) => (
                    <motion.tr
                      key={item.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      className="hover:bg-gray-50"
                    >
                      <TableCell className="font-medium">{getUsername(item)}</TableCell>
                      <TableCell>{item.asset.name}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>{item.hours}</TableCell>
                      <TableCell>{formatCurrency(item.price)}</TableCell>
                      <TableCell>{formatCurrency(item.subtotal)}</TableCell>
                      <TableCell>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleViewCartDetails(item)}
                        >
                          View Details
                        </Button>
                      </TableCell>
                    </motion.tr>
                  ))}
                </AnimatePresence>
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>

      <Dialog open={isCartDetailsDialogOpen} onOpenChange={setIsCartDetailsDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Cart Item Details</DialogTitle>
          </DialogHeader>
          {selectedCartItem && renderCartDetails(selectedCartItem)}
          <DialogFooter>
            <Button onClick={() => setIsCartDetailsDialogOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader>
          <CardTitle className="text-xl font-semibold">Cart Statistics</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-blue-100 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-blue-800">Total Items</h3>
              <p className="text-2xl font-bold text-blue-900">{cartData.total_items}</p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-green-800">Total Value</h3>
              <p className="text-2xl font-bold text-green-900">
                {formatCurrency(cartData.total_price)}
              </p>
            </div>
            <div className="bg-purple-100 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-purple-800">Created At</h3>
              <p className="text-2xl font-bold text-purple-900">
                {formatDate(cartData.created_at)}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CartManagement;