import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import zxcvbn from 'zxcvbn';
import { motion } from 'framer-motion';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const { confirmResetPassword } = useAuth();

  useEffect(() => {
    if (!uidb64 || !token) {
      toast.error("Invalid password reset link.");
      navigate('/forgot-password');
    }
  }, [uidb64, token, navigate]);

  const handlePasswordChange = useCallback((e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(zxcvbn(newPassword).score);
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords don't match.");
      return;
    }
    if (passwordStrength < 3) {
      toast.error("Please choose a stronger password.");
      return;
    }
    setIsLoading(true);
    try {
      await confirmResetPassword(uidb64, token, password);
      toast.success("Password reset successfully. You can now login with your new password.");
      navigate('/login');
    } catch (error) {
      console.error('Failed to reset password:', error);
      toast.error(error.response?.data?.error || "Failed to reset password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [password, confirmPassword, passwordStrength, uidb64, token, confirmResetPassword, navigate]);



  const getPasswordStrengthColor = useCallback(() => {
    const colors = ['bg-red-500', 'bg-orange-500', 'bg-yellow-500', 'bg-green-500', 'bg-green-600'];
    return colors[passwordStrength];
  }, [passwordStrength]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset your password</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <PasswordInput
              id="password"
              placeholder="New Password"
              value={password}
              onChange={handlePasswordChange}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <PasswordInput
              id="confirm-password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              showPassword={showConfirmPassword}
              setShowPassword={setShowConfirmPassword}
            />
          </div>

          <PasswordStrengthMeter strength={passwordStrength} getColor={getPasswordStrengthColor} />

          <div>
            <motion.button
              type="submit"
              disabled={isLoading}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isLoading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Resetting...
                </span>
              ) : (
                'Reset Password'
              )}
            </motion.button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

const PasswordInput = ({ id, placeholder, value, onChange, showPassword, setShowPassword }) => (
  <div className="relative">
    <label htmlFor={id} className="sr-only">{placeholder}</label>
    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <FaLock className="h-5 w-5 text-gray-400" />
    </div>
    <input
      id={id}
      name={id}
      type={showPassword ? "text" : "password"}
      autoComplete="new-password"
      required
      className="appearance-none rounded-md relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    <button
      type="button"
      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
      onClick={() => setShowPassword(!showPassword)}
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </button>
  </div>
);

const PasswordStrengthMeter = ({ strength, getColor }) => (
  <div>
    <div className="flex mb-2">
      {[...Array(4)].map((_, index) => (
        <div key={index} className={`h-2 w-1/4 ${strength >= index + 1 ? getColor() : 'bg-gray-200'}`}></div>
      ))}
    </div>
    <p className="text-xs text-gray-600">
      {strength === 0 && "Very weak"}
      {strength === 1 && "Weak"}
      {strength === 2 && "Fair"}
      {strength === 3 && "Strong"}
      {strength === 4 && "Very strong"}
    </p>
  </div>
);

export default ResetPasswordPage;