import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { MagnifyingGlassIcon, MapPinIcon, CalendarIcon, CubeIcon } from '@heroicons/react/24/outline';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { getCategories, getLocations, getAssets } from '../services/api';
import AssetCard from '../components/AssetCard';

const LandingPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState({
    search: '',
    location: '',
    category: '',
    pickup_date: null,
    return_date: null,
    minPrice: '',
    maxPrice: '',
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState({ assets: false, locations: false, categories: false });
  const [error, setError] = useState({ assets: null, locations: null, categories: null });
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const featuredItemsRef = useRef(null); // Ref for the featured items section

  const fetchLocations = useCallback(async () => {
    setLoading(prev => ({ ...prev, locations: true }));
    setError(prev => ({ ...prev, locations: null }));
    try {
      const response = await getLocations();
      if (response.data && Array.isArray(response.data.results)) {
        setLocations(response.data.results);
      } else {
        throw new Error('Unexpected locations data structure');
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      setError(prev => ({ ...prev, locations: 'Failed to load locations. Please try again later.' }));
      toast.error('Failed to load locations. Please try again later.');
    } finally {
      setLoading(prev => ({ ...prev, locations: false }));
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    setLoading(prev => ({ ...prev, categories: true }));
    setError(prev => ({ ...prev, categories: null }));
    try {
      const response = await getCategories();
      if (response.data && Array.isArray(response.data.results)) {
        setCategories(response.data.results);
      } else {
        throw new Error('Unexpected categories data structure');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError(prev => ({ ...prev, categories: 'Failed to load categories. Please try again later.' }));
      toast.error('Failed to load categories. Please try again later.');
    } finally {
      setLoading(prev => ({ ...prev, categories: false }));
    }
  }, []);

  const fetchAssets = useCallback(async (categorySlug = '', page = 1) => {
    setLoading(prev => ({ ...prev, assets: true }));
    setError(prev => ({ ...prev, assets: null }));
    try {
      const response = await getAssets({
        category: categorySlug,
        page,
        limit: 8,
        featured: true,
        search: searchParams.search || undefined,
        location: searchParams.location || undefined,
        min_price: searchParams.minPrice || undefined,
        max_price: searchParams.maxPrice || undefined,
        pickup_date: searchParams.pickup_date ? searchParams.pickup_date.toISOString() : undefined,
        return_date: searchParams.return_date ? searchParams.return_date.toISOString() : undefined,
      });
      setAssets(response.data.results || []);
      setTotalPages(Math.ceil(response.data.count / 8));
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching assets:', error);
      setError(prev => ({ ...prev, assets: 'Failed to fetch assets. Please try again later.' }));
      toast.error('Failed to fetch assets. Please try again later.');
    } finally {
      setLoading(prev => ({ ...prev, assets: false }));
    }
  }, [searchParams]);

  useEffect(() => {
    fetchLocations();
    fetchCategories();
    fetchAssets();
  }, [fetchLocations, fetchCategories]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setSearchParams(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleDateChange = useCallback((date, name) => {
    setSearchParams(prev => ({ ...prev, [name]: date }));
  }, []);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const formattedParams = new URLSearchParams();
    Object.entries(searchParams).forEach(([key, value]) => {
      if (value) {
        formattedParams.append(key, value instanceof Date ? value.toISOString() : value);
      }
    });
    navigate(`/search-results?${formattedParams.toString()}`);
  }, [navigate, searchParams]);

  const handleCategoryClick = useCallback((category) => {
    setSelectedCategory(category);
    setSearchParams(prev => ({ ...prev, category: category.slug }));
    fetchAssets(category.slug, 1);
    
    // Add a delay of 1 second before performing the scroll action
    setTimeout(() => {
      featuredItemsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start' // Scrolls to the top of the element
      });
    }, 1000); // 1 second delay
  }, [fetchAssets]);

  const handlePageChange = useCallback((newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      fetchAssets(selectedCategory?.slug || '', newPage);
    }
  }, [fetchAssets, selectedCategory, totalPages]);

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
<div className="bg-gradient-to-r from-primary to-primary-dark text-white py-16 px-4 sm:px-6 lg:px-8">
  <div className="max-w-7xl mx-auto">
    <h1 className="text-3xl sm:text-3xl md:text-4xl font-extrabold mb-4">
      Rent Anything!
    </h1>
    <p className="text-lg sm:text-xl mb-8">
      Hire exactly what you need, it's so easy and convenient.
    </p>
    <form onSubmit={handleSearch} className="bg-white rounded-lg shadow-md p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {['search', 'location', 'category'].map((field) => (
          <div key={field} className="relative">
            {field === 'search' && (
              <>
                <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
                <input
                  type="text"
                  name={field}
                  value={searchParams[field]}
                  onChange={handleInputChange}
                  className="h-12 w-full pl-10 pr-3 text-sm rounded-md border border-gray-300 text-gray-700 placeholder-gray-500 focus:ring-2 focus:ring-primary focus:border-primary"
                  placeholder="Search for something..."
                />
              </>
            )}
            {field === 'location' && (
              <>
                <MapPinIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
                <select
                  name={field}
                  value={searchParams[field]}
                  onChange={handleInputChange}
                  className="h-12 w-full pl-10 pr-3 text-sm rounded-md border border-gray-300 text-gray-700 focus:ring-2 focus:ring-primary focus:border-primary"
                >
                  <option value="">All locations</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.slug}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </>
            )}
            {field === 'category' && (
              <>
                <CubeIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
                <select
                  name={field}
                  value={searchParams[field]}
                  onChange={handleInputChange}
                  className="h-12 w-full pl-10 pr-3 text-sm rounded-md border border-gray-300 text-gray-700 focus:ring-2 focus:ring-primary focus:border-primary"
                >
                  <option value="">All categories</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.slug}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
        ))}
        
        {/* Grouping Pickup and Return Date Fields */}
        <div className="flex space-x-4">
          <div className="relative w-full">
            <CalendarIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
            <DatePicker
              selected={searchParams.pickup_date}
              onChange={(date) => handleDateChange(date, 'pickup_date')}
              className="h-12 w-full pl-1 pr-1 text-sm text-center rounded-md border border-gray-300 text-gray-700 focus:ring-2 focus:ring-primary focus:border-primary"
              placeholderText="Pick-up Date"
              dateFormat="yyyy-MM-dd"
              minDate={new Date()} // Current date and future dates
            />
          </div>
          <div className="relative w-full">
            <CalendarIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-600" />
            <DatePicker
              selected={searchParams.return_date}
              onChange={(date) => handleDateChange(date, 'return_date')}
              className="h-12 w-full pl-1 pr-1 text-sm text-center rounded-md border border-gray-300 text-gray-700 focus:ring-2 focus:ring-primary focus:border-primary"
              placeholderText="Return Date"
              dateFormat="yyyy-MM-dd"
              minDate={new Date()} // Current date and future dates
            />
          </div>
        </div>
        
        {/* Grouping Min and Max Price Fields */}
        <div className="flex space-x-4">
          <div className="relative w-full">
            <input
              type="number"
              name="minPrice"
              value={searchParams.minPrice}
              onChange={handleInputChange}
              className="h-12 w-full pl-1 pr-1 text-sm text-center rounded-md border border-gray-300 text-gray-700 focus:ring-2 focus:ring-primary focus:border-primary"
              placeholder="Min Price"
            />
          </div>
          <div className="relative w-full">
            <input
              type="number"
              name="maxPrice"
              value={searchParams.maxPrice}
              onChange={handleInputChange}
              className="h-12 w-full pl-1 pr-1 text-sm text-center rounded-md border border-gray-300 text-gray-700 focus:ring-2 focus:ring-primary focus:border-primary"
              placeholder="Max Price"
            />
          </div>
        </div>

        <div className="flex space-x-4">
  <button
    type="submit"
    className="h-12 w-full pl-1 pr-1 bg-primary text-white rounded-md shadow hover:bg-primary-dark transition"
  >
    Find
  </button>
</div>

      </div>
    </form>
  </div>
</div>


{/* Categories Section */}
<div className="py-12">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <h2 className="text-2xl font-bold mb-6">Featured Items Per Category</h2>
    <div className="grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-9 gap-4 mb-2">
      {categories.map((category) => (
        <div
          key={category.id}
          className="bg-white rounded-lg shadow-md p-2 cursor-pointer hover:bg-gray-100 transition"
          onClick={() => handleCategoryClick(category)}
        >
          <div className="flex items-center justify-center h-full">
            <h3 className="text-center text-xs sm:text-sm md:text-sm lg:text-sm">
              {category.name}
            </h3>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>

      {/* Featured Items Section */}
      <div ref={featuredItemsRef} className="pb-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-md font-bold mb-6">Featured Items</h2>
          {loading.assets ? (
            <p>Loading...</p>
          ) : error.assets ? (
            <p className="text-red-500">{error.assets}</p>
          ) : assets.length > 0 ? (
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
              {assets.map((asset) => (
                <AssetCard key={asset.id} asset={asset} />
              ))}
            </div>
          ) : (
            <p>No featured items found.</p>
          )}
        </div>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center my-8">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="mx-2 bg-gray-300 text-gray-700 rounded px-4 py-2 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="mx-2">{currentPage} of {totalPages}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="mx-2 bg-gray-300 text-gray-700 rounded px-4 py-2 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
