import React, { useState } from 'react';
import { MdEmail, MdPhone, MdLocationOn } from 'react-icons/md';
import { toast } from 'react-toastify';
import apiService from '../services/api';

// Color scheme
const colors = {
  primary: 'bg-orange-600 hover:bg-orange-700 text-white',
  secondary: 'bg-orange-500 hover:bg-orange-600 text-white',
  accent: 'text-orange-600',
  background: 'bg-gray-100', // Light grey background
  text: 'text-gray-800', // Dark text for contrast
  muted: 'text-gray-600', // Slightly lighter text for secondary elements
  inputFocus: 'focus:ring-orange-500 focus:border-orange-500',
  card: 'bg-white', // White background for cards
};

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await apiService.submitContactForm({}, formData);
      console.log('Contact form submission response:', response);
      toast.success('Message sent successfully!');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      console.error('Error submitting contact form:', error);
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);

        if (error.response.data) {
          const errorMessages = Object.values(error.response.data).flat();
          errorMessages.forEach(message => toast.error(message));
        } else {
          toast.error(`Error: ${error.response.status} - ${error.response.statusText}`);
        }
      } else if (error.request) {
        console.error('Error request:', error.request);
        toast.error('No response received from the server. Please try again.');
      } else {
        console.error('Error message:', error.message);
        toast.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`${colors.background} min-h-screen py-12 px-4 sm:px-6 lg:px-8`}>
      <div className="max-w-7xl mx-auto">
        <h1 className={`text-4xl font-bold text-center ${colors.text} mb-12`}>Contact Us</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h2 className={`text-2xl font-semibold ${colors.text} mb-4`}>Get in Touch</h2>
                <p className={`${colors.muted} mb-4`}>
                  We'd love to hear from you. Please fill out this form and we will get in touch with you shortly.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="name" className={`block ${colors.text} font-bold mb-2`}>Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none ${colors.inputFocus}`}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className={`block ${colors.text} font-bold mb-2`}>Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none ${colors.inputFocus}`}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="subject" className={`block ${colors.text} font-bold mb-2`}>Subject</label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none ${colors.inputFocus}`}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="message" className={`block ${colors.text} font-bold mb-2`}>Message</label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none ${colors.inputFocus}`}
                      required
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className={`w-full ${colors.primary} font-bold py-2 px-4 rounded-md transition duration-300 disabled:opacity-50`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </button>
                </form>
              </div>
              <div>
                <h2 className={`text-2xl font-semibold ${colors.text} mb-4`}>Contact Information</h2>
                <div className="space-y-4">
                  <p className={`flex items-center ${colors.muted}`}>
                    <MdLocationOn className={`mr-2 ${colors.accent}`} size={20} />
                    Our Coverage: Republic of Kenya
                  </p>
                  <p className={`flex items-center ${colors.muted}`}>
                    <MdPhone className={`mr-2 ${colors.accent}`} size={20} />
                    Phone Number: +254724812413
                  </p>
                  <p className={`flex items-center ${colors.muted}`}>
                    <MdEmail className={`mr-2 ${colors.accent}`} size={20} />
                    Email: info.alihire@gmail.com
                  </p>
                </div>
                <div className="mt-8">
                  <h3 className={`text-xl font-semibold ${colors.text} mb-4`}>Office Hours</h3>
                  <ul className={`space-y-2 ${colors.muted}`}>
                    <li>Monday - Friday: 9:00 AM - 5:00 PM</li>
                    <li>Saturday: 10:00 AM - 2:00 PM</li>
                    <li>Sunday: Closed</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
