import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Input } from "../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../components/ui/dialog";
import { toast } from "../../components/ui/use-toast";
import * as Icons from 'lucide-react';
import { useToast } from '../../components/ui/use-toast'
import apiService from '../../services/api';
import { formatCurrency, formatDate } from '../../utils/formatters';
import Spinner from '../../components/Spinner';

const RewardsContent = ({ onRefresh, initialTab = 'overview' }) => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [affiliateData, setAffiliateData] = useState(null);
  const [loyaltyAccount, setLoyaltyAccount] = useState(null);
  const [rewards, setRewards] = useState([]);
  const [pointsTransactions, setPointsTransactions] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [promotionalCampaigns, setPromotionalCampaigns] = useState([]);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [copiedCode, setCopiedCode] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const fetchRewardsData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [
        affiliateResponse,
        loyaltyAccountResponse,
        rewardsResponse,
        transactionsResponse,
        referralsResponse,
        couponsResponse,
        campaignsResponse
      ] = await Promise.all([
        apiService.getAffiliateData(),
        apiService.getLoyaltyAccount(),
        apiService.getRewards(),
        apiService.getPointsTransactions(),
        apiService.getReferrals(),
        apiService.getCoupons(),
        apiService.getPromotionalCampaigns()
      ]);
      setAffiliateData(affiliateResponse);
      setLoyaltyAccount(loyaltyAccountResponse);
      setRewards(rewardsResponse);
      setPointsTransactions(transactionsResponse);
      setReferrals(referralsResponse);
      setCoupons(Array.isArray(couponsResponse) ? couponsResponse : []);
      setPromotionalCampaigns(campaignsResponse);
    } catch (err) {
      console.error('Error fetching rewards data:', err);
      setError('Failed to load rewards data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRewardsData();
  }, [fetchRewardsData]);

  const handleRedeemReward = async (rewardId) => {
    setLoading(true);
    setError(null);
    try {
      await apiService.redeemReward(rewardId);
      await fetchRewardsData();
      onRefresh();
      toast({
        title: "Reward Redeemed",
        description: "Your reward has been successfully redeemed.",
        variant: "success",
      });
    } catch (err) {
      console.error('Error redeeming reward:', err);
      toast({
        title: "Error",
        description: "Failed to redeem reward. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    setCopiedCode(code);
    setTimeout(() => setCopiedCode(null), 2000);
    toast({
      title: "Code Copied",
      description: "The coupon code has been copied to your clipboard.",
      variant: "success",
    });
  };

  const handleGenerateReferralLink = async () => {
    try {
      const response = await apiService.generateReferralLink();
      setAffiliateData(prevData => ({ ...prevData, referral_link: response.referral_link }));
      toast({
        title: "Referral Link Generated",
        description: "Your new referral link has been generated successfully.",
        variant: "success",
      });
    } catch (err) {
      console.error('Error generating referral link:', err);
      toast({
        title: "Error",
        description: "Failed to generate referral link. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleGenerateCampaignReport = async (campaignId) => {
    setIsGeneratingReport(true);
    try {
      await apiService.generateCampaignReport(campaignId);
      toast({
        title: "Report Generation Initiated",
        description: "The campaign report is being generated and will be sent to your email.",
        variant: "success",
      });
    } catch (err) {
      console.error('Error generating campaign report:', err);
      toast({
        title: "Error",
        description: "Failed to generate campaign report. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsGeneratingReport(false);
    }
  };

  const filteredCoupons = coupons.filter(coupon => 
    coupon.code.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (filterType === 'all' || coupon.discount_type === filterType)
  );

  const renderOverviewTab = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <Card>
        <CardHeader>
          <CardTitle>Loyalty Account</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <p><strong>Points Balance:</strong> {loyaltyAccount?.points_balance || 0}</p>
            <p><strong>Lifetime Points:</strong> {loyaltyAccount?.lifetime_points || 0}</p>
            <p><strong>Tier:</strong> {loyaltyAccount?.tier || 'N/A'}</p>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Affiliate Program</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <p><strong>Affiliate Code:</strong> {affiliateData?.affiliate_code || 'N/A'}</p>
            <p><strong>Commission Rate:</strong> {affiliateData?.commission_rate || 0}%</p>
            <p><strong>Total Earnings:</strong> {formatCurrency(affiliateData?.total_earnings || 0)}</p>
            <p><strong>Referral Link:</strong> {affiliateData?.referral_link || 'Not generated'}</p>
            {!affiliateData?.referral_link && (
              <Button onClick={handleGenerateReferralLink}>
                Generate Referral Link
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderRewardsTab = () => (
    <Card>
      <CardHeader>
        <CardTitle>Available Rewards</CardTitle>
      </CardHeader>
      <CardContent>
        {rewards.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Reward</TableHead>
                <TableHead>Points Required</TableHead>
                <TableHead>Quantity Available</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {rewards.map((reward) => (
                <TableRow key={reward.id}>
                  <TableCell>{reward.name}</TableCell>
                  <TableCell>{reward.points_required}</TableCell>
                  <TableCell>{reward.quantity_available}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleRedeemReward(reward.id)}
                      disabled={loyaltyAccount?.points_balance < reward.points_required || reward.quantity_available === 0}
                    >
                      Redeem
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Alert>
            <AlertTitle>No rewards available</AlertTitle>
            <AlertDescription>
              There are currently no rewards available. Check back later for new offers!
            </AlertDescription>
          </Alert>
        )}
      </CardContent>
    </Card>
  );

  const renderPointsHistoryTab = () => (
    <Card>
      <CardHeader>
        <CardTitle>Points Transaction History</CardTitle>
      </CardHeader>
      <CardContent>
        {pointsTransactions.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Description</TableHead>
                <TableHead>Points</TableHead>
                <TableHead>Type</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {pointsTransactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{formatDate(transaction.created_at)}</TableCell>
                  <TableCell>{transaction.description}</TableCell>
                  <TableCell className={transaction.amount > 0 ? 'text-green-600' : 'text-red-600'}>
                    {transaction.amount > 0 ? '+' : ''}{transaction.amount}
                  </TableCell>
                  <TableCell>{transaction.transaction_type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Alert>
            <AlertTitle>No transactions recorded</AlertTitle>
            <AlertDescription>
              You haven't made any point transactions yet. Start earning points by making purchases or referrals!
            </AlertDescription>
          </Alert>
        )}
      </CardContent>
    </Card>
  );

  const renderReferralsTab = () => (
    <Card>
      <CardHeader>
        <CardTitle>Your Referrals</CardTitle>
      </CardHeader>
      <CardContent>
        {referrals.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Referred User</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Reward Points</TableHead>
                <TableHead>Commission</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {referrals.map((referral) => (
                <TableRow key={referral.id}>
                  <TableCell>{formatDate(referral.created_at)}</TableCell>
                  <TableCell>{referral.referred_user.username}</TableCell>
                  <TableCell>{referral.status}</TableCell>
                  <TableCell>{referral.reward_points}</TableCell>
                  <TableCell>{formatCurrency(referral.first_rental_commission)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Alert>
            <AlertTitle>No referrals yet</AlertTitle>
            <AlertDescription>
              You haven't made any referrals yet. Start sharing your referral link to earn rewards!
            </AlertDescription>
          </Alert>
        )}
        <div className="mt-4">
          <h4 className="font-semibold mb-2">Share Your Referral Link</h4>
          <div className="flex items-center space-x-2">
            <Input
              value={affiliateData?.referral_link || ''}
              readOnly
            />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(affiliateData?.referral_link || '');
                toast({
                  title: "Referral Link Copied",
                  description: "Your referral link has been copied to the clipboard.",
                  variant: "success",
                });
              }}
            >
              <Icons.Copy className="w-4 h-4 mr-2" />
              Copy
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const renderCouponsTab = () => (
    <Card>
      <CardHeader>
        <CardTitle>Available Coupons</CardTitle>
        <CardDescription>Use these coupons to get discounts on your rentals</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mb-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
          <Input
            placeholder="Search coupons..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow"
          />
          <Select value={filterType} onValueChange={setFilterType}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filter by type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Types</SelectItem>
              <SelectItem value="percentage">Percentage</SelectItem>
              <SelectItem value="fixed">Fixed Amount</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {filteredCoupons.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Code</TableHead>
                <TableHead>Discount</TableHead>
                <TableHead>Valid Until</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredCoupons.map((coupon) => (
                <TableRow key={coupon.id}>
                  <TableCell>{coupon.code}</TableCell>
                  <TableCell>
                    {coupon.discount_type === 'percentage'
                      ? `${coupon.discount_value}%`
                      : formatCurrency(coupon.discount_value)}
                  </TableCell>
                  <TableCell>{formatDate(coupon.valid_to)}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleCopyCode(coupon.code)}
                      variant={copiedCode === coupon.code ? "success" : "default"}
                    >
                      {copiedCode === coupon.code ? (
                        <>
                          <Icons.Check className="w-4 h-4 mr-2" />
                          Copied!
                        </>
                      ) : (
                        <>
                          <Icons.Copy className="w-4 h-4 mr-2" />
                          Copy Code
                        </>
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Alert>
            <AlertTitle>No coupons found</AlertTitle>
            <AlertDescription>
              {searchTerm || filterType !== 'all'
                ? "No coupons match your search or filter criteria. Try adjusting your search."
                : "There are no coupons available at the moment. Check back later for new offers!"}
            </AlertDescription>
          </Alert>
        )}
      </CardContent>
    </Card>
  );

  const renderPromotionalCampaignsTab = () => (
    <Card>
      <CardHeader>
        <CardTitle>Promotional Campaigns</CardTitle>
        <CardDescription>View active promotional campaigns and their details</CardDescription>
      </CardHeader>
      <CardContent>
        {promotionalCampaigns.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Start Date</TableHead>
                <TableHead>End Date</TableHead>
                <TableHead>Discount</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {promotionalCampaigns.map((campaign) => (
                <TableRow key={campaign.id}>
                  <TableCell>{campaign.name}</TableCell>
                  <TableCell>{formatDate(campaign.start_date)}</TableCell>
                  <TableCell>{formatDate(campaign.end_date)}</TableCell>
                  <TableCell>
                    {campaign.discount_type === 'percentage'
                      ? `${campaign.discount_value}%`
                      : formatCurrency(campaign.discount_value)}
                  </TableCell>
                  <TableCell>
                    <span className={`px-2 py-1 rounded-full text-xs font-semibold ${campaign.is_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                      {campaign.is_active ? 'Active' : 'Inactive'}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button variant="outline" size="sm">
                          <Icons.FileText className="w-4 h-4 mr-2" />
                          Generate Report
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Generate Campaign Report</DialogTitle>
                          <DialogDescription>
                            Are you sure you want to generate a report for this campaign?
                          </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                          <Button variant="outline" onClick={() => {}}>Cancel</Button>
                          <Button onClick={() => handleGenerateCampaignReport(campaign.id)} disabled={isGeneratingReport}>
                            {isGeneratingReport ? (
                              <>
                                <Spinner size="small" className="mr-2" />
                                Generating...
                              </>
                            ) : (
                              <>
                                <Icons.FileText className="w-4 h-4 mr-2" />
                                Generate
                              </>
                            )}
                          </Button>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Alert>
            <AlertTitle>No promotional campaigns</AlertTitle>
            <AlertDescription>
              There are no active promotional campaigns at the moment. Check back later for new promotions!
            </AlertDescription>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
  
  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Spinner size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Rewards & Loyalty Program</CardTitle>
          <CardDescription>
            Manage your rewards, points, referrals, and view promotional campaigns
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-6">
              <TabsTrigger value="overview">Overview</TabsTrigger>
              <TabsTrigger value="rewards">Rewards</TabsTrigger>
              <TabsTrigger value="points">Points History</TabsTrigger>
              <TabsTrigger value="referrals">Referrals</TabsTrigger>
              <TabsTrigger value="coupons">Coupons</TabsTrigger>
              <TabsTrigger value="campaigns">Campaigns</TabsTrigger>
            </TabsList>
            <TabsContent value="overview">{renderOverviewTab()}</TabsContent>
            <TabsContent value="rewards">{renderRewardsTab()}</TabsContent>
            <TabsContent value="points">{renderPointsHistoryTab()}</TabsContent>
            <TabsContent value="referrals">{renderReferralsTab()}</TabsContent>
            <TabsContent value="coupons">{renderCouponsTab()}</TabsContent>
            <TabsContent value="campaigns">{renderPromotionalCampaignsTab()}</TabsContent>
          </Tabs>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Loyalty Program Benefits</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="list-disc list-inside space-y-2">
            <li>Earn {loyaltyAccount?.program?.points_per_dollar || 1} point for every {formatCurrency(100)} spent on rentals</li>
            <li>Redeem points for discounts on future rentals</li>
            <li>Exclusive access to special promotions and events</li>
            <li>Tier-based benefits: The more you rent, the more you earn!</li>
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Affiliate Program</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="mb-4">
            Earn extra rewards by referring friends and family to our platform!
          </p>
          <ul className="list-disc list-inside space-y-2">
            <li>Earn {affiliateData?.commission_rate}% commission on referred users' first rental</li>
            <li>Receive bonus points for each successful referral</li>
            <li>Track your earnings and referrals in real-time</li>
          </ul>
          <Button className="mt-4" onClick={() => setActiveTab('referrals')}>
            View Referral Details
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default RewardsContent;