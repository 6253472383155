import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { motion } from 'framer-motion';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Switch } from '@headlessui/react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getCategories, getLocations } from '../services/api';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';

const FilterForm = ({ onFilterChange, initialFilters = {} }) => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { register, handleSubmit, control, reset, watch, setValue, formState: { errors } } = useForm({
    defaultValues: initialFilters
  });
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  const pickupDate = watch('pickupDate');

  useEffect(() => {
    const fetchFilterData = async () => {
      setIsLoading(true);
      try {
        const [categoriesResponse, locationsResponse] = await Promise.all([
          getCategories(),
          getLocations()
        ]);

        setCategories(categoriesResponse.data?.results || []);
        setLocations(locationsResponse.data?.results || []);
      } catch (error) {
        console.error('Error fetching filter data:', error);
        setFetchError('Failed to load filter options. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchFilterData();
  }, []);

  useEffect(() => {
    // Set initial values when initialFilters change
    Object.entries(initialFilters).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [initialFilters, setValue]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await onFilterChange(data);
    } catch (error) {
      console.error('Error applying filters:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    reset();
    onFilterChange({});
  };

  if (fetchError) {
    return <ErrorMessage message={fetchError} />;
  }

  return (
    <motion.form
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      onSubmit={handleSubmit(onSubmit)}
      className="bg-white shadow-md rounded-lg p-6 mb-8"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div>
          <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-1">
            Search
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              {...register("search", { maxLength: { value: 100, message: "Search query is too long" } })}
              className="focus:ring-primary focus:border-primary block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
              placeholder="Search assets"
            />
          </div>
          {errors.search && <p className="mt-1 text-sm text-red-600">{errors.search.message}</p>}
        </div>

        <div>
        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
            Category
          </label>
          <select
            {...register("category")}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
            Location
          </label>
          <select
            {...register("location")}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
          >
            <option value="">All Locations</option>
            {locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="min_hourly_rate" className="block text-sm font-medium text-gray-700 mb-1">
            Min Hourly Rate
          </label>
          <input
            type="number"
            {...register("min_hourly_rate", { 
              min: { value: 0, message: "Minimum rate cannot be negative" },
              validate: (value) => !value || parseFloat(value) <= parseFloat(watch("max_hourly_rate")) || "Min rate should be less than max rate"
            })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
            placeholder="0"
          />
          {errors.min_hourly_rate && <p className="mt-1 text-sm text-red-600">{errors.min_hourly_rate.message}</p>}
        </div>
      </div>

      <div className="mt-4 flex items-center">
        <Switch
          checked={showAdvanced}
          onChange={setShowAdvanced}
          className={`${
            showAdvanced ? 'bg-primary' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
        >
          <span className="sr-only">Show advanced filters</span>
          <span
            className={`${
              showAdvanced ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
          />
        </Switch>
        <span className="ml-2 text-sm text-gray-600">Advanced Filters</span>
      </div>

      {showAdvanced && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
          className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
        >
          <div>
            <label htmlFor="max_hourly_rate" className="block text-sm font-medium text-gray-700 mb-1">
              Max Hourly Rate
            </label>
            <input
              type="number"
              {...register("max_hourly_rate", { 
                min: { value: 0, message: "Maximum rate cannot be negative" },
                validate: (value) => !value || parseFloat(value) >= parseFloat(watch("min_hourly_rate")) || "Max rate should be greater than min rate"
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
              placeholder="1000"
            />
            {errors.max_hourly_rate && <p className="mt-1 text-sm text-red-600">{errors.max_hourly_rate.message}</p>}
          </div>

          <div>
            <label htmlFor="condition" className="block text-sm font-medium text-gray-700 mb-1">
              Condition
            </label>
            <select
              {...register("condition")}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
            >
              <option value="">Any Condition</option>
              <option value="excellent">Excellent</option>
              <option value="good">Good</option>
              <option value="fair">Fair</option>
              <option value="poor">Poor</option>
            </select>
          </div>

          <div>
            <label htmlFor="pickupDate" className="block text-sm font-medium text-gray-700 mb-1">
              Pickup Date
            </label>
            <Controller
              name="pickupDate"
              control={control}
              rules={{ validate: (value) => !value || !watch("returnDate") || value < watch("returnDate") || "Pickup date must be before return date" }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                  placeholderText="Select pickup date"
                  minDate={new Date()}
                />
              )}
            />
            {errors.pickupDate && <p className="mt-1 text-sm text-red-600">{errors.pickupDate.message}</p>}
          </div>

          <div>
            <label htmlFor="returnDate" className="block text-sm font-medium text-gray-700 mb-1">
              Return Date
            </label>
            <Controller
              name="returnDate"
              control={control}
              rules={{ validate: (value) => !value || !watch("pickupDate") || value > watch("pickupDate") || "Return date must be after pickup date" }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                  placeholderText="Select return date"
                  minDate={pickupDate || new Date()}
                />
              )}
            />
            {errors.returnDate && <p className="mt-1 text-sm text-red-600">{errors.returnDate.message}</p>}
          </div>

          <div className="flex items-center">
            <label htmlFor="is_available" className="block text-sm font-medium text-gray-700 mr-2">
              Available only
            </label>
            <Controller
              name="is_available"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={field.onChange}
                  className={`${
                    field.value ? 'bg-primary' : 'bg-gray-200'
                  } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
                >
                  <span className="sr-only">Available only</span>
                  <span
                    className={`${
                      field.value ? 'translate-x-6' : 'translate-x-1'
                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                  />
                </Switch>
              )}
            />
          </div>
        </motion.div>
      )}

      <div className="mt-6 flex justify-between">
        <button
          type="submit"
          disabled={isLoading}
          className={`${
            isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary hover:bg-primaryDark'
          } text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition duration-150 ease-in-out flex items-center`}
        >
          {isLoading ? (
            <>
              <LoadingSpinner size="small" />
              <span className="ml-2">Applying...</span>
            </>
          ) : (
            'Apply Filters'
          )}
        </button>
        <button
          type="button"
          onClick={handleReset}
          className="bg-gray-200 text-gray-700 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-150 ease-in-out"
        >
          Reset Filters
        </button>
      </div>
    </motion.form>
  );
};

export default FilterForm;