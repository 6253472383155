import React, { useState, useEffect, useCallback } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import { CreditCard, Shield, AlertCircle, RefreshCw } from 'lucide-react';
import apiService from '../../services/api';
import Spinner from '../../components/Spinner';
import ErrorMessage from '../../components/ErrorMessage';
import { formatCurrency } from '../../utils/formatters';

const PaymentStatus = {
  IDLE: 'idle',
  INITIATED: 'initiated',
  CHECKING: 'checking',
  COMPLETED: 'completed',
  FAILED: 'failed',
  TIMEOUT: 'timeout',
  ERROR: 'error',
  PENDING: 'pending'
};

const useExponentialBackoff = (initialDelay, maxDelay, maxAttempts) => {
  const [delay, setDelay] = useState(initialDelay);
  const [attempts, setAttempts] = useState(0);

  const reset = useCallback(() => {
    setDelay(initialDelay);
    setAttempts(0);
  }, [initialDelay]);

  const next = useCallback(() => {
    setAttempts((prevAttempts) => prevAttempts + 1);
    setDelay((prevDelay) => Math.min(prevDelay * 2, maxDelay));
  }, [maxDelay]);

  return { delay, attempts, reset, next, maxAttempts };
};

const PayPalPaymentPage = () => {
  const [state, setState] = useState({
    orderData: null,
    loading: true,
    paymentStatus: PaymentStatus.IDLE,
    paymentId: null,
    error: null,
    paypalConfig: null,
    amountUSD: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { delay, attempts, reset, next, maxAttempts } = useExponentialBackoff(1000, 32000, 15);

  const updateState = useCallback((newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  const convertCurrency = useCallback(async (amount, fromCurrency, toCurrency) => {
    try {
      // For now, let's use a mock conversion rate to avoid API calls
      const mockRate = 0.0091; // 1 KES = 0.0091 USD (example rate)
      return (amount * mockRate).toFixed(2);
    } catch (error) {
      console.error('Error converting currency:', error);
      throw error;
    }
  }, []);

  const calculateItemTotal = useCallback((item) => {
    const rate = item.unit_price || item.hourly_rate || item.price || 0;
    return rate * item.quantity * (item.hours || 1);
  }, []);

  const calculateTotal = useCallback(() => {
    if (!state.orderData || !state.orderData.items) return 0;
    const subtotal = state.orderData.items.reduce((total, item) => total + calculateItemTotal(item), 0);
    const couponDiscount = parseFloat(state.orderData.coupon_discount) || 0;
    return Math.max(subtotal - couponDiscount, 0);
  }, [state.orderData, calculateItemTotal]);

  useEffect(() => {
    const loadOrderData = () => {
      const storedOrderData = sessionStorage.getItem('orderData');
      if (storedOrderData) {
        return JSON.parse(storedOrderData);
      } else if (location.state?.orderData) {
        return location.state.orderData;
      }
      return null;
    };

    const initializeData = async () => {
      try {
        const [paypalConfig, loadedOrderData] = await Promise.all([
          apiService.getPayPalConfig(),
          loadOrderData()
        ]);

        if (!loadedOrderData) {
          throw new Error('No order data found');
        }

        updateState({
          paypalConfig,
          orderData: loadedOrderData,
          loading: false
        });

        const totalKES = calculateTotal();
        const amountUSD = await convertCurrency(totalKES, 'KES', 'USD');
        updateState({ amountUSD });
      } catch (error) {
        console.error('Error initializing data:', error);
        updateState({
          error: error.message || 'Failed to load payment data',
          loading: false
        });
        toast.error(error.message || 'Failed to load payment data. Please try again.');
      }
    };

    initializeData();
  }, [navigate, location.state, updateState, calculateTotal, convertCurrency]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateState({ loading: true, error: null, paymentStatus: PaymentStatus.IDLE });
  
    if (!state.orderData || !state.orderData.id) {
      updateState({ error: 'Invalid order data. Please try again.', loading: false, paymentStatus: PaymentStatus.ERROR });
      return;
    }
  
    const paymentData = {
      order_id: state.orderData.id,
      payment_method: 'paypal',
      amount: state.amountUSD,
    };
  
    try {
      const response = await apiService.initiatePayment(paymentData);
  
      if (response.payment_id && response.redirect_url) {
        updateState({
          paymentId: response.payment_id,
          paymentStatus: PaymentStatus.INITIATED
        });
        toast.info('PayPal payment request initiated. Please complete the payment in the PayPal window.');
        reset();
        updateState({ showPayPalButtons: true });
      } else {
        throw new Error('Failed to initiate PayPal payment: Missing payment ID or redirect URL');
      }
    } catch (error) {
      console.error('PayPal payment error:', error);
      updateState({
        error: error.response?.data?.error || error.message || 'Failed to initiate payment. Please try again.',
        paymentStatus: PaymentStatus.ERROR
      });
      toast.error('An error occurred while processing your payment. Please try again.');
    } finally {
      updateState({ loading: false });
    }
  };

  const checkPaymentStatus = useCallback(async () => {
    updateState({ paymentStatus: PaymentStatus.CHECKING });
    
    try {
      const response = await apiService.checkPaymentStatus(state.paymentId);

      if (response.payment_status === 'completed') {
        updateState({ paymentStatus: PaymentStatus.COMPLETED });
        toast.success('Payment completed successfully!');
        navigate(`/order-confirmation/${state.orderData.id}`, {
          state: { paymentDetails: response, orderId: state.orderData.id }
        });
      } else if (response.payment_status === 'failed') {
        updateState({
          paymentStatus: PaymentStatus.FAILED,
          error: 'Payment failed. Please try again.'
        });
        toast.error('Payment failed. Please try again.');
      } else if (response.payment_status === 'pending') {
        if (attempts < maxAttempts) {
          next();
          setTimeout(() => checkPaymentStatus(), delay);
        } else {
          handlePaymentTimeout(state.orderData.id);
        }
      } else {
        throw new Error(`Unexpected payment status: ${response.payment_status}`);
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      if (attempts < maxAttempts) {
        next();
        setTimeout(() => checkPaymentStatus(), delay);
      } else {
        updateState({
          error: 'Error checking payment status. Please contact support.',
          paymentStatus: PaymentStatus.ERROR
        });
      }
    }
  }, [attempts, delay, maxAttempts, navigate, next, state.orderData, state.paymentId]);

  const handlePaymentTimeout = async (orderId) => {
    try {
      await apiService.handlePaymentTimeout(orderId);
      updateState({
        paymentStatus: PaymentStatus.TIMEOUT,
        error: 'Payment status check timed out. Please check your PayPal transaction or contact support.'
      });
      toast.warn('Payment status check timed out. Please check your PayPal transaction or contact support.');
    } catch (error) {
      console.error('Error handling payment timeout:', error);
      updateState({
        error: 'Error handling payment timeout. Please contact support.',
        paymentStatus: PaymentStatus.ERROR
      });
    }
  };

  const renderPaymentStatus = () => {
    const statusConfig = {
      [PaymentStatus.INITIATED]: {
        className: "bg-blue-100 border-l-4 border-blue-500 text-blue-700",
        message: "Payment initiated. Please complete the payment in the PayPal window."
      },
      [PaymentStatus.CHECKING]: {
        className: "bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700",
        message: "Checking payment status... This may take a few moments."
      },
      [PaymentStatus.COMPLETED]: {
        className: "bg-green-100 border-l-4 border-green-500 text-green-700",
        message: "Payment completed successfully!"
      },
      [PaymentStatus.FAILED]: {
        className: "bg-red-100 border-l-4 border-red-500 text-red-700",
        message: state.error || "Payment failed. Please try again."
      },
      [PaymentStatus.TIMEOUT]: {
        className: "bg-red-100 border-l-4 border-red-500 text-red-700",
        message: state.error || "Payment timed out. Please check your PayPal transaction or try again."
      },
      [PaymentStatus.ERROR]: {
        className: "bg-red-100 border-l-4 border-red-500 text-red-700",
        message: state.error || "An error occurred. Please try again or contact support."
      }
    };

    const config = statusConfig[state.paymentStatus];
    if (!config) return null;

    return (
      <div className={`${config.className} p-4 rounded-md mb-4`}>
        <p className="text-sm font-medium">{config.message}</p>
      </div>
    );
  };

  const renderOrderSummary = () => {
    if (!state.orderData || !state.orderData.items) {
      return <p>No order items found.</p>;
    }

    const subtotal = state.orderData.items.reduce((total, item) => total + calculateItemTotal(item), 0);
    const couponDiscount = parseFloat(state.orderData.coupon_discount) || 0;

    return (
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Order Items:</h3>
        <ul>
          {state.orderData.items.map((item, index) => (
            <li key={index} className="flex justify-between items-center mb-2">
              <span className="text-sm">
                {item.asset_name || `Item ${index + 1}`} (x{item.quantity})
              </span>
              <div className="flex items-center space-x-4">
                <span className="text-sm">
                  {item.hours || 1} hour(s)
                </span>
                <span className="text-sm font-medium">
                  {formatCurrency(calculateItemTotal(item))}
                </span>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-between items-center mt-4 font-medium">
          <span>Subtotal</span>
          <span>{formatCurrency(subtotal)}</span>
        </div>
        {couponDiscount > 0 && (
          <div className="flex justify-between items-center mt-2 text-green-600">
            <span>Coupon Discount</span>
            <span>-{formatCurrency(couponDiscount)}</span>
          </div>
        )}
        <div className="flex justify-between items-center mt-2 font-bold">
          <span>Total (KES)</span>
          <span>{formatCurrency(calculateTotal())}</span>
        </div>
        <div className="flex justify-between items-center mt-2 text-sm text-gray-600">
          <span>Total (USD)</span>
          <span>${parseFloat(state.amountUSD).toFixed(2)}</span>
        </div>
      </div>
    );
  };

  const renderPaymentForm = () => (
    <form onSubmit={handleSubmit} className="space-y-4">
      <button
        type="submit"
        disabled={state.loading || state.paymentStatus === PaymentStatus.CHECKING || state.paymentStatus === PaymentStatus.COMPLETED}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {state.loading ? 'Processing...' : state.paymentStatus === PaymentStatus.CHECKING ? 'Checking payment status...' : 'Pay with PayPal'}
      </button>
    </form>
  );

  const handleRetry = () => {
    updateState({ paymentStatus: PaymentStatus.IDLE, error: null });
    reset();
  };

  if (state.loading) {
    return <Spinner />;
  }

  if (state.error && state.paymentStatus === PaymentStatus.IDLE) {
    return <ErrorMessage message={state.error} />;
  }

  if (!state.orderData) {
    return <ErrorMessage message="Failed to load order data. Please try again." />;
  }

  return (
    <PayPalScriptProvider options={{ "client-id": state.paypalConfig?.clientId }}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-3xl mx-auto px-4 py-8"
      >
        <h1 className="text-3xl font-bold text-gray-900 mb-8 flex items-center">
          <CreditCard className="h-8 w-8 mr-2 text-blue-500" />
          PayPal Payment
        </h1>
        
        <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          <div className="p-6">
            <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
            {renderOrderSummary()}
            <AnimatePresence>
              {renderPaymentStatus()}
            </AnimatePresence>
            {(state.paymentStatus === PaymentStatus.IDLE || state.paymentStatus === PaymentStatus.FAILED) && renderPaymentForm()}
            {state.paymentStatus === PaymentStatus.INITIATED && (
              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [{
                      amount: {
                        value: state.amountUSD,
                        currency_code: "USD"
                      },
                    }],
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then((details) => {
                    updateState({ paymentStatus: PaymentStatus.CHECKING });
                    checkPaymentStatus();
                  });
                }}
                onError={(err) => {
                  console.error('PayPal Error:', err);
                  updateState({
                    error: 'An error occurred with PayPal. Please try again.',
                    paymentStatus: PaymentStatus.ERROR
                  });
                  toast.error('PayPal error. Please try again.');
                }}
                style={{ layout: "vertical" }}
              />
            )}
            {(state.paymentStatus === PaymentStatus.ERROR || state.paymentStatus === PaymentStatus.TIMEOUT) && (
              <button
                onClick={handleRetry}
                className="mt-4 w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <RefreshCw className="h-5 w-5 mr-2" />
                Retry Payment
              </button>
            )}
          </div>
        </div>
        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-md mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <Shield className="h-5 w-5 text-blue-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Your payment is secured by PayPal. You'll be redirected to PayPal to complete your payment securely.
              </p>
            </div>
          </div>
        </div>
        {state.paymentStatus === PaymentStatus.CHECKING && (
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md mb-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertCircle className="h-5 w-5 text-yellow-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  We are checking the payment status. This may take a few minutes. Please don't close this page.
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-4">Need Help?</h3>
          <p className="text-sm text-gray-600 mb-2">
            If you're experiencing any issues with your payment, please don't hesitate to contact our support team.
          </p>
          <a 
            href="/contact" 
            className="text-indigo-600 hover:text-indigo-800 transition-colors duration-200"
          >
            Contact Support
          </a>
        </div>
      </motion.div>
    </PayPalScriptProvider>
  );
};

export default PayPalPaymentPage;